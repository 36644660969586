import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Dynamic API base URL
const apiDomain = process.env.REACT_APP_API_DOMAIN;
const apiBase = `${apiDomain}/api/v2/faq`;

const FAQManager = () => {
  const [faqs, setFaqs] = useState([]);
  const [newQuestion, setNewQuestion] = useState('');
  const [newAnswer, setNewAnswer] = useState('');
  const [editID, setEditID] = useState(null); // Track the ID of the FAQ being edited
  const [alert, setAlert] = useState({ message: '', type: '' }); // Alert state

  // Fetch FAQs from backend
  const fetchFAQs = async () => {
    try {
      const response = await axios.get(`${apiBase}/get`);
      setFaqs(response.data);
    } catch (error) {
      showAlert('Error fetching FAQs', 'error');
    }
  };

  useEffect(() => {
    fetchFAQs();
  }, []);

  // Show alert with message and type (success/error)
  const showAlert = (message, type) => {
    setAlert({ message, type });
    setTimeout(() => setAlert({ message: '', type: '' }), 3000); // Auto-hide after 3 seconds
  };

  // Add or Update FAQ
  const handleSave = async () => {
    const payload = { question: newQuestion, answer: newAnswer };
    try {
      if (editID) {
        // Update existing FAQ
        await axios.put(`${apiBase}/update`, { id: editID, ...payload }, {
          headers: { 'Content-Type': 'application/json' },
        });
        showAlert('FAQ updated successfully', 'success');
      } else {
        // Create new FAQ
        await axios.post(`${apiBase}/create`, payload, {
          headers: { 'Content-Type': 'application/json' },
        });
        showAlert('FAQ added successfully', 'success');
      }
      fetchFAQs(); // Refresh FAQs
      resetForm();
    } catch (error) {
      showAlert('Error saving FAQ', 'error');
    }
  };

  // Delete FAQ
  const handleDelete = async (id) => {
    try {
      await axios.delete(`${apiBase}/delete`, { params: { id } });
      showAlert('FAQ deleted successfully', 'success');
      fetchFAQs();
    } catch (error) {
      showAlert('Error deleting FAQ', 'error');
    }
  };

  // Edit FAQ
  const handleEdit = (faq) => {
    setNewQuestion(faq.question);
    setNewAnswer(faq.answer);
    setEditID(faq.id);
  };

  // Reset form
  const resetForm = () => {
    setNewQuestion('');
    setNewAnswer('');
    setEditID(null);
  };

  return (
    <div className="m-4 lg:ml-[22vw] lg:mr-[4vw] mt-10">
      <h1 className="text-3xl font-bold text-center mb-6">FAQ Manager</h1>

      {/* Alert Message */}
      {alert.message && (
        <div
          className={`${
            alert.type === 'success' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
          } border-l-4 p-4 mb-4 rounded-md`}
        >
          {alert.message}
        </div>
      )}

      {/* FAQ List */}
      <div className="space-y-4">
        {faqs.map((faq) => (
          <div
            key={faq.id}
            className="border border-gray-300 p-4 rounded-md bg-white shadow-md"
          >
            <h2 className="text-xl font-semibold">{faq.question}</h2>
            <p className="mt-2 text-gray-700">{faq.answer}</p>
            <div className="mt-4 space-x-2">
              <button
                onClick={() => handleEdit(faq)}
                className="bg-yellow-500 text-white px-4 py-2 rounded-md"
              >
                Edit
              </button>
              <button
                onClick={() => handleDelete(faq.id)}
                className="bg-red-500 text-white px-4 py-2 rounded-md"
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Add/Edit Form */}
      <div className="mt-8 p-6 bg-gray-100 rounded-md">
        <h2 className="text-2xl font-semibold mb-4">
          {editID ? 'Edit FAQ' : 'Add New FAQ'}
        </h2>
        <input
          type="text"
          placeholder="Enter Question"
          value={newQuestion}
          onChange={(e) => setNewQuestion(e.target.value)}
          className="w-full px-4 py-2 border border-gray-300 rounded-md mb-4"
        />
        <textarea
          placeholder="Enter Answer"
          value={newAnswer}
          onChange={(e) => setNewAnswer(e.target.value)}
          className="w-full px-4 py-2 border border-gray-300 rounded-md mb-4"
        ></textarea>
        <div className="space-x-2">
          <button
            onClick={handleSave}
            className={`px-4 py-2 text-white rounded-md ${
              editID ? 'bg-yellow-500' : 'bg-green-500'
            }`}
          >
            {editID ? 'Update FAQ' : 'Add FAQ'}
          </button>
          {editID && (
            <button
              onClick={resetForm}
              className="px-4 py-2 bg-gray-500 text-white rounded-md"
            >
              Cancel
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default FAQManager;
