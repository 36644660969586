import React, { useState } from "react";
// import { paymentData } from "../paymentData";

const PaymentTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [statusFilter, setStatusFilter] = useState("All");
  const [searchTerm, setSearchTerm] = useState("");
  const eventsPerPage = 5;

  // Search logic (searching across all fields)
  const searchFilter = (payment) => {
    const lowerCaseSearch = searchTerm.toLowerCase();
    return (
      payment.eventName.toLowerCase().includes(lowerCaseSearch) ||
      payment.sponsorCategory.toLowerCase().includes(lowerCaseSearch) ||
      payment.amount.toString().includes(lowerCaseSearch) ||
      payment.status.toLowerCase().includes(lowerCaseSearch) ||
      payment.paymentMode.toLowerCase().includes(lowerCaseSearch) ||
      payment.transactionId.toLowerCase().includes(lowerCaseSearch)
    );
  };

  // Filter Payments by status and search, then paginate
  const filteredPayments = paymentData
    .filter((payment) =>
      statusFilter === "All" ? true : payment.status === statusFilter
    )
    .filter(searchFilter);

  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;
  const currentPayments = filteredPayments.slice(
    indexOfFirstEvent,
    indexOfLastEvent
  );

  // Pagination logic
  const totalPages = Math.ceil(filteredPayments.length / eventsPerPage);
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
     
     <div className="flex items-center justify-between mt-10">
      {/* Status Filter Buttons */}
      <div className="flex justify-start space-x-4 mb-6">
        <button
          className={`px-4 py-2 rounded-lg font-semibold ${
            statusFilter === "All"
              ? "bg-indigo-500 text-white"
              : "bg-gray-300 text-gray-700"
          }`}
          onClick={() => {
            setStatusFilter("All");
            setCurrentPage(1);
          }}
        >
          All Payments
        </button>
        <button
          className={`px-4 py-2 rounded-lg font-semibold ${
            statusFilter === "Pending"
              ? "bg-yellow-500 text-white"
              : "bg-gray-300 text-gray-700"
          }`}
          onClick={() => {
            setStatusFilter("Pending");
            setCurrentPage(1);
          }}
        >
          Pending
        </button>
        <button
          className={`px-4 py-2 rounded-lg font-semibold ${
            statusFilter === "Success"
              ? "bg-green-500 text-white"
              : "bg-gray-300 text-gray-700"
          }`}
          onClick={() => {
            setStatusFilter("Success");
            setCurrentPage(1);
          }}
        >
          Success
        </button>
        <button
          className={`px-4 py-2 rounded-lg font-semibold ${
            statusFilter === "Failed"
              ? "bg-red-500 text-white"
              : "bg-gray-300 text-gray-700"
          }`}
          onClick={() => {
            setStatusFilter("Failed");
            setCurrentPage(1);
          }}
        >
          Failed
        </button>
      </div>

       {/* Search Input */}
       <div className="mb-6">
        <input
          type="text"
          placeholder="Search Payments..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="px-4 py-2 w-full border border-gray-300 rounded-lg focus:outline-none focus:ring focus:border-blue-500"
        />
      </div>
      </div>

      {/* Payment Table */}
      <div className="overflow-x-auto rounded-lg border border-gray-200 bg-white shadow">
        <table className="min-w-full text-center table-auto">
          <thead className="bg-gray-50 border-b">
            <tr>
              <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase">Event Name</th>
              <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase">Sponsor Category</th>
              <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase">Amount</th>
              <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase">Status</th>
              <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase">Payment Mode</th>
              <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase">Transaction ID</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {currentPayments.length > 0 ? (
              currentPayments.map((payment) => (
                <tr key={payment.id} className="hover:bg-gray-100 transition duration-200">
                  <td className="px-6 py-4 text-sm text-gray-900">{payment.eventName}</td>
                  <td className="px-6 py-4 text-sm text-gray-900">{payment.sponsorCategory}</td>
                  <td className="px-6 py-4 text-sm text-gray-900">${payment.amount}</td>
                  <td className="px-6 py-4 text-sm">
                    <span
                      className={`px-4 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${
                        payment.status === "Success"
                          ? "bg-green-100 text-green-800"
                          : payment.status === "Pending"
                          ? "bg-yellow-100 text-yellow-800"
                          : "bg-red-100 text-red-800"
                      }`}
                    >
                      {payment.status}
                    </span>
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-900">{payment.paymentMode}</td>
                  <td className="px-6 py-4 text-sm text-gray-900">{payment.transactionId}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="px-6 py-4 text-sm text-gray-500">
                  No payments found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="flex justify-center items-center space-x-2 mt-6">
        {pageNumbers.map((number) => (
          <button
            key={number}
            onClick={() => paginate(number)}
            className={`px-3 py-2 font-semibold rounded-lg ${
              number === currentPage
                ? "bg-blue-500 text-white"
                : "bg-blue-200 text-blue-700"
            } hover:bg-blue-300`}
          >
            {number}
          </button>
        ))}
      </div>
    </>
  );
};

export default PaymentTable;





const paymentData = [
  {
    id: 1,
    eventName: "Charity Marathon 2023",
    sponsorCategory: "Corporate",
    amount: 500,
    status: "Success",
    paymentMode: "Credit Card",
    transactionId: "TRX001234",
  },
  {
    id: 2,
    eventName: "Education Fundraiser",
    sponsorCategory: "Individual",
    amount: 100,
    status: "Pending",
    paymentMode: "PayPal",
    transactionId: "TRX001235",
  },
  {
    id: 3,
    eventName: "Animal Rescue",
    sponsorCategory: "Non-Profit",
    amount: 250,
    status: "Failed",
    paymentMode: "Debit Card",
    transactionId: "TRX001236",
  },

  {
    id: 4,
    eventName: "Charity Marathon 2023",
    sponsorCategory: "Corporate",
    amount: 500,
    status: "Success",
    paymentMode: "Credit Card",
    transactionId: "TRX001234",
  },
  {
    id: 5,
    eventName: "Education Fundraiser",
    sponsorCategory: "Individual",
    amount: 100,
    status: "Pending",
    paymentMode: "PayPal",
    transactionId: "TRX001235",
  },
  {
    id: 6,
    eventName: "Animal Rescue",
    sponsorCategory: "Non-Profit",
    amount: 250,
    status: "Failed",
    paymentMode: "Debit Card",
    transactionId: "TRX001236",
  },
  // Add more data as needed
];
