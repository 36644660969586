import React from 'react'
import AddVenue from './AddVenue'
import VenueTable from './VenueTable'

export const Venue = () => {
  return (
    <div className='lg:ml-[20vw] lg:mr-[2vw] mt-6'>
        <AddVenue />
        <VenueTable />
    </div>
  )
}
