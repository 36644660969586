import React, { useContext, useState, useEffect } from "react";
import { DataContext } from "../context/DataContext";
import axios from "axios";
import LoadingScreen from "../utilities/LoadingScreen";

const Notifications = () => {
  const { participant, notifications, loading } = useContext(DataContext);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [additionalData, setAdditionalData] = useState(""); // Optional URL field for additional data
  const [notificationsData, setNotificationsData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  if (loading) <LoadingScreen />;

  // Initialize notifications data once
  useEffect(() => {
    if (notificationsData.length === 0 && notifications.length > 0) {
      setNotificationsData(notifications);
    }
  }, [notifications, notificationsData]);

  console.log("Participant data:", participant);

  // Convert userId to integer format to ensure consistency
  const participantUsers = participant?.participants?.map((user) => ({
    ...user,
    userId: parseInt(user.userId, 10),
  }));

  // Handle individual user selection
  const handleUserSelection = (userId) => {
    setSelectedUsers((prev) =>
      prev.includes(userId)
        ? prev.filter((id) => id !== userId)
        : [...prev, userId]
    );
  };

  const apiDomain = process.env.REACT_APP_API_DOMAIN;

  // Handle selecting all users
  const handleSelectAllUsers = () => {
    if (selectedUsers.length === participantUsers?.length) {
      setSelectedUsers([]); // Deselect all if all are selected
    } else {
      setSelectedUsers(participantUsers.map((user) => user.userId)); // Select all participant IDs
    }
  };

  // Filter users based on search term
  const filteredUsers = participantUsers?.filter((user) =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSendNotification = async () => {
    if (!title || !description || selectedUsers.length === 0) {
      alert("Please fill out all fields and select at least one user.");
      return;
    }

    try {
      // Convert selectedUsers array to a comma-separated string
      const selectedUsersString = selectedUsers.join(", ");
      console.log("Selected Users String:", selectedUsersString);

      // Fetch FCM tokens for the selected users
      const tokenResponse = await axios.get(
        `${apiDomain}/api/v2/token/get?user_ids=${selectedUsersString}`
      );

      console.log("Token response data:", tokenResponse.data);

      // Map tokens, ensuring both FCM token and participant data exist
      const tokens = selectedUsers
        .map((userId) => {
          const participant = participantUsers?.find(
            (p) => p.userId === userId
          );
          const fcmToken = tokenResponse.data[userId];
          return participant && fcmToken
            ? { user_id: userId, fcm_token: fcmToken }
            : null;
        })
        .filter(Boolean); // Remove any null entries

      console.log("Tokens array:", tokens);

      if (tokens.length === 0) {
        alert("No FCM tokens or participant data found for selected users.");
        return;
      }

      // Construct the request body for sending notifications
      const requestBody = {
        users: tokens,
        title,
        body: description,
        ...(additionalData && { additional_data: { url: additionalData } }), // Add additional data if available
      };

      console.log("Request body:", requestBody);

      // Send the notification to the backend
      const response = await axios.post(`${apiDomain}/notifications/send`,
        // const response = await axios.post(`http://localhost:7070/notifications/send`,
        requestBody,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      console.log("Notification response:", response.data);
      alert(`Notification sent to ${tokens.length} users.`);
      setTitle("");
      setDescription("");
      setAdditionalData("");
      setSelectedUsers([]);
    } catch (error) {
      console.error("Error sending notification:", error);
      alert("Error sending notification. Please try again.");
    }
  };

  return (
    <div className="lg:ml-[20vw] lg:mr-[2vw] p-10 bg-gray-100 min-h-screen">
      <div className="bg-white shadow-lg rounded-lg p-6 mb-10">
        <div className="flex w-full">
          {/* Left Section for User Selection */}
          <div className="w-1/2 pr-4 relative">
            <h2 className="text-xl font-semibold mb-4">Select Users</h2>

            {/* Search Box */}
            <input
              type="text"
              placeholder="Search users..."
              className="w-full p-2 mb-2 border border-gray-300 rounded"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />

            {/* Dropdown */}
            <div className="relative">
              <button
                className="w-full p-2 border border-gray-300 rounded bg-white"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                Select Users to Send Notification
              </button>

              {isDropdownOpen && (
                <div className="absolute z-10 mt-2 w-full bg-white border border-gray-300 rounded shadow-lg max-h-60 overflow-y-auto">
                  <ul className="p-2">
                    {/* Select All Option */}
                    <li
                      className={`p-2 cursor-pointer ${
                        selectedUsers.length === participantUsers?.length
                          ? "bg-blue-100"
                          : ""
                      }`}
                      onClick={handleSelectAllUsers}
                    >
                      {selectedUsers.length === participantUsers?.length
                        ? "Deselect All Users"
                        : "Select All Users"}
                    </li>
                    {/* Individual User Options */}
                    {filteredUsers.map((user) => (
                      <li
                        key={user.userId}
                        className={`p-2 cursor-pointer ${
                          selectedUsers.includes(user.userId)
                            ? "bg-blue-100"
                            : ""
                        }`}
                        onClick={() => handleUserSelection(user.userId)}
                      >
                        {user.name}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>

          {/* Right Section for Sending Notification */}
          <div className="w-1/2">
            <h2 className="text-xl font-semibold mb-4">Send Notification</h2>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-semibold mb-2"
                htmlFor="title"
              >
                Title
              </label>
              <input
                type="text"
                id="title"
                className="w-full p-2 border border-gray-300 rounded"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 font-semibold mb-2"
                htmlFor="description"
              >
                Description
              </label>
              <textarea
                id="description"
                className="w-full p-2 border border-gray-300 rounded"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows="4"
              />
            </div>
            <div className="mb-6">
              <label
                className="block text-gray-700 font-semibold mb-2"
                htmlFor="additionalData"
              >
                Additional Data (Optional URL)
              </label>
              <input
                type="url"
                id="additionalData"
                className="w-full p-2 border border-gray-300 rounded"
                value={additionalData}
                onChange={(e) => setAdditionalData(e.target.value)}
                placeholder="https://example.com"
              />
            </div>
            <button
              onClick={handleSendNotification}
              className="px-6 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
            >
              Send Notification
            </button>
          </div>
        </div>
      </div>

      {/* Sent Notifications Section */}
      <div className="bg-white shadow-lg rounded-lg p-6">
        <h2 className="text-2xl font-bold mb-6 text-gray-800">
          Sent Notifications
        </h2>
        {notificationsData.length > 0 ? (
          <div className="overflow-x-auto">
            <table className="w-full table-auto border-separate border-spacing-y-3">
              <thead>
                <tr>
                  <th className="bg-blue-100 p-4 rounded-tl-lg text-left text-blue-800 font-semibold">
                    <div className="flex items-center gap-2">
                      <span>User ID</span>
                      <svg
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                      >
                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-2.5 0-4.71-1.28-6-3.22.03-2.02 4-3.12 6-3.12s5.97 1.1 6 3.12C16.71 18.72 14.5 20 12 20zm0-10c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
                      </svg>
                    </div>
                  </th>
                  <th className="bg-blue-100 p-4 text-left text-blue-800 font-semibold">
                    Title
                  </th>
                  <th className="bg-blue-100 p-4 text-left text-blue-800 font-semibold">
                    Description
                  </th>
                  <th className="bg-blue-100 p-4 rounded-tr-lg text-left text-blue-800 font-semibold">
                    Time
                  </th>
                </tr>
              </thead>
              <tbody>
                {notificationsData.map((notification, index) => (
                  <tr
                    key={index}
                    className={`bg-white hover:bg-blue-50 transition duration-200 shadow-sm rounded-md ${
                      index % 2 === 0 ? "bg-gray-50" : "bg-white"
                    }`}
                  >
                    <td className="p-4 flex items-center gap-3 text-gray-700 font-medium">
                      <div className="bg-blue-200 text-blue-600 rounded-full p-2">
                        <svg
                          className="w-6 h-6"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-2.5 0-4.71-1.28-6-3.22.03-2.02 4-3.12 6-3.12s5.97 1.1 6 3.12C16.71 18.72 14.5 20 12 20zm0-10c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z" />
                        </svg>
                      </div>
                      {notification.user_id}
                    </td>
                    <td className="p-4 text-gray-800 font-semibold">
                      {notification.title}
                    </td>
                    <td className="p-4 text-gray-600">{notification.body}</td>
                    <td className="p-4 text-sm text-gray-500">
                      {new Date(notification.created_at).toLocaleString()}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="text-gray-500">No notifications sent yet.</p>
        )}
      </div>
    </div>
  );
};

export default Notifications;

// import { data } from "autoprefixer";
// import React, { useContext, useState } from "react";
// import { DataContext } from "../../components/context/DataContext";
// import axios from "axios";
// import LoadingScreen from "../../components/context/LoadingScreen";

// const Notifications = () => {
//   const { participant,notifications, loading } = useContext(DataContext);
//   const [selectedUsers, setSelectedUsers] = useState([]);
//   const [title, setTitle] = useState("");
//   const [description, setDescription] = useState("");
//   const [notificationsData, setNotifications] = useState([]);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);

//   if (loading) <LoadingScreen />

//   if(notificationsData.length == 0 && notifications.length > 0){
//     setNotifications(notifications);
//   }
//   console.log("participant...", participant);

//   // Handle user selection from dropdown
//   const handleUserSelection = (userId) => {
//     setSelectedUsers((prev) =>
//       prev.includes(userId)
//         ? prev.filter((id) => id !== userId)
//         : [...prev, userId]
//     );
//   };

//   const apiDomain = process.env.REACT_APP_API_DOMAIN;

//   // Handle selecting all users
//   const handleSelectAllUsers = () => {
//     if (selectedUsers.length === participant?.participants?.length) {
//       setSelectedUsers([]); // Deselect all if all are selected
//     } else {
//       setSelectedUsers(participant?.participants.map((user) => user.userId)); // Select all participants' IDs
//     }
//   };

//   // Filter users based on search term
//   const filteredUsers = participant?.participants.filter((user) =>
//     user.name.toLowerCase().includes(searchTerm.toLowerCase())
//   );
//   const handleSendNotification = async () => {
//     if (!title || !description || selectedUsers.length === 0) {
//       alert("Please fill out all fields and select at least one user.");
//       return;
//     }

//     try {
//       // Fetch tokens for selected users
//       const selectedUsersString = selectedUsers.join(", ");
//       const tokenResponse = await axios.get(`${apiDomain}/api/v2/token/get?user_ids=${selectedUsersString}`);

//       // Map the response to extract the tokens and make sure user_id is a number, not a string
//       const tokens = Object.keys(tokenResponse.data).map((userId) => ({
//         user_id: parseInt(userId),  // Ensure user_id is a number
//         fcm_token: tokenResponse.data[userId],
//       }));

//       // Build the request body matching the backend's expected structure
//       const requestBody = {
//         users: tokens,             // Array of users with their FCM tokens
//         title: title,              // Notification title
//         body: description,         // Notification body
//       };

//       // Send the notification
//       const response = await axios.post(`${apiDomain}/notifications/send`, requestBody, {
//         headers: {
//           "Content-Type": "application/json",  // Ensure proper content type
//         }
//       });

//       console.log("Notification response:", response.data);

//       // Show success message
//       alert(`Notification sent to ${selectedUsers.length} users.`);

//       // Clear form fields
//       setTitle("");
//       setDescription("");
//       setSelectedUsers([]);
//     } catch (error) {
//       console.error("Error sending notification:", error);
//       alert("Error sending notification. Please try again.");
//     }
//   };

//   return (
//     <div className="lg:ml-[20vw] lg:mr-[2vw] p-10 bg-gray-100 min-h-screen">
//       <div className="bg-white shadow-lg rounded-lg p-6 mb-10">
//         <div className="flex w-full">
//           {/* Left Section */}
//           <div className="w-1/2 pr-4 relative">
//             <h2 className="text-xl font-semibold mb-4">Select Users</h2>

//             {/* Search Box */}
//             <input
//               type="text"
//               placeholder="Search users..."
//               className="w-full p-2 mb-2 border border-gray-300 rounded"
//               value={searchTerm}
//               onChange={(e) => setSearchTerm(e.target.value)}
//             />

//             {/* Dropdown */}
//             <div className="relative">
//               <button
//                 className="w-full p-2 border border-gray-300 rounded bg-white"
//                 onClick={() => setIsDropdownOpen(!isDropdownOpen)}
//               >
//                 Select Users to Send Notification
//               </button>

//               {isDropdownOpen && (
//                 <div className="absolute z-10 mt-2 w-full bg-white border border-gray-300 rounded shadow-lg max-h-60 overflow-y-auto">
//                   <ul className="p-2">
//                     <li
//                       className={`p-2 cursor-pointer ${
//                         selectedUsers.length === participant?.participants?.length ? "bg-blue-100" : ""
//                       }`}
//                       onClick={handleSelectAllUsers}
//                     >
//                       {selectedUsers.length === participant?.participants?.length
//                         ? "Deselect All Users"
//                         : "Select All Users"}
//                     </li>
//                     {filteredUsers.map((user) => (
//                       <li
//                         key={user.userId}
//                         className={`p-2 cursor-pointer ${
//                           selectedUsers.includes(user.userId) ? "bg-blue-100" : ""
//                         }`}
//                         onClick={() => handleUserSelection(user.userId)}
//                       >
//                         {user.name}
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               )}
//             </div>
//           </div>

//           {/* Right Section */}
//           <div className="w-1/2">
//             <h2 className="text-xl font-semibold mb-4">Send Notification</h2>
//             <div className="mb-4">
//               <label className="block text-gray-700 font-semibold mb-2" htmlFor="title">
//                 Title
//               </label>
//               <input
//                 type="text"
//                 id="title"
//                 className="w-full p-2 border border-gray-300 rounded"
//                 value={title}
//                 onChange={(e) => setTitle(e.target.value)}
//               />
//             </div>
//             <div className="mb-6">
//               <label className="block text-gray-700 font-semibold mb-2" htmlFor="description">
//                 Description
//               </label>
//               <textarea
//                 id="description"
//                 className="w-full p-2 border border-gray-300 rounded"
//                 value={description}
//                 onChange={(e) => setDescription(e.target.value)}
//                 rows="4"
//               />
//             </div>
//             <button
//               onClick={handleSendNotification}
//               className="px-6 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
//             >
//               Send Notification
//             </button>
//           </div>
//         </div>
//       </div>

// {/* Sent Notifications Section */}
// <div className="bg-white shadow-lg rounded-lg p-6">
//   <h2 className="text-xl font-semibold mb-4">Sent Notifications</h2>
//   {notificationsData.length > 0 ? (
//     <table className="w-full table-auto border-collapse">
//       <thead>
//         <tr className="bg-gray-200">
//           <th className="border px-4 py-2 text-left">User IDs</th>
//           <th className="border px-4 py-2 text-left">Title</th>
//           <th className="border px-4 py-2 text-left">Description</th>
//           <th className="border px-4 py-2 text-left">Time</th>
//         </tr>
//       </thead>
//       <tbody>
//         {notificationsData.map((notification, index) => (
//           <tr key={index} className="bg-white hover:bg-gray-100">
//             <td className="border px-4 py-2">{notification.user_id}</td>
//             <td className="border px-4 py-2">{notification.title}</td>
//             <td className="border px-4 py-2">{notification.body}</td>
//             <td className="border px-4 py-2">{notification.created_at}</td>
//           </tr>
//         ))}
//       </tbody>
//     </table>
//   ) : (
//     <p>No notifications sent yet.</p>
//   )}
// </div>
//     </div>
//   );
// };

// export default Notifications;
