import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaPlus, FaTrashAlt } from "react-icons/fa";
import { uploadFile } from "../../firebase/fileUpload";

class MediaData {
  constructor(
    label,
    url,
    description,
    duration,
    mandatory,
    defaultValue
    // applyMusic
  ) {
    this.label = label || "";
    this.url = url || "";
    this.description = description || "";
    this.duration = duration || 0;
    this.mandatory = mandatory || false;
    this.default = defaultValue || false;
    // this.applyMusic = typeof applyMusic !== "undefined" ? applyMusic : false;
  }
}

const IndependentMediaUpload = ({ eventId }) => {
  const [imageData, setImageData] = useState([]);
  const [videoData, setVideoData] = useState([]);
  const [musicData, setMusicData] = useState([]);
  const [videoOutputDuration, setVideoOutputDuration] = useState(0);
  const [musicDuration, setMusicDuration] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);

  const apiDomain = process.env.REACT_APP_API_DOMAIN;

  // Fetch existing media data
  useEffect(() => {
    const fetchMediaData = async () => {
      try {
        const response = await axios.get(`${apiDomain}/api/v2/getlongevent`, {
          params: { event_id: eventId },
        });

        if (response.status === 200) {
          const data = response.data;
          setImageData(JSON.parse(data.image_data) || []);
          setVideoData(JSON.parse(data.video_data) || []);
          setMusicData(JSON.parse(data.music_data) || []);
          setVideoOutputDuration(data.video_output_duration_in_secs || 0);
          setMusicDuration(data.music_duration_in_secs || 0);
        }
      } catch (error) {
        console.error("Failed to fetch existing media data:", error);
      }
    };

    if (eventId) {
      fetchMediaData();
    }
  }, [eventId]);

  const handleFileUpload = async (file, type, index) => {
    const url = await uploadFile(file);
    if (type === "image") {
      const updatedImageData = [...imageData];
      updatedImageData[index] = { ...updatedImageData[index], url };
      setImageData(updatedImageData);
    }
    if (type === "video") {
      const updatedVideoData = [...videoData];
      updatedVideoData[index] = { ...updatedVideoData[index], url };
      setVideoData(updatedVideoData);
    }
    if (type === "music") {
      const updatedMusicData = [...musicData];
      updatedMusicData[index] = { ...updatedMusicData[index], url };
      setMusicData(updatedMusicData);
    }
  };

  const handleRemove = (index, type) => {
    if (type === "image")
      setImageData((prev) => prev.filter((_, i) => i !== index));
    if (type === "video")
      setVideoData((prev) => prev.filter((_, i) => i !== index));
    if (type === "music")
      setMusicData((prev) => prev.filter((_, i) => i !== index));
  };

  const handleMediaChange = (index, value, type, field) => {
    const updateData = (data, setData) => {
      const updated = [...data];
      updated[index][field] = value;
      setData(updated);
    };
    if (type === "image") updateData(imageData, setImageData);
    if (type === "video") updateData(videoData, setVideoData);
    if (type === "music") updateData(musicData, setMusicData);
  };

  const renderMediaFrame = (mediaArray, type) => {
    return mediaArray.map((media, i) => (
      <div
        key={i}
        className="p-4 border border-gray-200 rounded-lg mb-6 shadow-md relative w-full lg:w-64 xl:w-80 bg-white"
      >
        <button
          type="button"
          className="absolute -top-3 right-0 bg-red-500 text-white p-1 rounded-full"
          onClick={() => handleRemove(i, type)}
        >
          <FaTrashAlt />
        </button>

        <div
          className="mb-4 flex flex-col items-center cursor-pointer"
          onClick={() =>
            document.getElementById(`${type}-file-input-${i}`).click()
          }
        >
          {media.url ? (
            type === "image" ? (
              <img
                src={media.url}
                alt="preview"
                className="w-full h-40 object-cover rounded-md"
              />
            ) : type === "video" ? (
              <video
                src={media.url}
                className="w-full h-40 rounded-md"
                controls
              />
            ) : (
              <audio controls src={media.url} className="w-full" />
            )
          ) : (
            <div className="flex flex-col items-center justify-center w-full h-32 border-dashed border-2 border-gray-300 rounded-md">
              <FaPlus className="text-gray-400 text-xl" />
              <span className="text-gray-400 text-sm">Add {type}</span>
              <input
                id={`${type}-file-input-${i}`}
                type="file"
                accept={
                  type === "image"
                    ? "image/*"
                    : type === "video"
                    ? "video/*"
                    : "audio/*"
                }
                className="hidden"
                onChange={(e) => handleFileUpload(e.target.files[0], type, i)}
              />
            </div>
          )}
        </div>

        <input
          type="text"
          value={media.label || ""}
          onChange={(e) => handleMediaChange(i, e.target.value, type, "label")}
          placeholder="Label"
          className="w-full px-3 py-2 border border-gray-300 rounded-md mb-2"
        />
        <textarea
          value={media.description || ""}
          onChange={(e) =>
            handleMediaChange(i, e.target.value, type, "description")
          }
          placeholder="Description"
          className="w-full px-3 py-2 border border-gray-300 rounded-md mb-2"
        />
        <input
          type="number"
          value={media.duration || 0}
          onChange={(e) =>
            handleMediaChange(i, e.target.value, type, "duration")
          }
          placeholder="Duration (secs)"
          className="w-full px-3 py-2 border border-gray-300 rounded-md mb-2"
        />
        <label className="flex items-center mb-2">
          <input
            type="checkbox"
            checked={media.mandatory || false}
            onChange={(e) =>
              handleMediaChange(i, e.target.checked, type, "mandatory")
            }
            className="mr-2"
          />
          Mandatory
        </label>
        <label className="flex items-center mb-2">
          <input
            type="checkbox"
            checked={media.default || false}
            onChange={(e) =>
              handleMediaChange(i, e.target.checked, type, "default")
            }
            className="mr-2"
          />
          Default
        </label>
        {/* {type !== "music" && (
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={media.applyMusic || false}
              onChange={(e) =>
                handleMediaChange(i, e.target.checked, type, "applyMusic")
              }
              className="mr-2"
            />
            Apply Music
          </label>
        )} */}
      </div>
    ));
  };

  const handleSaveMedia = async () => {
    setIsUploading(true);
    setSaveSuccess(false); // Reset success status
    const formData = {
      event_id: eventId,
      number_of_images: imageData.length,
      number_of_videos: videoData.length,
      number_of_music: musicData.length,
      image_data: JSON.stringify(imageData),
      video_data: JSON.stringify(videoData),
      music_data: JSON.stringify(musicData),
      video_output_duration_in_secs: parseInt(videoOutputDuration, 10) || 0,
      music_duration_in_secs: parseInt(musicDuration, 10) || 0,
    };

    console.log("FormData being sent:", formData);

    try {
      const response = await axios.post(
        `${apiDomain}/api/v2/longevent/createorupdate`,
        formData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if (response.status === 200 || response.status === 201) {
        setSaveSuccess(true);
        setTimeout(() => setSaveSuccess(false), 3000); // Hide after 3 seconds
      }
    } catch (error) {
      console.error("Error saving media data:", error);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="lg:ml-[20vw] lg:mr-[2vw] p-6 border mt-8 rounded-lg shadow-lg">
      <h2 className="w-fit text-xl font-semibold text-blue-600 bg-gray-100 px-3 py-2 rounded-md mb-8">
        Additional Media For Long Event
      </h2>

      <div className="mb-10">
        {/* <h3 className="text-2xl font-semibold mb-4">Images</h3> */}

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {renderMediaFrame(imageData, "image")}
        </div>
        <button
          onClick={() => setImageData((prev) => [...prev, new MediaData()])}
          className="bg-blue-500 text-white px-4 py-2 rounded mb-4"
        >
          Add Image
        </button>
      </div>

      <div className="mb-10">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {renderMediaFrame(videoData, "video")}
        </div>
        <button
          onClick={() => setVideoData((prev) => [...prev, new MediaData()])}
          className="bg-blue-500 text-white px-4 py-2 rounded mb-4"
        >
          Add Video
        </button>
      </div>

      <div className="mb-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {renderMediaFrame(musicData, "music")}
        </div>
        <button
          onClick={() => setMusicData((prev) => [...prev, new MediaData()])}
          className="bg-blue-500 text-white px-4 py-2 rounded mb-4"
        >
          Add Music
        </button>
      </div>
      {/* Input fields for video and music durations */}
      <div className="relative mb-6">
        <input
          type="number"
          value={videoOutputDuration}
          onChange={(e) => setVideoOutputDuration(e.target.value)}
          className="w-full border border-gray-300 rounded-md p-4 peer"
          placeholder=" "
        />
        <label className="absolute text-base text-blue-500 bg-white px-1 left-3 -top-2 transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500">
          Video Output Duration (secs)
        </label>
      </div>

      <div className="relative mb-6">
        <input
          type="number"
          value={musicDuration}
          onChange={(e) => setMusicDuration(e.target.value)}
          className="w-full border border-gray-300 rounded-md p-4 peer"
          placeholder=" "
        />
        <label className="absolute text-base text-blue-500 bg-white px-1 left-3 -top-2 transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500">
          Music Duration (secs)
        </label>
      </div>

      <button
        onClick={handleSaveMedia}
        className="bg-green-500 text-white px-6 py-2 rounded shadow-md"
      >
        {isUploading ? "Saving..." : "Save Media Data"}
      </button>

      {/* Success pop-up message */}
      {saveSuccess && (
        <div className="mt-4 p-4 text-green-800 bg-green-200 rounded-lg">
          Media saved successfully!
        </div>
      )}
    </div>
  );
};

export default IndependentMediaUpload;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import collapseIcon from "./assets/reduce.png";
// import expandIcon from "./assets/expand-arrows.png";
// import { FaPlus, FaTrashAlt } from "react-icons/fa";
// import { uploadFile } from "../../firebase/fileUpload"; // Your Firebase file upload function

// class MediaData {
//   constructor(
//     label,
//     url,
//     description,
//     duration,
//     mandatory,
//     defaultValue,
//     applyMusic
//   ) {
//     this.label = label ? label : "media";
//     this.url = url;
//     this.description = description ? description : "";
//     this.duration = duration ? duration : 0;
//     this.mandatory = mandatory ? mandatory : false;
//     this.default = defaultValue ? defaultValue : false;
//     this.applyMusic = typeof applyMusic !== "undefined" ? applyMusic : false; // Only for non-audio media
//   }
// }

// class LongeventMedia {
//   constructor() {
//     this.imageData = [];
//     this.videoData = [];
//     this.musicData = [];
//   }

//   toJSON() {
//     return {
//       image_data: this.imageData,
//       video_data: this.videoData,
//       music_data: this.musicData,
//     };
//   }
// }

// const IndependentMediaUpload = ({ eventId }) => {
//   const [numImages, setNumImages] = useState(0);
//   const [imageFiles, setImageFiles] = useState([]);
//   const [imageUrls, setImageUrls] = useState([]);
//   const [imageLabels, setImageLabels] = useState([]);
//   const [imageDescriptions, setImageDescriptions] = useState([]);
//   const [imageDurations, setImageDurations] = useState([]);
//   const [imageMandatory, setImageMandatory] = useState([]);
//   const [imageDefault, setImageDefault] = useState([]);
//   const [imageApplyMusic, setImageApplyMusic] = useState([]);

//   const [numVideos, setNumVideos] = useState(0);
//   const [videoFiles, setVideoFiles] = useState([]);
//   const [videoUrls, setVideoUrls] = useState([]);
//   const [videoLabels, setVideoLabels] = useState([]);
//   const [videoDescriptions, setVideoDescriptions] = useState([]);
//   const [videoDurations, setVideoDurations] = useState([]);
//   const [videoMandatory, setVideoMandatory] = useState([]);
//   const [videoDefault, setVideoDefault] = useState([]);
//   const [videoApplyMusic, setVideoApplyMusic] = useState([]);

//   const [numMusic, setNumMusic] = useState(0);
//   const [musicFiles, setMusicFiles] = useState([]);
//   const [musicUrls, setMusicUrls] = useState([]);
//   const [musicLabels, setMusicLabels] = useState([]);
//   const [musicDescriptions, setMusicDescriptions] = useState([]);
//   const [musicDurations, setMusicDurations] = useState([]);
//   const [musicMandatory, setMusicMandatory] = useState([]);
//   const [musicDefault, setMusicDefault] = useState([]);

//   const [videoDuration, setVideoDuration] = useState(0);
//   const [isCardOpen, setIsCardOpen] = useState(true);

//   const [progress, setProgress] = useState(0);
//   const [isUploading, setIsUploading] = useState(false);
//   const [editMode, setEditMode] = useState(false);

//   const apiDomain = process.env.REACT_APP_API_DOMAIN;

//   const handleFileChange = (e, index, type) => {
//     const file = e.target.files[0];
//     if (!file) return;

//     if (type === "image") {
//       const updatedFiles = [...imageFiles];
//       updatedFiles[index] = file;
//       setImageFiles(updatedFiles);
//     } else if (type === "video") {
//       const updatedFiles = [...videoFiles];
//       updatedFiles[index] = file;
//       setVideoFiles(updatedFiles);
//     } else if (type === "music") {
//       const updatedFiles = [...musicFiles];
//       updatedFiles[index] = file;
//       setMusicFiles(updatedFiles);
//     }
//   };

//   const renderPlaceholders = (
//     num,
//     files,
//     labels,
//     setLabels,
//     descriptions,
//     setDescriptions,
//     durations,
//     setDurations,
//     mandatory,
//     setMandatory,
//     defaultVal,
//     setDefault,
//     applyMusic,
//     setApplyMusic,
//     urls,
//     setFiles,
//     setUrls,
//     type
//   ) => {
//     const placeholders = [];
//     for (let i = 0; i < num; i++) {
//       placeholders.push(
//         <div
//           key={i}
//           className="p-4 border border-gray-300 rounded-md mb-4 bg-white shadow-lg relative w-full lg:w-60 xl:w-72 h-auto"
//         >
//           {/* Display media preview */}
//           <div className="mb-4 flex items-center justify-center">
//             {files[i] ? (
//               type === "image" ? (
//                 <img
//                   src={URL.createObjectURL(files[i])}
//                   alt="preview"
//                   className="w-full h-48 object-cover rounded-md"
//                 />
//               ) : type === "video" ? (
//                 <video
//                   src={URL.createObjectURL(files[i])}
//                   className="w-full h-48"
//                   controls
//                 />
//               ) : (
//                 <audio
//                   controls
//                   src={URL.createObjectURL(files[i])}
//                   className="w-full"
//                 />
//               )
//             ) : urls[i] ? (
//               type === "image" ? (
//                 <img
//                   src={urls[i]}
//                   alt="preview"
//                   className="w-full h-48 object-cover rounded-md"
//                 />
//               ) : type === "video" ? (
//                 <video src={urls[i]} className="w-full h-48" controls />
//               ) : (
//                 <audio controls src={urls[i]} className="w-full" />
//               )
//             ) : (
//               <>
//                 <input
//                   type="file"
//                   accept={
//                     type === "image"
//                       ? "image/*"
//                       : type === "video"
//                       ? "video/*"
//                       : "audio/*"
//                   }
//                   className="absolute inset-0 opacity-0 cursor-pointer"
//                   onChange={(e) => handleFileChange(e, i, type)}
//                 />
//                 <div className="flex flex-col items-center justify-center">
//                   <FaPlus className="text-xl text-gray-400" />
//                   <p className="text-gray-400">Add {type}</p>
//                 </div>
//               </>
//             )}
//           </div>

//           {/* Input fields */}
//           <div className="space-y-3">
//             <input
//               type="text"
//               value={labels[i] || ""}
//               onChange={(e) => {
//                 const newLabels = [...labels];
//                 newLabels[i] = e.target.value;
//                 setLabels(newLabels);
//               }}
//               placeholder={`Enter ${type} label`}
//               className="w-full px-3 py-2 border border-gray-300 rounded-md"
//             />

//             <input
//               type="text"
//               value={descriptions[i] || ""}
//               onChange={(e) => {
//                 const newDescriptions = [...descriptions];
//                 newDescriptions[i] = e.target.value;
//                 setDescriptions(newDescriptions);
//               }}
//               placeholder={`Enter ${type} description`}
//               className="w-full px-3 py-2 border border-gray-300 rounded-md"
//             />

//             <input
//               type="number"
//               value={durations[i] || 0}
//               onChange={(e) => {
//                 const newDurations = [...durations];
//                 newDurations[i] = e.target.value;
//                 setDurations(newDurations);
//               }}
//               placeholder="Duration (secs)"
//               className="w-full px-3 py-2 border border-gray-300 rounded-md"
//             />

//             <div className="flex justify-between">
//               <label className="flex items-center">
//                 <input
//                   type="checkbox"
//                   checked={mandatory[i] || false}
//                   onChange={(e) => {
//                     const newMandatory = [...mandatory];
//                     newMandatory[i] = e.target.checked;
//                     setMandatory(newMandatory);
//                   }}
//                   className="mr-2"
//                 />
//                 Mandatory
//               </label>

//               <label className="flex items-center">
//                 <input
//                   type="checkbox"
//                   checked={defaultVal[i] || false}
//                   onChange={(e) => {
//                     const newDefault = [...defaultVal];
//                     newDefault[i] = e.target.checked;
//                     setDefault(newDefault);
//                   }}
//                   className="mr-2"
//                 />
//                 Default
//               </label>

//               {type !== "music" && (
//                 <label className="flex items-center">
//                   <input
//                     type="checkbox"
//                     checked={applyMusic[i] || false}
//                     onChange={(e) => {
//                       const newApplyMusic = [...applyMusic];
//                       newApplyMusic[i] = e.target.checked;
//                       setApplyMusic(newApplyMusic);
//                     }}
//                     className="mr-2"
//                   />
//                   Apply Music
//                 </label>
//               )}
//             </div>
//           </div>

//           {/* Delete button */}
//           <button
//             type="button"
//             className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full"
//             onClick={() => {
//               // Remove the media URL and reset the file input
//               const updatedUrls = urls.slice(); // Use slice to clone the array
//               const updatedFiles = files.slice();
//               const updatedLabels = labels.slice();
//               const updatedDescriptions = descriptions.slice();
//               const updatedDurations = durations.slice();
//               const updatedMandatory = mandatory.slice();
//               const updatedDefault = defaultVal.slice();

//               // Remove the specific media item from all arrays
//               updatedUrls.splice(i, 1);
//               updatedFiles.splice(i, 1);
//               updatedLabels.splice(i, 1);
//               updatedDescriptions.splice(i, 1);
//               updatedDurations.splice(i, 1);
//               updatedMandatory.splice(i, 1);
//               updatedDefault.splice(i, 1);

//               // Set the updated arrays back into state
//               setUrls(updatedUrls);
//               setFiles(updatedFiles);
//               setLabels(updatedLabels);
//               setDescriptions(updatedDescriptions);
//               setDurations(updatedDurations);
//               setMandatory(updatedMandatory);
//               setDefault(updatedDefault);

//               // Reset Apply Music if the type is not 'music'
//               if (type !== "music") {
//                 const updatedApplyMusic = applyMusic.slice();
//                 updatedApplyMusic.splice(i, 1);
//                 setApplyMusic(updatedApplyMusic);
//               }
//             }}
//           >
//             <FaTrashAlt />
//           </button>
//         </div>
//       );
//     }
//     return placeholders;
//   };

//   const handleSaveMedia = async () => {
//     setIsUploading(true);
//     const longeventMedia = new LongeventMedia();
//     let totalFiles = numImages + numVideos + numMusic;
//     let uploadedFiles = 0;

//     const updateProgress = () => {
//       uploadedFiles += 1;
//       setProgress(Math.floor((uploadedFiles / totalFiles) * 100));
//     };

//     for (let i = 0; i < numImages; i++) {
//       if (imageFiles[i]) {
//         const imageUrl = await uploadFile(imageFiles[i]);
//         longeventMedia.imageData.push(
//           new MediaData(
//             imageLabels[i],
//             imageUrl,
//             imageDescriptions[i],
//             imageDurations[i],
//             imageMandatory[i],
//             imageDefault[i],
//             imageApplyMusic[i]
//           )
//         );
//         updateProgress();
//       } else if (editMode && imageUrls[i]) {
//         longeventMedia.imageData.push(
//           new MediaData(
//             imageLabels[i],
//             imageUrls[i],
//             imageDescriptions[i],
//             imageDurations[i],
//             imageMandatory[i],
//             imageDefault[i],
//             imageApplyMusic[i]
//           )
//         );
//       }
//     }

//     for (let i = 0; i < numVideos; i++) {
//       if (videoFiles[i]) {
//         const videoUrl = await uploadFile(videoFiles[i]);
//         longeventMedia.videoData.push(
//           new MediaData(
//             videoLabels[i],
//             videoUrl,
//             videoDescriptions[i],
//             videoDurations[i],
//             videoMandatory[i],
//             videoDefault[i],
//             videoApplyMusic[i]
//           )
//         );
//         updateProgress();
//       } else if (editMode && videoUrls[i]) {
//         longeventMedia.videoData.push(
//           new MediaData(
//             videoLabels[i],
//             videoUrls[i],
//             videoDescriptions[i],
//             videoDurations[i],
//             videoMandatory[i],
//             videoDefault[i],
//             videoApplyMusic[i]
//           )
//         );
//       }
//     }

//     for (let i = 0; i < numMusic; i++) {
//       if (musicFiles[i]) {
//         const musicUrl = await uploadFile(musicFiles[i]);
//         longeventMedia.musicData.push(
//           new MediaData(
//             musicLabels[i],
//             musicUrl,
//             musicDescriptions[i],
//             musicDurations[i],
//             musicMandatory[i],
//             musicDefault[i]
//           )
//         );
//         updateProgress();
//       } else if (editMode && musicUrls[i]) {
//         longeventMedia.musicData.push(
//           new MediaData(
//             musicLabels[i],
//             musicUrls[i],
//             musicDescriptions[i],
//             musicDurations[i],
//             musicMandatory[i],
//             musicDefault[i]
//           )
//         );
//       }
//     }

//     const formData = {
//       event_id: parseInt(eventId),
//       number_of_images: parseInt(numImages),
//       image_data: JSON.stringify(longeventMedia.imageData),
//       number_of_videos: parseInt(numVideos),
//       video_data: JSON.stringify(longeventMedia.videoData),
//       number_of_music: parseInt(numMusic),
//       music_data: JSON.stringify(longeventMedia.musicData),
//       video_output_duration_in_secs: parseInt(videoDuration),
//     };

//     try {
//       const response = await axios.post(
//         `${apiDomain}/api/v2/longevent/createorupdate`,
//         formData,
//         {
//           headers: {
//             "Content-Type": "application/json",
//           },
//         }
//       );

//       if (response.status === 200 || response.status === 201) {
//         alert("Media data saved successfully!");
//       } else {
//         alert(`Failed to save media data: ${response.data}`);
//       }
//     } catch (error) {
//       console.error("Error saving media data:", error);
//       alert("Error saving media data.");
//     } finally {
//       setIsUploading(false);
//       setProgress(0);
//     }
//   };

//   useEffect(() => {
//     const fetchLongeventData = async () => {
//       try {
//         const response = await axios.get(`${apiDomain}/api/v2/getlongevent`, {
//           params: { event_id: eventId },
//         });
//         const data = response.data;

//         setNumImages(data.number_of_images);
//         const fetchedImageData = JSON.parse(data.image_data);
//         setImageLabels(fetchedImageData.map((item) => item.label));
//         setImageUrls(fetchedImageData.map((item) => item.url));
//         setImageDescriptions(fetchedImageData.map((item) => item.description));
//         setImageDurations(fetchedImageData.map((item) => item.duration));
//         setImageMandatory(fetchedImageData.map((item) => item.mandatory));
//         setImageDefault(fetchedImageData.map((item) => item.default));
//         setImageApplyMusic(fetchedImageData.map((item) => item.applyMusic));

//         setNumVideos(data.number_of_videos);
//         const fetchedVideoData = JSON.parse(data.video_data);
//         setVideoLabels(fetchedVideoData.map((item) => item.label));
//         setVideoUrls(fetchedVideoData.map((item) => item.url));
//         setVideoDescriptions(fetchedVideoData.map((item) => item.description));
//         setVideoDurations(fetchedVideoData.map((item) => item.duration));
//         setVideoMandatory(fetchedVideoData.map((item) => item.mandatory));
//         setVideoDefault(fetchedVideoData.map((item) => item.default));
//         setVideoApplyMusic(fetchedVideoData.map((item) => item.applyMusic));

//         setNumMusic(data.number_of_music);
//         const fetchedMusicData = JSON.parse(data.music_data);
//         setMusicLabels(fetchedMusicData.map((item) => item.label));
//         setMusicUrls(fetchedMusicData.map((item) => item.url));
//         setMusicDescriptions(fetchedMusicData.map((item) => item.description));
//         setMusicDurations(fetchedMusicData.map((item) => item.duration));
//         setMusicMandatory(fetchedMusicData.map((item) => item.mandatory));
//         setMusicDefault(fetchedMusicData.map((item) => item.default));

//         setVideoDuration(data.video_output_duration_in_secs);
//         setEditMode(true);
//       } catch (error) {
//         console.error("Failed to fetch longevent data", error);
//       }
//     };

//     if (eventId) {
//       fetchLongeventData();
//     }
//   }, [eventId]);

//   return (
//     <div className="lg:ml-[20vw] lg:mr-[2vw] p-4 border rounded-md mt-8">
//       <div className="w-full flex items-center justify-between mb-4">
//         <h2 className="text-3xl font-semibold text-black">
//           Additional Event Media
//         </h2>
//         <button
//           type="button"
//           onClick={() => setIsCardOpen(!isCardOpen)}
//           className="flex items-center"
//         >
//           <img
//             src={isCardOpen ? collapseIcon : expandIcon}
//             alt={isCardOpen ? "Collapse" : "Expand"}
//             className="w-6 h-6"
//           />
//         </button>
//       </div>

//       {isCardOpen && (
//         <>
//           <div className="flex-1 min-w-[320px] mb-4">
//             <label className="block text-xl font-semibold text-black mb-2">
//               Number of Images
//             </label>
//             <input
//               type="number"
//               value={numImages}
//               onChange={(e) => {
//                 setNumImages(e.target.value);
//                 setImageFiles(Array(parseInt(e.target.value) || 0).fill(null));
//                 setImageLabels(Array(parseInt(e.target.value) || 0).fill(""));
//                 setImageDescriptions(
//                   Array(parseInt(e.target.value) || 0).fill("")
//                 );
//                 setImageDurations(Array(parseInt(e.target.value) || 0).fill(0));
//                 setImageMandatory(
//                   Array(parseInt(e.target.value) || 0).fill(false)
//                 );
//                 setImageDefault(
//                   Array(parseInt(e.target.value) || 0).fill(false)
//                 );
//                 setImageApplyMusic(
//                   Array(parseInt(e.target.value) || 0).fill(false)
//                 );
//               }}
//               className="w-full h-14 bg-gray-200 border-none px-4 py-2 rounded"
//             />
//             <div className="mt-4 grid grid-cols-4 gap-4">
//               {renderPlaceholders(
//                 numImages,
//                 imageFiles,
//                 imageLabels,
//                 setImageLabels,
//                 imageDescriptions,
//                 setImageDescriptions,
//                 imageDurations,
//                 setImageDurations,
//                 imageMandatory,
//                 setImageMandatory,
//                 imageDefault,
//                 setImageDefault,
//                 imageApplyMusic,
//                 setImageApplyMusic,
//                 imageUrls,
//                 setImageFiles,
//                 setImageUrls,
//                 "image"
//               )}
//             </div>
//           </div>

//           <div className="flex-1 min-w-[320px] mb-4">
//             <label className="block text-xl font-semibold text-black mb-2">
//               Number of Videos
//             </label>
//             <input
//               type="number"
//               value={numVideos}
//               onChange={(e) => {
//                 setNumVideos(e.target.value);
//                 setVideoFiles(Array(parseInt(e.target.value) || 0).fill(null));
//                 setVideoLabels(Array(parseInt(e.target.value) || 0).fill(""));
//                 setVideoDescriptions(
//                   Array(parseInt(e.target.value) || 0).fill("")
//                 );
//                 setVideoDurations(Array(parseInt(e.target.value) || 0).fill(0));
//                 setVideoMandatory(
//                   Array(parseInt(e.target.value) || 0).fill(false)
//                 );
//                 setVideoDefault(
//                   Array(parseInt(e.target.value) || 0).fill(false)
//                 );
//                 setVideoApplyMusic(
//                   Array(parseInt(e.target.value) || 0).fill(false)
//                 );
//               }}
//               className="w-full h-14 bg-gray-200 border-none px-4 py-2 rounded"
//             />
//             <div className="mt-4 grid grid-cols-4 gap-4">
//               {renderPlaceholders(
//                 numVideos,
//                 videoFiles,
//                 videoLabels,
//                 setVideoLabels,
//                 videoDescriptions,
//                 setVideoDescriptions,
//                 videoDurations,
//                 setVideoDurations,
//                 videoMandatory,
//                 setVideoMandatory,
//                 videoDefault,
//                 setVideoDefault,
//                 videoApplyMusic,
//                 setVideoApplyMusic,
//                 videoUrls,
//                 setVideoFiles,
//                 setVideoUrls,
//                 "video"
//               )}
//             </div>
//           </div>

//           <div className="flex-1 min-w-[320px] mb-4">
//             <label className="block text-xl font-semibold text-black mb-2">
//               Number of Music Files
//             </label>
//             <input
//               type="number"
//               value={numMusic}
//               onChange={(e) => {
//                 setNumMusic(e.target.value);
//                 setMusicFiles(Array(parseInt(e.target.value) || 0).fill(null));
//                 setMusicLabels(Array(parseInt(e.target.value) || 0).fill(""));
//                 setMusicDescriptions(
//                   Array(parseInt(e.target.value) || 0).fill("")
//                 );
//                 setMusicDurations(Array(parseInt(e.target.value) || 0).fill(0));
//                 setMusicMandatory(
//                   Array(parseInt(e.target.value) || 0).fill(false)
//                 );
//                 setMusicDefault(
//                   Array(parseInt(e.target.value) || 0).fill(false)
//                 );
//               }}
//               className="w-full h-14 bg-gray-200 border-none px-4 py-2 rounded"
//             />
//             <div className="mt-4 grid grid-cols-4 gap-4">
//               {renderPlaceholders(
//                 numMusic,
//                 musicFiles,
//                 musicLabels,
//                 setMusicLabels,
//                 musicDescriptions,
//                 setMusicDescriptions,
//                 musicDurations,
//                 setMusicDurations,
//                 musicMandatory,
//                 setMusicMandatory,
//                 musicDefault,
//                 setMusicDefault,
//                 musicUrls,
//                 setMusicFiles,
//                 setMusicUrls,
//                 "music"
//               )}
//             </div>
//           </div>

//           <div className="flex-1 min-w-[320px] mb-4">
//             <label className="block text-xl font-semibold text-black mb-2">
//               Total Video Output Duration (in seconds)
//             </label>
//             <input
//               type="number"
//               value={videoDuration}
//               onChange={(e) => setVideoDuration(e.target.value)}
//               className="w-full h-14 bg-gray-200 border-none px-4 py-2 rounded"
//             />
//           </div>

//           {isUploading && (
//             <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
//               <div className="bg-white p-6 rounded-lg shadow-lg text-center">
//                 <h2 className="text-xl font-semibold mb-2">Uploading...</h2>
//                 <div className="w-full bg-gray-200 rounded-full h-4 mb-4">
//                   <div
//                     className="bg-blue-600 h-4 rounded-full"
//                     style={{ width: `${progress}%` }}
//                   ></div>
//                 </div>
//                 <p className="text-sm text-gray-700">{progress}% complete</p>
//               </div>
//             </div>
//           )}

//           <button
//             type="button"
//             onClick={handleSaveMedia}
//             disabled={isUploading}
//             className="bg-blue-500 text-white px-4 py-2 rounded shadow-md mt-6"
//           >
//             Save Media Data
//           </button>
//         </>
//       )}
//     </div>
//   );
// };

// export default IndependentMediaUpload;
