
import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";

// Create the context
const DataContext = createContext();

const DataProvider = ({ children }) => {
  const apiDomain = process.env.REACT_APP_API_DOMAIN;

  // State variables
  const [participantsMonthly, setParticipantsMonthly] = useState([]);
  const [participantsTotal, setParticipantsTotal] = useState([]);
  const [participant, setParticipant] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [categories, setGenericCategories] = useState([]);
  const [ageCategories, setAgeCategories] = useState([]);
  const [genderCategories, setGenderCategories] = useState([]);
  const [followerCountCategories, setFollowerCountCategories] = useState([]);
  const [location, setLocation] = useState([]);
  const [venues, setVenues] = useState([]);
  const [sponsors, setSponsors] = useState([]);
  const [support, setSupport] = useState([]);
  const [winners, setWinners] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [referrals, setReferrals] = useState([]);
  const [rewards, setRewards] = useState([]);
  const [usersInfo, setUsersInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null); // Reset error state before fetching

        const accessToken = Cookies.get("fbauthToken"); // Get the fbauthToken from cookies
        if (!accessToken) {
          throw new Error("No access token found in cookies");
        }

        // API Calls
        const eventDataPromise = axios.get(`${apiDomain}/api/v2/getevent?pageSize=100`, {
          params: { access_token: accessToken },
        }).catch(err => {
          console.error("Error fetching event data:", err);
          return null; // Return null in case of error
        });

        const categoriesPromise = axios.get(`${apiDomain}/api/v2/category`, {
          params: { access_token: accessToken, categoryType: 'generic' },
        }).catch(err => {
          console.error("Error fetching generic categories:", err);
          return null;
        });

        const ageCategoriesPromise = axios.get(`${apiDomain}/api/v2/category`, {
          params: { access_token: accessToken, categoryType: 'age' },
        }).catch(err => {
          console.error("Error fetching age categories:", err);
          return null;
        });

        const genderCategoriesPromise = axios.get(`${apiDomain}/api/v2/category`, {
          params: { access_token: accessToken, categoryType: 'gender' },
        }).catch(err => {
          console.error("Error fetching gender categories:", err);
          return null;
        });

        const followerCountCategoriesPromise = axios.get(`${apiDomain}/api/v2/category`, {
          params: { access_token: accessToken, categoryType: 'followercount' },
        }).catch(err => {
          console.error("Error fetching follower count categories:", err);
          return null;
        });

        const locationPromise = axios.get(`${apiDomain}/api/v2/getlocations`, {
          params: { access_token: accessToken },
        }).catch(err => {
          console.error("Error fetching locations:", err);
          return null;
        });

        const venuesPromise = axios.get(`${apiDomain}/api/v2/venues`, {
          params: { access_token: accessToken },
        }).catch(err => {
          console.error("Error fetching venues:", err);
          return null;
        });

        const participantsMonthlyPromise = axios.get(`${apiDomain}/api/v2/monthlyparticipants`, {
          params: { access_token: accessToken },
        }).catch(err => {
          console.error("Error fetching monthly participants:", err);
          return null;
        });

        const participantsTotalPromise = axios.get(`${apiDomain}/api/v2/totalparticipants`, {
          params: { access_token: accessToken },
        }).catch(err => {
          console.error("Error fetching total participants:", err);
          return null;
        });

        const participantPromise = axios.get(`${apiDomain}/api/v2/exploreparticipants`, {
          params: { access_token: accessToken },
        }).catch(err => {
          console.error("Error fetching participant data:", err);
          return null;
        });

        const sponsorsPromise = axios.get(`${apiDomain}/api/v2/getallsponsors`, {
          params: { access_token: accessToken },
        }).catch(err => {
          console.error("Error fetching sponsors:", err);
          return null;
        });

        const supportPromise = axios.get(`${apiDomain}/support/tickets`, 
        // const supportPromise = axios.get(`http://localhost:5050/support/tickets`, 
          {
          params: { access_token: accessToken },
        }).catch(err => {
          console.error("Error fetching support tickets:", err);
          return null;
        });

        const winnerPromise = axios.get(`${apiDomain}/api/v2/getallwinners`, {
          params: { access_token: accessToken },
        }).catch(err => {
          console.error("Error fetching winners:", err);
          return null;
        });

        const notificationsPromise = axios.get(`${apiDomain}/notifications/all`, 
        // const notificationsPromise = axios.get(`http://localhost:7070/notifications/all`, 
          {
          params: { limit: 100 },
        }).catch(err => {
          console.error("Error fetching notifications:", err);
          return null;
        });

        const referralsPromise = axios.get(`${apiDomain}/api/v2/referral/admin/all`, {
          params: { limit: 100 },
        }).catch(err => {
          console.error("Error fetching referrals:", err);
          return null;
        });

        const usersInfoPromise = axios.get(`${apiDomain}/api/v2/userinformation`,{
          params: { limit: 100 },
        }).catch(err => {
          console.error("Error fetching referrals:", err);
          return null;
        });

        const rewardsPromise = axios.get(`${apiDomain}/api/v2/referral-data/get`).catch(err => {
          console.error("Error fetching referral data:", err);
          return null;
        });

        // Resolve all promises
        const [
          eventsRes,
          categoriesRes,
          ageCategoriesRes,
          genderCategoriesRes,
          followerCountCategoriesRes,
          locationRes,
          venuesRes,
          participantsMonthlyRes,
          participantsTotalRes,
          participantRes,
          sponsorsRes,
          supportRes,
          winnerRes,
          notificationsRes,
          referralsRes,
          rewardsRes,
          userInfoRes,
        ] = await Promise.all([
          eventDataPromise,
          categoriesPromise,
          ageCategoriesPromise,
          genderCategoriesPromise,
          followerCountCategoriesPromise,
          locationPromise,
          venuesPromise,
          participantsMonthlyPromise,
          participantsTotalPromise,
          participantPromise,
          sponsorsPromise,
          supportPromise,
          winnerPromise,
          notificationsPromise,
          referralsPromise,
          rewardsPromise,
          usersInfoPromise
        ]);

        // Set States
        if (eventsRes) setEventData(eventsRes.data);
        if (categoriesRes) setGenericCategories(categoriesRes.data);
        if (ageCategoriesRes) setAgeCategories(ageCategoriesRes.data);
        if (genderCategoriesRes) setGenderCategories(genderCategoriesRes.data);
        if (followerCountCategoriesRes) setFollowerCountCategories(followerCountCategoriesRes.data);
        if (locationRes) setLocation(locationRes.data);
        if (venuesRes) setVenues(venuesRes.data);
        if (participantRes) setParticipant(participantRes.data);
        if (participantsMonthlyRes) setParticipantsMonthly(participantsMonthlyRes.data);
        if (participantsTotalRes) setParticipantsTotal(participantsTotalRes.data);
        if (sponsorsRes) setSponsors(sponsorsRes.data);
        if (supportRes) setSupport(supportRes.data);
        if (winnerRes) setWinners(winnerRes.data);
        if (notificationsRes) setNotifications(notificationsRes.data);
        if (referralsRes) setReferrals(referralsRes.data);
        if (rewardsRes) setRewards(rewardsRes.data);
        if (userInfoRes) setUsersInfo(userInfoRes.data);
      } catch (err) {
        setError(err.message || "An error occurred while fetching data.");
        console.error("Error fetching data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData(); // Fetch data on component mount
  }, [apiDomain]); // Add accessToken here if it can change dynamically

  return (
    <DataContext.Provider
      value={{
        eventData,
        categories,
        ageCategories,
        genderCategories,
        followerCountCategories,
        location,
        venues,
        participant,
        participantsMonthly,
        participantsTotal,
        sponsors,
        support,
        winners,
        notifications,
        referrals,
        rewards,
        usersInfo,
        loading,
        error,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export { DataContext, DataProvider };

