import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useParams, useSearchParams, useLocation } from "react-router-dom";
import MediaList from "./MediaList";
import { getAccessToken } from "../../../components/getAccessToken/getAccessToken";
import { DataContext } from "../../context/DataContext";
import { ParticipationDetail } from "./ParticipationDetail";
import FacebookInsightsChart from "./FacebookInsightsChart";
import UserDetail from "./UserDetail";

export const Instagram = () => {
  const accessToken = Cookies.get("fbauthToken");
  const [searchParams] = useSearchParams();
  const username = searchParams.get("username");
  const passphrase = searchParams.get("passphrase");
  const { id, instagramBusinessAccountId, isiguser } = useParams();
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);
  const apiDomain = process.env.REACT_APP_API_DOMAIN;
  const [userFbToken, setUserFbToken] = useState(null);

  const location = useLocation();
  const participantData = location.state?.participant || {};
  
  // Button states
  const [allMedia, setAllMedia] = useState(true);
  const [eventMedia, setEventMedia] = useState(false);
  const [participationDetail, setParticipationDetail] = useState(false);
  const [insights, setInsights] = useState(false);
  const [userDetails, setUserDetails] = useState(false);
  
  // Fetch context data
  const {
    eventData,
    categories,
    ageCategories,
    genderCategories,
    followerCountCategories,
    usersInfo,
    loading,
  } = useContext(DataContext);

  if (loading) <></>;

  console.log("events, user info", eventData, usersInfo);

  // Fetch Instagram user data from API
  const fetchInstagramUser = async (accessToken, id, iguser) => {
    var token = await getAccessToken(
      `${apiDomain}/api/v2/admin/getrefreshtoken`,
      id
    );
    setUserFbToken(token);
    try {
      const response = await axios.get(
        `${apiDomain}/api/v2/facebook/instagramuser`,
        {
          params: {
            access_token: token,
            id: instagramBusinessAccountId,
            iguser: isiguser == "true"
             ? "true" : "false"
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching Instagram user data:", error);
      throw error;
    }
  };

  useEffect(() => {
    const getUserData = async () => {
      try {
        if (accessToken && instagramBusinessAccountId) {
          const data = await fetchInstagramUser(accessToken, id, isiguser);
          setUserData(data);
        } else {
          setError("Missing access token or Instagram Business Account ID");
        }
      } catch (err) {
        setError("Failed to fetch Instagram user data.");
      }
    };
    getUserData();
  }, [accessToken, instagramBusinessAccountId,isiguser]);

  // Handler to update the state based on the clicked button
  const handleButtonClick = (button) => {
    setAllMedia(button === "allMedia");
    setEventMedia(button === "eventMedia");
    setParticipationDetail(button === "participationDetail");
    setInsights(button === "insights");
    setUserDetails(button === "userDetails");
  };

  // Find the user details from the usersInfo array based on user_id
  const currentUser = usersInfo?.find((user) => user.user_id === Number(id));

  if (error) return <div>Error: {error}</div>;
  if (!userData && !currentUser) return <div></div>;

  return (
    <main className="lg:ml-[20vw] lg:mr-[2vw]">
      <div className="p-4 bg-white shadow-md rounded-lg mt-8">
        <div className="flex items-center space-x-6">
          <img
            src={userData?.profile_picture_url || "/default-profile.jpg"}
            alt={userData?.name || currentUser?.name}
            className="w-32 h-32 rounded-full border-2 border-gray-300"
          />
          <div className="flex flex-col">
            <h1 className="text-3xl font-semibold">
              {userData?.name || currentUser?.name}
            </h1>
            <p className="text-lg text-gray-600">
              @{userData?.username || currentUser?.email}
            </p>
            <p className="text-sm text-gray-500">
              {userData?.biography || currentUser?.home_street}
            </p>
          </div>
        </div>
        <div className="mt-6 border-t border-gray-300 pt-4">
          <div className="flex space-x-8 text-center">
            <div>
              <p className="text-xl font-semibold">
                {userData?.followers_count || "N/A"}
              </p>
              <p className="text-sm text-gray-500">Followers</p>
            </div>
            <div>
              <p className="text-xl font-semibold">
                {userData?.follows_count || "N/A"}
              </p>
              <p className="text-sm text-gray-500">Following</p>
            </div>
            <div>
              <p className="text-xl font-semibold">
                {userData?.media_count || "N/A"}
              </p>
              <p className="text-sm text-gray-500">Posts</p>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <p className="text-sm text-gray-500">Website:</p>
          <a
            href={userData?.website || "#"}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:underline"
          >
            {userData?.website || "No website available"}
          </a>
        </div>
      </div>

      {/* Filter buttons */}
      <div className="filter-btn my-8 mt-16 flex items-center space-x-2">
        <button
          className={`border rounded-md px-6 py-2 ${
            allMedia ? "bg-blue-500 text-white" : ""
          }`}
          onClick={() => handleButtonClick("allMedia")}
        >
          All Media
        </button>
        <button
          className={`border rounded-md px-6 py-2 ${
            eventMedia ? "bg-blue-500 text-white" : ""
          }`}
          onClick={() => handleButtonClick("eventMedia")}
        >
          Event Media
        </button>
        <button
          className={`border rounded-md px-6 py-2 ${
            participationDetail ? "bg-blue-500 text-white" : ""
          }`}
          onClick={() => handleButtonClick("participationDetail")}
        >
          Participation Detail
        </button>
        <button
          className={`border rounded-md px-6 py-2 ${
            insights ? "bg-blue-500 text-white" : ""
          }`}
          onClick={() => handleButtonClick("insights")}
        >
          Insights
        </button>
        <button
          className={`border rounded-md px-6 py-2 ${
            userDetails ? "bg-blue-500 text-white" : ""
          }`}
          onClick={() => handleButtonClick("userDetails")}
        >
          User Detail
        </button>
      </div>

      {allMedia && userFbToken && (
        <MediaList
          userId={instagramBusinessAccountId}
          token={userFbToken}
          eventMedia={false}
          isiguser={isiguser}
          heading="Instagram All Media"
        />
      )}

      {eventMedia && userFbToken && (
        <MediaList userId={id} token={userFbToken} eventMedia={true} isiguser={isiguser} heading="Instagram Event Media" />
      )}

      {participationDetail && eventData && userFbToken && (
        <ParticipationDetail
          details={participantData}
          eventData={eventData}
          categories={categories}
          genderCategories={genderCategories}
          ageCategories={ageCategories}
          followerCountCategories={followerCountCategories}
          token={userFbToken}
        />
      )}

      <div>
        {insights && userFbToken && instagramBusinessAccountId && (
          <FacebookInsightsChart
            instagramBusinessAccountId={instagramBusinessAccountId}
            accessToken={userFbToken}
          />
        )}
      </div>

      {userDetails && (
        <UserDetail currentUser={currentUser} instagramData={userData} />
      )}
       </main>
  );
};

export default Instagram;


// please make a separate component of user Details here with daynmic ui also show the instagrm detail in this component of this particuler user
// based on the user id