import React, { useEffect, useState } from 'react';
import axios from 'axios';

const MediaList = ({ userId, token, eventMedia, heading , isiguser}) => {
  console.log("media details local ig user", isiguser)
  const [mediaData, setMediaData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const [selectedMedia, setSelectedMedia] = useState(null); // State for selected media
  const apiDomain = process.env.REACT_APP_API_DOMAIN;

  const getMediaDetails = async (url) => {
    try {
      const response = await axios.get(url, {
        params: {
          access_token: token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching media details:', error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchMediaData = async () => {
      setLoading(true);
      try {
        const initialUrl = `${apiDomain}/api/v2/facebook/user/media?access_token=${token}&id=${userId}&iguser=${isiguser == "true" ? "true" : ""}`;
        const data = await getMediaDetails(initialUrl);
        console.log(data);
        setMediaData(data.data);
        setNextUrl(data.next_url); // Update nextUrl from response
        setPrevUrl(data.prev_url); // Update prevUrl from response
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    const fetchEventMediaData = async () => {
      setLoading(true);
      try {
        const initialUrl = `${apiDomain}/api/v2/getfilteredigusermedia?userid=${userId}&access_token=${token}&iguser=${isiguser == "true" ? "true" : ""}`;
        const data = await getMediaDetails(initialUrl);
        console.log('filtereddata', data);
        setMediaData(data.data);
        setNextUrl(data.next_url); // Update nextUrl from response
        setPrevUrl(data.prev_url); // Update prevUrl from response
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    if (eventMedia) fetchEventMediaData();
    else fetchMediaData();
  }, [userId, eventMedia]);

  const handlePageChange = async (url) => {
    if (url) {
      setLoading(true);
      try {
        const data = await getMediaDetails(url);
        setMediaData(data.data);
        setNextUrl(data.next_url);
        setPrevUrl(data.prev_url);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    }
  };

  const openModal = (media) => {
    setSelectedMedia(media); // Set the selected media to state
  };

  const closeModal = () => {
    setSelectedMedia(null); // Close the modal
  };

  if (loading) return <div className="text-center py-4"></div>;
  if (error) return <div className="text-center py-4 text-red-500">Error: {error.message}</div>;

  return (
    <div className="container px-4 py-6">
      <h1 className="text-4xl font-extrabold mb-2 text-center text-gray-800 py-1">{heading}</h1>
      <div className='w-full h-1 bg-gradient-to-r from-blue-400 to-purple-600 mb-4'></div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6 md:gap-8 lg:gap-10">
        {mediaData && mediaData.map((media) => (
          <div
            key={media.id}
            className="relative group bg-white shadow-lg rounded-lg overflow-hidden cursor-pointer h-[50vh] border-2 border-gray-200 hover:border-blue-500 transition duration-300"
            onClick={() => openModal(media)} // Open modal on click
          >
            <div className="absolute top-0 left-0 z-20 bg-white bg-opacity-90 w-full px-3 py-2">
              <span className="text-gray-900 font-semibold">@{media.username}</span>
            </div>
            <div className="relative h-full">
              {media.media_type === 'IMAGE' && (
                <img
                  src={media.media_url}
                  alt={media.caption}
                  className="w-full h-full object-cover group-hover:scale-110 transition-transform duration-300"
                />
              )}
              {media.media_type === 'VIDEO' && (
                <video
                  src={media.media_url}
                  className="w-full h-full object-cover group-hover:scale-110 transition-transform duration-300"
                  controls
                />
              )}
            </div>
            <div className="absolute bottom-0 left-0 z-20 w-full px-3 py-2 bg-white bg-opacity-90">
              <div className="flex items-center justify-between text-gray-800">
                <span className="flex items-center">
                  <svg className="w-5 h-5 text-red-600 mr-1" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
                  </svg>
                  {media.like_count}
                </span>
                <span className="flex items-center">
                  <svg className="w-5 h-5 text-gray-600 mr-1" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-2.95-.49-5.23-2.77-5.72-5.72H11v5.72zm2 0v-5.72h4.72c-.49 2.95-2.77 5.23-5.72 5.72zM4.07 13H11v-2H4.07c.49-2.95 2.77-5.23 5.72-5.72V11H4.07zM13 11V4.07c2.95.49 5.23 2.77 5.72 5.72H13v2h6.93c-.49 2.95-2.77 5.23-5.72 5.72V13H13z" />
                  </svg>
                  {media.comments_count}
                </span>
              </div>
            </div>
            <div className="absolute inset-x-0 bottom-10 bg-white bg-opacity-90 px-3 py-2 opacity-0 transition-all duration-300 z-10 transform translate-y-full group-hover:translate-y-0 group-hover:opacity-100">
              <p className="text-gray-800 text-sm font-semibold">
                {media.caption}
              </p>
            </div>
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
              <span className="text-white text-lg font-semibold">
                {media.media_type === 'IMAGE' ? 'View Image' : 'Play Video'}
              </span>
            </div>
          </div>
        ))}
      </div>

      {/* Modal for displaying selected media */}
      {selectedMedia && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
          <div className="bg-white rounded-lg overflow-hidden relative w-full md:w-1/2" style={{height: '560px'}}> {/* Reduced height */}
            <button
              onClick={closeModal} // Close modal on click
              className="absolute top-2 right-2 text-black hover:text-white bg bg-red-600"
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <div className="p-4">
              {selectedMedia.media_type === 'IMAGE' && (
                <img
                  src={selectedMedia.media_url}
                  alt={selectedMedia.caption}
                  className="w-full h-auto"
                />
              )}
              {selectedMedia.media_type === 'VIDEO' && (
                <video
                  src={selectedMedia.media_url}
                  className="w-full h-auto"
                  controls
                />
              )}
              <p className="mt-2 text-gray-800 font-semibold">{selectedMedia.caption}</p>
            </div>
          </div>
        </div>
      )}

      {/* Pagination buttons */}
      <div className="flex justify-between py-4">
        {prevUrl && (
          <button
            onClick={() => handlePageChange(prevUrl)}
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300"
          >
            Previous
          </button>
        )}
        {nextUrl && (
          <button
            onClick={() => handlePageChange(nextUrl)}
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-300"
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
};

export default MediaList;
