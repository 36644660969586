import Cookies from 'js-cookie';
import axios from 'axios'

const apiDomain = process.env.REACT_APP_API_DOMAIN;
export const handleLogin = async (username, passphrase) => {
    try {
        // Construct the query parameters
        const queryParams = new URLSearchParams({
            username: username,
            passphrase: passphrase,
        });
        

        // Make the request with the query parameters
        const response = await axios.get(`${apiDomain}/api/v2/admin/login?${queryParams.toString()}`); 
        if (response.status == 200) {
            const data = response.data;
            console.log(response)
            const token = data.publicKey; // Assuming the token is in the response
            Cookies.set("username", username, { expires: 30, path: '/' }); 
            // Store the token and timestamp in cookies
            Cookies.set('authToken', token, { expires: 1, path: '/' }); // Expires in 1 day
            Cookies.set('loginTime', Date.now(), { expires: 1, path: '/' });
            // Redirect to home page after successful login
            return true;
        } else {
            console.error('Login failed');
            return false;
        }
    } catch (error) {
        console.error('Error logging in:', error);
        return false
    }
};

export const checkSession = () => {
    const token = Cookies.get('authToken');
    const loginTime = Cookies.get('loginTime');

    if (!token || !loginTime) {
        // If no token or loginTime, redirect to login page
        window.location.href = '/admin/login';
        return;
    }

    const currentTime = Date.now();
    const sessionDuration = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

    if (currentTime - parseInt(loginTime) > sessionDuration) {
        // Session has expired, clear cookies and redirect to login
        Cookies.remove('authToken');
        Cookies.remove('loginTime');
        window.location.href = '/admin/login';
    }
    if(!Cookies.get('fbauthToken')){
        window.location.href = `${apiDomain}/auth/facebook?redirectTo=https://namasvi.indicarena.com/auth/facebook/callback`;
        return;
    }
};

// Call this function on each protected route or in a useEffect in your main component















// // Example using localStorage
// export const handleLogin = async (username, passphrase) => {
//     try {
//       // Construct the query parameters
//       const queryParams = new URLSearchParams({
//         username: username,
//         passphrase: passphrase,
//       });
  
//       // Make the request with the query parameters
//       const response = await fetch(`http://localhost:8080/admin/login?${queryParams.toString()}`, {
//         method: 'POST', // You can use GET if that's what your API requires, though POST is safer for sensitive data.
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });
//       console.log("response", response)
//       if (response.ok) {
//         const data = await response.json();
//         const token = data.publicKey; // Assuming the token is in the response
        
//         // Store the token and timestamp
//         localStorage.setItem('authToken', token);
//         localStorage.setItem('loginTime', Date.now());
        
//         // Redirect to home page after successful login
//         window.location.href = '/';
//       } else {
//         console.error('Login failed');
//       }
//     } catch (error) {
//       console.error('Error logging in:', error);
//     }
//   };
  

//   export const checkSession = () => {
//     const token = localStorage.getItem('authToken');
//     const loginTime = localStorage.getItem('loginTime');
    
//     if (!token || !loginTime) {
//       // If no token or loginTime, redirect to login page
//       window.location.href = '/admin/login';
//       return;
//     }
    
//     const currentTime = Date.now();
//     const sessionDuration = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
    
//     if (currentTime - loginTime > sessionDuration) {
//       // Session has expired, clear storage and redirect to login
//       localStorage.removeItem('authToken');
//       localStorage.removeItem('loginTime');
//       window.location.href = '/admin/login';
//     }
//   };
  
//   // Call this function on each protected route or in a useEffect in your main component
//   checkSession();
  
