import React, { useContext, useState } from "react";
import { DataContext } from "../context/DataContext";
import axios from "axios";
import { uploadFile } from "../../firebase/fileUpload";
import LoadingScreen from "../utilities/LoadingScreen";

const Support = () => {
  const { support, loading } = useContext(DataContext);

  if (loading) <LoadingScreen />

  const apiDomain = process.env.REACT_APP_API_DOMAIN;

  const [isOpenTicketView, setIsOpenTicketView] = useState(true);
  const [showTicketDetail, setShowTicketDetail] = useState(false);
  const [currentTicket, setCurrentTicket] = useState(null);
  const [reply, setReply] = useState("");
  const [attachment, setAttachment] = useState(null);
  const [imagePreview, setImagePreview] = useState(null); // For image overlay preview
  const [isloading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [replies, setReplies] = useState(null);
  const [instaUser, setInstaUser] = useState(null);


  // Toggle between open and closed tickets view
  const toggleView = (view) => {
    setIsOpenTicketView(view);
  };

  // Open ticket detail view
  const handleEditTicket = async (ticket) => {
    await fetchTicketDetails(ticket.id);
    setCurrentTicket(ticket);
    setShowTicketDetail(true);
  };

  // Back button to return to ticket list
  const handleBackToList = () => {
    setShowTicketDetail(false);
    setCurrentTicket(null);
  };

  const fetchTicketDetails = async (ticketId) => {
    try {
      const response = await axios.get(
        // `http://localhost:5050/support/ticket/${ticketId}`
        `${apiDomain}/support/ticket/${ticketId}`
      );
      setCurrentTicket(response.data.ticket);
      setReplies(response.data.replies);
      setLoading(false);
      await fetchUserDetails(response.data.ticket.user_id);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const fetchUserDetails = async (id) => {
    try {
      const resp = await axios.get(
        // `http://localhost:8080/api/v2/admin/getrefreshtoken?id=${id}`
        `${apiDomain}/api/v2/admin/getrefreshtoken?id=${id}`
      );
      const response = await axios.get(
        // `http://localhost:8080/api/v2/user?access_token=${resp.data.accessToken}`
        `${apiDomain}/api/v2/user?access_token=${resp.data.accessToken}`
        
      );
      const igUser = await axios.get(
        `${apiDomain}/api/v2/facebook/instagramuser?id=${response.data.instagram_business_account_id}&access_token=${resp.data.accessToken}`
      );
      setInstaUser(igUser.data);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const createReply = async (replyData) => {
    try {
      // const response = await axios.post(`http://localhost:5050/support/reply`, 
        const response = await axios.post(`${apiDomain}/support/reply`, 
        {
        ticket_id: replyData.ticket_id,
        user_id: replyData.user_id,
        message: replyData.message,
        parent_reply_id: replyData.parent_reply_id || null,
        source: replyData.source,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const createAttachment = async (id, url) => {
    try {
      const response = await axios.post(
        // "http://localhost:5050/support/attachment",
        `${apiDomain}/support/attachment`,
        {
          reply_id: id,
          file_url: url,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const handleReplySubmit = async () => {
    if (currentTicket) {
      const newReply = {
        ticket_id: currentTicket.id,
        user_id: currentTicket.user_id,
        message: reply,
        parent_reply_id: currentTicket.parent_reply_id || null,
        source: "admin",
      };

      var resp = await createReply(newReply);
      var url = await uploadFile(attachment);
      var atresp = await createAttachment(resp.id, url);
      alert("Reply posted successfully");
      setReply("");
      setAttachment(null);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setAttachment(file);
    } else {
      alert("Please upload an image file (JPG, PNG, etc.)");
    }
  };

  const handleImageClick = (image) => {
    setImagePreview(image);
  };

  const closeImagePreview = () => {
    setImagePreview(null);
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  return (
    <div className="lg:ml-[20vw] lg:mr-[2vw] p-10 bg-gray-100 min-h-screen">
      <h2 className="text-3xl font-bold mb-6">Support Ticket Management</h2>

      {/* Toggle buttons for Open/Closed Tickets */}
      {/* <div className="flex space-x-4 mb-6">
        <button
          onClick={() => toggleView(true)}
          className={`px-6 py-2 rounded ${
            isOpenTicketView ? "bg-blue-600 text-white" : "bg-gray-300"
          }`}
        >
          Open Tickets
        </button>
        <button
          onClick={() => toggleView(false)}
          className={`px-6 py-2 rounded ${
            !isOpenTicketView ? "bg-blue-600 text-white" : "bg-gray-300"
          }`}
        >
          Closed Tickets
        </button>
      </div> */}

      {/* Ticket List View */}
      {!showTicketDetail && isOpenTicketView && (
        <div className="bg-white shadow-lg rounded-lg p-6">
          <h3 className="text-xl font-semibold mb-4">Open Tickets</h3>
          <table className="w-full table-auto border-collapse">
            <thead>
              <tr className="bg-gray-200">
                <th className="border px-4 py-2 text-left">Ticket ID</th>
                <th className="border px-4 py-2 text-left">User ID</th>
                <th className="border px-4 py-2 text-left">Title</th>
                <th className="border px-4 py-2 text-left">Time</th>
                <th className="border px-4 py-2 text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              {support && support.tickets &&
                support.tickets.map((ticket) => (
                  <tr key={ticket.id} className="bg-white hover:bg-gray-100">
                    <td className="border px-4 py-2">{ticket.id}</td>
                    <td className="border px-4 py-2">{ticket.user_id}</td>
                    <td className="border px-4 py-2">{ticket.title}</td>
                    <td className="border px-4 py-2">
                      {formatDate(ticket.created_at)}
                    </td>
                    <td className="border px-4 py-2">
                      <button
                        onClick={() => handleEditTicket(ticket)}
                        className="mr-2 px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600"
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Ticket Detail View */}
      {showTicketDetail && currentTicket && (
        <div className="bg-white shadow-lg rounded-lg p-6">
               {/* Back Button */}
               <button
            onClick={handleBackToList}
            className="px-4 py-2 bg-gray-500 text-white rounded mb-6 hover:bg-gray-600"
          >
            Back to Tickets
          </button>
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-xl font-semibold">
              Ticket #{currentTicket.id} |{" "}
              <span className="text-blue-500 capitalize">
                {currentTicket.status}
              </span>
            </h3>
            <span>{formatDate(currentTicket.created_at)}</span>
          </div>
          <h4 className="text-lg font-semibold mb-2">
            Title: {currentTicket.title}
          </h4>
          <p className="mb-6">Description: {currentTicket.description}</p>

     

          {/* Replies Section */}
          <div className="mb-6 rounded-2xl">
          <h4 className="text-lg font-semibold mb-4">User Query</h4>
            {replies &&
              replies.map((reply, index) => (
                <div
                  key={index}
                  className="mb-4 border-2 py-2 px-4 rounded-xl"
                >
                  <div className="flex items-center justify-between mb-2 ">
                    <div className="flex items-center gap-2 pl-2 pr-2 py-1 rounded-full border border-gray-300">
                      {instaUser && (
                        <img
                          src={instaUser.profile_picture_url}
                          alt="/img"
                          className="w-10 h-10 rounded-full"
                        />
                      )}
                      <p className="font-semibold capitalize">
                        {reply.reply.source === "user"
                          ? `${instaUser && instaUser.username}`
                          : "sponsor"
                          ? `${instaUser && instaUser.username}`
                          : "Admin"}
                      </p>
                    </div>
                    
                  </div>
                  <p className="capitalize font-normal text-gray-800 ml-2">
                    {reply.reply.message}
                  </p>
                  <div className="my-4 ml-2">
                    {reply.attachments &&
                      reply.attachments.map((image) => (
                        <img
                          key={image.file_path}
                          src={image.file_path}
                          alt="attachment"
                          className="w-32 h-32 object-cover cursor-pointer rounded-md"
                          onClick={() => handleImageClick(image.file_path)}
                        />
                      ))}
                  </div>
                </div>
              ))}
          </div>

          {/* Admin Reply Form */}
          <div className="bg-gray-100 p-4 rounded-lg">
            <h4 className="font-semibold mb-2">Reply</h4>
            <textarea
              className="w-full p-2 mb-4 border border-gray-300 rounded"
              rows="4"
              value={reply}
              onChange={(e) => setReply(e.target.value)}
              placeholder="Type your reply here"
            />
            <input type="file" onChange={handleFileChange} className="mb-4" />
            <div className="flex justify-end">
              <button
                onClick={() => handleReplySubmit("admin")}
                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
              >
                Submit Reply
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Image Overlay */}
      {imagePreview && (
        <div
          className="fixed inset-0 left-8 bg-black bg-opacity-75 flex items-center justify-center z-50"
          onClick={closeImagePreview}
        >
          <img
            src={imagePreview}
            alt="Full-size preview"
            className="max-w-full max-h-full"
          />
        </div>
      )}
    </div>
  );
};

export default Support;
