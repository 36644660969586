import React, { useState } from "react";
import { handleLogin } from "./loginHandelSystem/hadelFunction";
import Cookies from "js-cookie";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa"; // Import icons for success and failure

export const Login = () => {
  const [username, setUsername] = useState("");
  const [passphrase, setPassphrase] = useState("");
  const [loginStatus, setLoginStatus] = useState(null); // Track login status (null, 'success', 'failure')
  const [showModal, setShowModal] = useState(false); // Control modal visibility

  // Example cookie setting and logic
  // Cookies.set("authToken", "sampleAuthToken");
  // Cookies.set("loginTime", Date.now());
  // Cookies.set(
  //   "fbauthToken",
  //   "EAAfu4stBpQYBOyIuIaHRk8TrsLwx2QOQuxVKcWpRyswssxzLNmhbmeCxWw3BrfRbZBzHQXST0t4MUqQdxQePjjuOWUCKgvBV6tUyE5G3atL2Vq0UWsr9VgYlZAZBRPJcBcQHRxP7zZCJRWZCNAzVVpiYL0QQTgXgzgfsAN8kZCp60ccAN2jKipX8MHeDaujIZAY7QtN7d5f"
  // );

  if (Cookies.get("authToken") && Cookies.get("loginTime") && !Cookies.get("fbauthToken")) {
    window.location.href = `https://dafukcrew.com/auth/facebook?redirectTo=https://namasvi.indicarena.com/auth/facebook/callback`;
    return;
  }
  if (Cookies.get("authToken") && Cookies.get("loginTime") && Cookies.get("fbauthToken")) {
    window.location.href = "/";
    return;
  }

  // Handle the login click
  const onLoginClick = async () => {
    const trimmedUsername = username.trim();
    const trimmedPassphrase = passphrase.trim();

    // Call the handleLogin function and get result (Assuming handleLogin returns a promise)
    const loginResult = await handleLogin(trimmedUsername, trimmedPassphrase);

    // Update login status based on login result
    if (loginResult) {
      setLoginStatus("success");
      setShowModal(true);

      // Redirect after 5 seconds if login is successful
      setTimeout(() => {
        window.location.href = "/";
      }, 5000);
    } else {
      setLoginStatus("failure");
      setShowModal(true); // Show modal immediately after failure
    }
  };

  // Close the modal
  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="w-full bg-white ml-8 lg:ml-[20vw] mt-20 p-16 items-center justify-center">
      <main className="w-full flex flex-col items-start space-y-4">
        <div className="flex items-center gap-5 mb-2">
          <p className="text-xl font-semibold">Login:</p>
          <input
            type="text"
            placeholder="Enter your username..."
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="border rounded-md px-4 py-2"
          />
        </div>
        <div className="flex items-center gap-5 mb-2">
          <p className="text-xl font-semibold">Passphrase:</p>
          <input
            type="password"
            placeholder="Enter your passphrase..."
            value={passphrase}
            onChange={(e) => setPassphrase(e.target.value)}
            className="border rounded-md px-4 py-2"
          />
        </div>
        <button
          className="text-2xl border-2 bg-gray-800 text-white px-4 py-1"
          onClick={onLoginClick}
        >
          Login
        </button>
      </main>

      {/* Modal for login status */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="relative w-[400px] p-8 bg-white rounded-lg shadow-lg transform transition-all duration-300 ease-in-out scale-100">
            {/* Success or failure message */}
            {loginStatus === "success" ? (
              <div className="flex flex-col items-center">
                <FaCheckCircle className="text-green-500 text-6xl mb-4" />
                <p className="text-2xl font-semibold text-green-600">Login successful!</p>
                <p className="mt-2 text-gray-500">You will be redirected shortly.</p>
              </div>
            ) : (
              <div className="flex flex-col items-center">
                <FaTimesCircle className="text-red-500 text-6xl mb-4" />
                <p className="text-2xl font-semibold text-red-600">Login failed!</p>
                <p className="mt-2 text-gray-500">Please check your credentials and try again.</p>
              </div>
            )}

            {/* Close button */}
            <button
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
              onClick={closeModal}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};


















// import React, { useState } from "react";
// import { handleLogin } from "./loginHandelSystem/hadelFunction";
// // import { useParams } from "react-router-dom";
// import Cookies from 'js-cookie'

// export const Login = () => {
//   const [username, setUsername] = useState("");
//   const [passphrase, setPassphrase] = useState("");
//   const apiDomain = process.env.REACT_APP_API_DOMAIN;
//   // Set random values for authToken, loginTime, and fbauthToken
// Cookies.set("authToken", );
// Cookies.set("loginTime", Date.now());
// Cookies.set('fbauthToken', "EAAO5JZBORQjIBOxoetQIjgkDf2mcci8qshYMfEMpVM2pgVjgh6Lb4iPN2a7bJlRgQEHsMMwZCVGXpolGwv4SEcqFKJGAccRs8NlTGvsCm30lBdvnOKwbxRa20ADpIzmPB7jedmeLJDdZABb4htjjbvtxmgesiIfoZBdRHUQqngadqZCSk6FMX2ZB3w1rSQq1K2Dl2EIVolJRFCBjB87uctz6c8");
// // // Cookies.remove("authToken", );
// // Cookies.remove("loginTime", Date.now());
// // Cookies.remove('fbauthToken', "EAAMNMF5aKJwBOZC0Sg7GJVgSDBa7HOA7Tz6uuPVp7qKiI0s8B2h4oF10RAyYd5UuTvEZCwOZBMQZBLzzTfuQpKYiEpZBOLIocTKH81CC3KdlKwZCgR9EqeYxvd3qPS5pzdB3U7OOYbyGdz6D1Y2ozZAKURzA4a9KVwxYaMDzsX1nR970DbPkTFNZBgY2WUsOftEkN3lMkHEK");
//   if(Cookies.get("authToken") && Cookies.get("loginTime") && !Cookies.get('fbauthToken')){
//     window.location.href = `${apiDomain}/auth/facebook?redirectTo=https://namasvi.indicarena.com/auth/facebook/callback`;
//     // window.location.href = 'https://dafukcrew.com/auth/facebook?redirectTo=https://namasvinexus.com/auth/facebook/callback';
//     // window.location.href = 'https://dafukcrew.com/auth/facebook?redirectTo=https://localhost:3000/auth/facebook/callback';
//     return;
// }
// if(Cookies.get("authToken") && Cookies.get("loginTime") && Cookies.get('fbauthToken')){
//   window.location.href = '/';
//   return;
// }
//   const onLoginClick = () => {
//     // Trim the input values before sending
//     const trimmedUsername = username.trim();
//     const trimmedPassphrase = passphrase.trim();

//     // Call the handleLogin function with the trimmed inputs
//     handleLogin(trimmedUsername, trimmedPassphrase);
//   };


//   return (
//     <div className="w-full bg-white ml-[20vw] mt-20 p-16 items-center justify-center">
//       <main className="w-full flex flex-col items-start space-y-4">
//         <div className="flex items-center gap-5 mb-2">
//           <p className="text-xl font-semibold">Login:</p>
//           <input
//             type="text"
//             placeholder="Enter your username..."
//             value={username}
//             onChange={(e) => setUsername(e.target.value)}
//             className="border rounded-md px-4 py-2"
//           />
//         </div>
//         <div className="flex items-center gap-5 mb-2">
//         <p className="text-xl font-semibold">Passphrase:</p>
//           <input
//             type="password"
//             placeholder="Enter your passphrase..."
//             value={passphrase}
//             onChange={(e) => setPassphrase(e.target.value)}
//             className="border rounded-md px-4 py-2"
//           />
//         </div>
//         <button 
//           className="text-2xl border-2 bg-gray-800 text-white px-4 py-1"
//           onClick={onLoginClick}
        
//         >
//           Login
//         </button>
        
//       </main>
//     </div>
//   );
// };
