import React, { useContext } from "react";
import TotalStatEvent from "./TotalStatEvent";
import EventTable from "./EventTable";
import { DataContext } from "../context/DataContext";
import LoadingScreen from "../utilities/LoadingScreen";

const Events = () => {
  const { eventData, loading } = useContext(DataContext);
  if (loading) return <LoadingScreen />;

  return (
    <div className="lg:ml-[20vw] lg:mr-[2vw] mt-0">
      {/* <TotalStatEvent /> */}
      <EventTable eventData={eventData} Dashboard={false} />
    </div>
  );
};

export default Events;
