import React from "react";
import TotalStatSponsor from "./totalSponsorStat/TotalStatSponsor";
import SponsorDetail from "./sponsorListTable/SponsorDetail";


const Sponsors = () => {
  return (
    <div className="">
      <SponsorDetail />
    </div>
  );
};

export default Sponsors;
