import React, { useContext } from "react";
import TotalStat from "../../components/content/Dashboard/TotalStat";
import EventTable from "./EventTable";
import { DataContext } from "../context/DataContext";
import LoadingScreen from "../utilities/LoadingScreen";


const Dashboard = () => {
  const { eventData, loading, error } = useContext(DataContext);


  if (loading) {
    return (
      <>
        <LoadingScreen />
      </>
    );
  }

  // Error handling
  if (error) {
    return (
      <div className="w-full flex justify-center items-center">
        <p>Error loading data. Please try again later.</p>
      </div>
    );
  }

  // Main dashboard content
  return (
    <div className="lg:ml-[18vw] lg:mr-[2vw]">
      <EventTable eventData={eventData} Dashboard={true} />
    </div>
  );
};

export default Dashboard;
