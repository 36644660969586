import React, { useState, useEffect } from "react";
import { ClipLoader } from "react-spinners"; // Import spinner from react-spinners

const LoadingScreen = () => {
  const [progress, setProgress] = useState(0);

  // Simulate progress incrementally
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => (prev >= 100 ? 100 : prev + 2));
    }, 100);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex items-center justify-center mt-[10vh] lg:mt-40 lg:ml-[20vw] flex-col space-y-6 p-10 ">
      {/* Enhanced Spinning Loader */}
      <ClipLoader color="#3b82f6" size={60} speedMultiplier={1} />

      {/* Loading Text with animated wave effect */}
      <div className="flex space-x-2 text-4xl font-bold text-blue-500">
        {"Loading".split("").map((letter, index) => (
          <span
            key={index}
            className="animate-bounce"
            style={{
              animationDelay: `${index * 0.1}s`,
            }}
          >
            {letter}
          </span>
        ))}
      </div>


      {/* <div className="w-3/4 bg-gray-300 rounded-full h-3 overflow-hidden">
        <div
          className="bg-gradient-to-r from-blue-500 to-indigo-500 h-full rounded-full transition-all duration-200 ease-in-out"
          style={{
            width: `${progress}%`,
          }}
        ></div>
      </div>


      <p className="text-lg text-blue-500 font-medium">
        Loading... {progress}%
      </p> */}
    </div>
  );
};

export default LoadingScreen;
