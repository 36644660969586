import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import backIcon from "./backIcon.png";

const AddCategory = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    startAge: "",
    endAge: "",
    gender: "",
    startCount: "",
    endCount: "",
  });

  const [selectedCategory, setSelectedCategory] = useState("generic");

  const categories = [
    { value: "generic", label: "Generic" },
    { value: "age", label: "Age" },
    { value: "gender", label: "Gender" },
    { value: "followercount", label: "Followers Count" },
  ];

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const accessToken = Cookies.get("fbauthToken");
    const apiDomain = process.env.REACT_APP_API_DOMAIN;

    const url = `${apiDomain}/api/v2/createcategory?categoryType=${selectedCategory}&access_token=${accessToken}`;

    let dataToSend = {};

    switch (selectedCategory) {
      case "age":
        dataToSend = {
          startAge: parseInt(formData.startAge, 10),
          endAge: parseInt(formData.endAge, 10),
          name: formData.name,
        };
        break;
      case "gender":
        dataToSend = {
          gender: formData.gender,
          name: formData.name,
        };
        break;
      case "followercount":
        dataToSend = {
          startCount: parseInt(formData.startCount, 10),
          endCount: parseInt(formData.endCount, 10),
          name: formData.name,
        };
        break;
      default:
        dataToSend = {
          name: formData.name,
          description: formData.description,
        };
    }
    
    axios
      .post(url, dataToSend)
      .then((response) => {
        alert("Category added successfully");
        navigate("/");
      })
      .catch((error) => {
        alert(`Error: ${error.response ? error.response.data : error.message}`);
      });
  };

  return (
    <div className="flex flex-col items-center">
      <div className="w-full bg-white shadow-lg rounded-lg p-6 border-2 ">
        <div className="flex items-center mb-6">
          <h2 className="text-3xl font-semibold text-gray-800  ">
            Add New Category
          </h2>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="flex flex-col space-y-4">
            <label className="text-xl font-semibold text-gray-700">
              Category Type
              <select
                id="categories"
                value={selectedCategory}
                onChange={handleCategoryChange}
                className="w-full mt-2 p-2 border border-gray-300 rounded-md bg-white focus:ring-2 focus:ring-blue-400 transition duration-200"
              >
                {categories.map((category) => (
                  <option key={category.value} value={category.value}>
                    {category.label}
                  </option>
                ))}
              </select>
            </label>

            {selectedCategory === "generic" && (
              <>
                <div>
                  <label className="text-xl font-semibold text-gray-700">
                    Name
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                      className="w-full mt-2 p-3 border border-gray-300 rounded-md bg-gray-100 focus:ring-2 focus:ring-blue-400 transition duration-200"
                    />
                  </label>
                </div>
                <div>
                  <label className="text-xl font-semibold text-gray-700">
                    Description
                    <input
                      type="text"
                      id="description"
                      name="description"
                      value={formData.description}
                      onChange={handleChange}
                      required
                      className="w-full mt-2 p-3 border border-gray-300 rounded-md bg-gray-100 focus:ring-2 focus:ring-blue-400 transition duration-200"
                    />
                  </label>
                </div>
              </>
            )}

            {selectedCategory === "age" && (
              <>
                <div>
                  <label className="text-xl font-semibold text-gray-700">
                    Start Age
                    <input
                      type="number"
                      id="startAge"
                      name="startAge"
                      value={formData.startAge}
                      onChange={handleChange}
                      required
                      className="w-full mt-2 p-3 border border-gray-300 rounded-md bg-gray-100 focus:ring-2 focus:ring-blue-400 transition duration-200"
                    />
                  </label>
                </div>
                <div>
                  <label className="text-xl font-semibold text-gray-700">
                    End Age
                    <input
                      type="number"
                      id="endAge"
                      name="endAge"
                      value={formData.endAge}
                      onChange={handleChange}
                      required
                      className="w-full mt-2 p-3 border border-gray-300 rounded-md bg-gray-100 focus:ring-2 focus:ring-blue-400 transition duration-200"
                    />
                  </label>
                </div>
                <div>
                  <label className="text-xl font-semibold text-gray-700">
                    Category Name
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                      className="w-full mt-2 p-3 border border-gray-300 rounded-md bg-gray-100 focus:ring-2 focus:ring-blue-400 transition duration-200"
                    />
                  </label>
                </div>
              </>
            )}

            {selectedCategory === "gender" && (
              <div>
                <label className="text-xl font-semibold text-gray-700">
                  Gender
                  <select
                    id="gender"
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                    className="w-full mt-2 p-2 border border-gray-300 rounded-md bg-white focus:ring-2 focus:ring-blue-400 transition duration-200"
                    required
                  >
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                </label>
              </div>
            )}

            {selectedCategory === "followercount" && (
              <>
                <div>
                  <label className="text-xl font-semibold text-gray-700">
                    Start Count
                    <input
                      type="number"
                      id="startCount"
                      name="startCount"
                      value={formData.startCount}
                      onChange={handleChange}
                      required
                      className="w-full mt-2 p-3 border border-gray-300 rounded-md bg-gray-100 focus:ring-2 focus:ring-blue-400 transition duration-200"
                    />
                  </label>
                </div>
                <div>
                  <label className="text-xl font-semibold text-gray-700">
                    End Count
                    <input
                      type="number"
                      id="endCount"
                      name="endCount"
                      value={formData.endCount}
                      onChange={handleChange}
                      required
                      className="w-full mt-2 p-3 border border-gray-300 rounded-md bg-gray-100 focus:ring-2 focus:ring-blue-400 transition duration-200"
                    />
                  </label>
                </div>
              </>
            )}

            <button
              type="submit"
              className="w-fit px-4 py-2 mx-auto mt-6 text-lg  text-white bg-blue-600 rounded-md shadow-md hover:bg-blue-700 transition duration-200"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddCategory;
