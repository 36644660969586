import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import Cookies from "js-cookie";
import { DataContext } from "../../context/DataContext";
import {
  FaChevronLeft,
  FaChevronRight,
  FaEdit,
  FaTrash,
  FaSave,
  FaTimes,
  FaChevronDown,
  FaChevronUp,
} from "react-icons/fa";
import { Tooltip } from "react-tooltip"; // Updated import
import "react-tooltip/dist/react-tooltip.css"; // Import CSS for tooltip

const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    backgroundColor: "#e5e7eb",
    border: "none",
    boxShadow: "none",
    minHeight: "56px",
    height: "56px",
    borderRadius: "0.375rem",
    padding: "0.5rem",
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: "0.25rem",
    borderRadius: "0.375rem",
    backgroundColor: "#e5e7eb",
  }),
  menuList: (provided) => ({
    ...provided,
    padding: "0.25rem",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#b3b3b3" : "#e5e7eb",
    color: "#000",
    cursor: "pointer",
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#000",
  }),
};

const CategoryTable = () => {
  
  const [currentPage, setCurrentPage] = useState(1);
  const eventsPerPage = 5;
  const apiDomain = process.env.REACT_APP_API_DOMAIN;
  const navigate = useNavigate();
  const {
    categories,
    genderCategories,
    ageCategories,
    followerCountCategories,
    loading,
  } = useContext(DataContext);

  const [editingId, setEditingId] = useState(null);
  
  const [editFormData, setEditFormData] = useState({
    name: "",
    description: "",
    startAge: "",
    endAge: "",
    gender: "",
    startCount: "",
    endCount: "",
  });
  const [showExpandedList, setShowExpandedList] = useState(true);

  if (loading) <></>;

  const categoryTypes = [
    { value: "generic", label: "Generic" },
    { value: "age", label: "Age" },
    { value: "gender", label: "Gender" },
    { value: "followercount", label: "Followers Count" },
  ];

  const [selectedCategory, setSelectedCategory] = useState("generic");

  let categoryData = [];
  let tableHeaders = [];

  switch (selectedCategory) {
    case "age":
      categoryData = ageCategories;
      tableHeaders = ["Category Name", "Start Age", "End Age", "Action"];
      break;
    case "gender":
      categoryData = genderCategories;
      tableHeaders = ["Gender", "Action"];
      break;
    case "followercount":
      categoryData = followerCountCategories;
      tableHeaders = ["Start Count", "End Count", "Action"];
      break;
    default:
      categoryData = categories;
      tableHeaders = ["Category Name", "Description", "Action"];
      break;
  }

  const indexOfLastEvent = currentPage * eventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - eventsPerPage;

  const currentEvents = categoryData
    ? categoryData.slice(indexOfFirstEvent, indexOfLastEvent)
    : [];

  // Use a fallback empty array for categoryData when it's null or undefined
  const totalPages = Math.ceil(
    (categoryData ? categoryData.length : 0) / eventsPerPage
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    setCurrentPage(1);
  };

  const deleteCategory = async (categoryId) => {
    try {
      const accessToken = Cookies.get("fbauthToken");
      await axios.delete(
        `${apiDomain}/api/v2/deletecategory?id=${categoryId}&access_token=${accessToken}&categoryType=${selectedCategory}`
      );
      alert("Category deleted successfully");
      navigate("/category");
    } catch (error) {
      alert(`Error: ${error.response ? error.response.data : error.message}`);
    }
  };

  const handleEditClick = (category) => {
    setEditingId(category.id);
    setEditFormData({
      name: category.name || "",
      description: category.description || "",
      startAge: category.startAge || "",
      endAge: category.endAge || "",
      gender: category.gender || "",
      startCount: category.startCount || "",
      endCount: category.endCount || "",
    });
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditFormData({ ...editFormData, [name]: value });
  };

  const handleSaveClick = async (categoryId) => {
    try {
      const accessToken = Cookies.get("fbauthToken");
      let url = `${apiDomain}/api/v2/updatecategory?id=${categoryId}&access_token=${accessToken}&categoryType=${selectedCategory}`;
      let payload = {};

      switch (selectedCategory) {
        case "generic":
          payload = {
            name: editFormData.name,
            description: editFormData.description,
          };
          break;
        case "age":
          payload = {
            name: editFormData.name,
            startAge: parseInt(editFormData.startAge, 10),
            endAge: parseInt(editFormData.endAge, 10),
          };
          break;
        case "gender":
          payload = { gender: editFormData.gender };
          break;
        case "followercount":
          payload = {
            startCount: parseInt(editFormData.startCount, 10),
            endCount: parseInt(editFormData.endCount, 10),
          };
          break;
        default:
          return;
      }

      await axios.put(url, payload);
      alert("Category updated successfully");
      setEditingId(null);
      window.location.reload();
    } catch (error) {
      alert("Failed to update category");
    }
  };

  const handleCancelClick = () => {
    setEditingId(null);
  };

  const renderTableRows = (category) => {
    return (
      <>
        {(selectedCategory === "generic" || selectedCategory === "age") && (
          <td className="w-fit px-4 py-2 border text-gray-700">
            {editingId === category.id ? (
              <input
                type="text"
                name="name"
                value={editFormData.name}
                onChange={handleEditChange}
                className="border rounded p-2 w-full"
              />
            ) : (
              category.name
            )}
          </td>
        )}

        {selectedCategory === "generic" && (
          <td className="w-fit px-4 py-2 border text-gray-700">
            {editingId === category.id ? (
              <input
                type="text"
                name="description"
                value={editFormData.description}
                onChange={handleEditChange}
                className="border rounded p-2 w-full"
              />
            ) : (
              category.description
            )}
          </td>
        )}

        {selectedCategory === "age" && (
          <>
            <td className="w-fit px-4 py-2 border text-gray-700">
              {editingId === category.id ? (
                <input
                  type="number"
                  name="startAge"
                  value={editFormData.startAge}
                  onChange={handleEditChange}
                  className="border rounded p-2 w-full"
                />
              ) : (
                category.startAge
              )}
            </td>
            <td className="w-fit px-4 py-2 border text-gray-700">
              {editingId === category.id ? (
                <input
                  type="number"
                  name="endAge"
                  value={editFormData.endAge}
                  onChange={handleEditChange}
                  className="border rounded p-2 w-full"
                />
              ) : (
                category.endAge
              )}
            </td>
          </>
        )}

        {selectedCategory === "gender" && (
          <td className="px-4 py-2 border text-gray-700">
            {editingId === category.id ? (
              <input
                type="text"
                name="gender"
                value={editFormData.gender}
                onChange={handleEditChange}
                className="border rounded p-2 w-full"
              />
            ) : (
              category.gender
            )}
          </td>
        )}

        {selectedCategory === "followercount" && (
          <>
            <td className="px-4 py-2 border text-gray-700">
              {editingId === category.id ? (
                <input
                  type="number"
                  name="startCount"
                  value={editFormData.startCount}
                  onChange={handleEditChange}
                  className="border rounded p-2 w-full"
                />
              ) : (
                category.startCount
              )}
            </td>
            <td className="px-4 py-2 border text-gray-700">
              {editingId === category.id ? (
                <input
                  type="number"
                  name="endCount"
                  value={editFormData.endCount}
                  onChange={handleEditChange}
                  className="border rounded p-2 w-full"
                />
              ) : (
                category.endCount
              )}
            </td>
          </>
        )}

        <td className="px-4 py-2 border text-center">
          {editingId === category.id ? (
            <div className="flex">
              <button
                className="bg-green-500 text-white px-2 py-1 rounded mr-2"
                onClick={() => handleSaveClick(category.id)}
                data-tooltip-id="tooltip"
                data-tooltip-content="Save"
              >
                <FaSave />
              </button>
              <button
                className="bg-red-500 text-white px-2 py-1 rounded"
                onClick={() => setEditingId(null)}
                data-tooltip-id="tooltip"
                data-tooltip-content="Cancel"
              >
                <FaTimes />
              </button>
            </div>
          ) : (
            <div className="flex">
              <button
                className="bg-blue-500 text-white px-2 py-1 rounded mr-2"
                onClick={() => handleEditClick(category)}
                data-tooltip-id="tooltip"
                data-tooltip-content="Edit"
              >
                <FaEdit />
              </button>
              <button
                className="bg-red-500 text-white px-2 py-1 rounded"
                onClick={() => deleteCategory(category.id)}
                data-tooltip-id="tooltip"
                data-tooltip-content="Delete"
              >
                <FaTrash />
              </button>
            </div>
          )}

          {/* Tooltip Component */}
          <Tooltip id="tooltip" place="top" />
        </td>
      </>
    );
  };

  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    if (
      i === 1 ||
      (i >= currentPage - 1 && i <= currentPage + 1) ||
      i === totalPages
    ) {
      pageNumbers.push(i);
    } else if (i === currentPage - 2 || i === currentPage + 2) {
      pageNumbers.push("...");
    }
  }

  return (
    <div className="p-6 bg-white shadow-md rounded-lg mt-5 border-2">
      <div className="flex justify-between items-center">
        <button
          className="bg-gray-700 text-white px-4 rounded mb-4 h-12 flex items-center gap-2"
          onClick={() => setShowExpandedList(!showExpandedList)}
        >
          {showExpandedList ? "Collapse List" : "See Category List"}
          {showExpandedList ? <FaChevronUp /> : <FaChevronDown />}
        </button>

        <div className={`mb-4 ${showExpandedList ? "block" : "hidden"}`}>
          <label
            htmlFor="categoryType"
            className="text-blue-700 font-semibold mb-2 flex items-center gap-2"
          >
            Select Category Type

      
          </label>
          <select
            id="categoryType"
            value={selectedCategory}
            onChange={handleCategoryChange}
            className="border shadow-xl border-blue-40 rounded p-2"
          >
            {categoryTypes.map((type) => (
              <option key={type.value} value={type.value}>
                {type.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      {showExpandedList && showExpandedList && (
        <>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-300">
              <thead>
                <tr className="bg-gray-100">
                  {tableHeaders.map((header) => (
                    <th
                      key={header}
                      className="px-4 py-2 border-b text-left text-gray-700"
                    >
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {currentEvents.map((category) => (
                  <tr key={category.id}>{renderTableRows(category)}</tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="mt-4 flex items-center justify-center space-x-2">
            <button
              onClick={() => paginate(currentPage - 1)}
              disabled={currentPage === 1}
              className="bg-gray-500 text-white w-8 h-8 flex items-center justify-center rounded-full hover:bg-gray-600"
            >
              <FaChevronLeft />
            </button>

            {pageNumbers.map((number, index) =>
              number === "..." ? (
                <span
                  key={index}
                  className="text-gray-700 text-xs w-8 h-8 border border-gray-300 rounded-full"
                >
                  {number}
                </span>
              ) : (
                <button
                  key={index}
                  onClick={() => paginate(number)}
                  className={`text-gray-700 text-sm w-8 h-8 border rounded-full ${
                    number === currentPage
                      ? "bg-blue-500 text-white"
                      : "border-gray-300 hover:bg-gray-100"
                  }`}
                >
                  {number}
                </button>
              )
            )}

            <button
              onClick={() => paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="bg-gray-500 text-white w-8 h-8 flex items-center justify-center rounded-full hover:bg-gray-600"
            >
              <FaChevronRight />
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default CategoryTable;
