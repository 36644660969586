const UserDetail = ({ currentUser, instagramData }) => {
    if (!currentUser && !instagramData) return (
      <div className="h-screen flex items-center justify-center bg-gradient-to-br from-purple-600 to-indigo-600 text-white">
        <p className="text-2xl font-semibold">No user data available.</p>
      </div>
    );
  
    return (
      <div className="min-h-screen bg-gradient-to-br rounded-sm sm:p-6">
        <div className="w-full mx-auto bg-white rounded-2xl shadow-2xl overflow-hidden">
          
          {/* Header Section */}
          <div className="bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white text-center py-8 ">
            <h2 className="text-4xl font-extrabold tracking-wider">User Details</h2>
          </div>
  
          {/* Content Wrapper */}
          <div className="p-8 space-y-8">   
            {/* General User Info */}
            <div className="bg-gray-50 p-6 rounded-lg shadow-lg">
              <h3 className="text-2xl font-semibold text-gray-800 mb-4">General Information</h3>
              <div className="space-y-4">
                <InfoRow label="Name" value={currentUser?.name || "N/A"} />
                <InfoRow label="Email" value={currentUser?.email || "N/A"} />
                <InfoRow label="Gender" value={currentUser?.gender || "N/A"} />
                <InfoRow label="Mobile" value={currentUser?.mobile_no || "N/A"} />
                <InfoRow 
                  label="Address"
                  value={`${currentUser?.home_street || ""}, ${currentUser?.building_no || ""}, ${currentUser?.city || ""}, ${currentUser?.state || ""}, ${currentUser?.pin_code || ""}`}
                />
              </div>
            </div>
  
            {/* Instagram Info Section */}
            {instagramData && (
              <div className="bg-gradient-to-tr from-pink-400 to-indigo-500 p-6 rounded-lg shadow-lg text-white">
                <h3 className="text-2xl font-semibold mb-4">Instagram Details</h3>
                <div className="flex flex-col md:flex-row items-center">
                  <img
                    src={instagramData.profile_picture_url || "/default-profile.jpg"}
                    alt={instagramData.name}
                    className="w-32 h-32 rounded-full border-4 border-white shadow-md mb-4 md:mr-6"
                  />
                  <div className="space-y-2">
                    <p className="text-lg">
                      <strong>Username:</strong> @{instagramData.username}
                    </p>
                    <div className="flex space-x-6">
                      <p><strong>Followers:</strong> {instagramData.followers_count}</p>
                      <p><strong>Following:</strong> {instagramData.follows_count}</p>
                      <p><strong>Posts:</strong> {instagramData.media_count}</p>
                    </div>
                    {instagramData.website && (
                      <p>
                        <strong>Website:</strong>{" "}
                        <a
                          href={instagramData.website}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-yellow-300 hover:underline"
                        >
                          {instagramData.website}
                        </a>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };
  
  // Helper component for cleaner code
  const InfoRow = ({ label, value }) => (
    <p className="flex justify-between border-b border-gray-200 pb-2">
      <span className="font-semibold text-gray-700">{label}:</span>
      <span>{value}</span>
    </p>
  );
  
  export default UserDetail;
  