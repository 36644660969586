import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DataContext } from "../../../../newComponents/context/DataContext";
import axios from "axios";
import Cookies from "js-cookie";
import SponsorDetails from "../sponsorDetails/SponsorDetails";
import LoadingScreen from "../../../../newComponents/utilities/LoadingScreen";

const SponsorDetail = () => {
  const { eventData, sponsors, loading } = useContext(DataContext);
  if (loading) <LoadingScreen />

  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState("All");
  const [selectedEvent, setSelectedEvent] = useState("All");

  const [participants, setParticipants] = useState([]);
  const [totalParticipants, setTotalParticipants] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [passphrase, setPassphrase] = useState("");
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const navigate = useNavigate();

  const totalPages = Math.ceil(participants ? participants.length / pageSize : []);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (selectedEvent && selectedEvent !== "All") {
      const validEventIds = eventData
        .filter((event) => event.eventID === selectedEvent.eventID)
        .map((event) => event.eventID);
      const filteredParticipants = totalParticipants.filter((participant) =>
        participant.eventCategories[0].some((category) =>
          validEventIds.includes(category.eventId)
        )
      );
      setParticipants(filteredParticipants);
    } else {
      if (sponsors) {
        setParticipants(sponsors.sponsors);
        setTotalParticipants(sponsors.sponsors);
        return;
      }
    }
  }, [selectedEvent, currentPage, pageSize, loading]);

  const handleFilterChange = (event) => {
    const eventName = event.target.value;
    if (eventName === "All") {
      setFilter(eventName);
      setCurrentPage(1);
      setParticipants(sponsors.sponsors);
      return;
    }
    setFilter(eventName);
    setCurrentPage(1);
    const selected = eventData.find((e) => e.eventName === eventName);
    setSelectedEvent(selected || null);
  };

  const handleRowClick = (sponsor) => {
    setSelectedParticipant(sponsor);
    navigate(
      `/sponsors/${sponsor.user_id}/${sponsor.ig_user.id}`,
      {
        state: {
          sponsor: sponsor,
        },
      }
    );
  };

  const handlePassphraseSubmit = () => {
    if (passphrase.trim() !== "") {
      navigate(
        `/sponsors/${selectedParticipant.user_id}/${selectedParticipant.ig_user.id}`,
        {
          state: {
            sponsor: selectedParticipant,
          },
        }
      );
      setIsModalOpen(false);
      setPassphrase("");
    }
  };

  const handlePageClick = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const renderPageNumbers = () => {
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(currentPage + 1, totalPages);

    const pages = [];
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          className={`mx-1 w-8 h-8 rounded-full flex items-center justify-center ${
            i === currentPage
              ? "bg-blue-500 text-white"
              : "bg-gray-200 text-gray-800"
          }`}
          onClick={() => handlePageClick(i)}
        >
          {i}
        </button>
      );
    }
    return pages;
  };

  return (
    <>
      {/* <div className="flex justify-between my-12 ">
        <div>
          <select
            className="border rounded px-3 py-2"
            value={filter}
            onChange={handleFilterChange}
          >
            <option key="All" value="All">
              All
            </option>
            {eventData && eventData.map((x) => (
              <option key={x.eventName} value={x.eventName}>
                {x.eventName}
              </option>
            ))}
          </select>
        </div>
        <div className="relative">
          <input
            type="text"
            placeholder="Search..."
            className="border rounded px-3 py-2 w-full"
          />
        </div>
      </div> */}
      <div className="overflow-x-auto lg:ml-[20vw] lg:mr-[2vw]">
        <table className="min-w-full table-auto border ">
          <thead>
            <tr className="bg-gray-100 ">
              <th className="px-4 py-4 text-left">Profile Pic.</th>
              <th className="px-4 py-4 text-left">Insta User Name</th>
              <th className="px-4 py-4 text-left">Followers</th>
              <th className="px-4 py-4 text-left">Name</th>
              <th className="px-4 py-4 text-left">Events Sponsored</th>
            </tr>
          </thead>
          <tbody>
            {participants && participants.slice((currentPage - 1) * pageSize, currentPage * pageSize).map((part, index) => (
              <tr
                key={index}
                className="hover:bg-gray-100 cursor-pointer border border-gray-300"
                onClick={() => handleRowClick(part)}
              >
                <td className="px-4 py-2">
                  <img
                    src={part.ig_user.profile_picture_url}
                    alt="profile-pic"
                    className="w-12 h-12 rounded-full"
                  />
                </td>
                <td className="px-4 py-2 ">{part.ig_user.username}</td>
                <td className="px-4 py-2">{part.ig_user.followers_count}</td>
                <td className="px-4 py-2">{part.ig_user.name}</td>
                <td className="px-4 py-2">{part.eventsSponsored}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="flex justify-center items-center mt-4 space-x-2">
        <button
          onClick={() => handlePageClick(currentPage - 1)}
          className={`p-2 rounded-full ${
            currentPage === 1
              ? "bg-gray-300 text-gray-500"
              : "bg-blue-500 text-white hover:bg-blue-600"
          }`}
          disabled={currentPage === 1}
        >
          &lt;
        </button>

        {renderPageNumbers()}

        <button
          onClick={() => handlePageClick(currentPage + 1)}
          className={`p-2 rounded-full ${
            currentPage === totalPages
              ? "bg-gray-300 text-gray-500"
              : "bg-blue-500 text-white hover:bg-blue-600"
          }`}
          disabled={currentPage === totalPages}
        >
          &gt;
        </button>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded p-6 w-full max-w-sm">
            <h3 className="text-lg mb-4">Enter Passphrase</h3>
            <input
              type="text"
              value={passphrase}
              onChange={(e) => setPassphrase(e.target.value)}
              placeholder="Passphrase"
              className="border w-full px-3 py-2 rounded mb-4"
            />
            <div className="flex justify-between">
            <button
                onClick={() => setIsModalOpen(false)}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
              >
                Cancel
              </button>
              <button
                onClick={handlePassphraseSubmit}
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
              >
                OK
              </button>
            
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SponsorDetail;


// import React, { useContext, useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import { DataContext } from "../../../context/DataContext";
// import axios from "axios";
// import Cookies from "js-cookie";

// import LoadingScreen from "../../../context/LoadingScreen";

// const SponsorDetail = () => {
//   const { eventData, sponsors, loading } = useContext(DataContext);
//   if (loading) <LoadingScreen />

//   console.log("sponsors", sponsors)
//   const [currentPage, setCurrentPage] = useState(1);
//   const [filter, setFilter] = useState("All");
//   const [selectedEvent, setSelectedEvent] = useState("All");

//   const [participants, setParticipants] = useState([]);
//   const [totalParticipants, setTotalParticipants] = useState(null);
//   const [pageSize, setPageSize] = useState(10);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [passphrase, setPassphrase] = useState("");
//   const [selectedParticipant, setSelectedParticipant] = useState(null);
//   const navigate = useNavigate();

//   const totalPages = Math.ceil(participants ? participants.length / pageSize : []);

//   useEffect(() => {
//     if (loading) {
//       return;
//     }
//     if (selectedEvent && selectedEvent !== "All") {
//       const validEventIds = eventData
//         .filter((event) => event.eventID === selectedEvent.eventID)
//         .map((event) => event.eventID);
//       const filteredParticipants = totalParticipants.filter((participant) =>
//         participant.eventCategories[0].some((category) =>
//           validEventIds.includes(category.eventId)
//         )
//       );
//       setParticipants(filteredParticipants);
//     } else {
//       if (sponsors) {
//         setParticipants(sponsors.sponsors);
//         setTotalParticipants(sponsors.sponsors);
//         return;
//       }
//     }
//   }, [selectedEvent, currentPage, pageSize, loading]);

//   const handleFilterChange = (event) => {
//     const eventName = event.target.value;
//     if (eventName === "All") {
//       setFilter(eventName);
//       setCurrentPage(1);
//       setParticipants(sponsors.sponsors);
//       return;
//     }
//     setFilter(eventName);
//     setCurrentPage(1);
//     const selected = eventData.find((e) => e.eventName === eventName);
//     setSelectedEvent(selected || null);
//   };

//   const handleRowClick = (sponsor) => {
//     setSelectedParticipant(sponsor);
//     navigate(
//       `/sponsors/${sponsor.user_id}/${sponsor.ig_user.id}`,
//       {
//         state: {
//           sponsor: sponsor,
//         },
//       }
//     );
//   };

//   const handlePassphraseSubmit = () => {
//     if (passphrase.trim() !== "") {
//       navigate(
//         `/sponsors/${selectedParticipant.user_id}/${selectedParticipant.ig_user.id}`,
//         {
//           state: {
//             sponsor: selectedParticipant,
//           },
//         }
//       );
//       setIsModalOpen(false);
//       setPassphrase("");
//     }
//   };

//   const handlePageClick = (pageNumber) => {
//     if (pageNumber >= 1 && pageNumber <= totalPages) {
//       setCurrentPage(pageNumber);
//     }
//   };

//   const renderPageNumbers = () => {
//     let startPage = Math.max(currentPage - 1, 1);
//     let endPage = Math.min(currentPage + 1, totalPages);

//     const pages = [];
//     for (let i = startPage; i <= endPage; i++) {
//       pages.push(
//         <button
//           key={i}
//           className={`mx-1 w-8 h-8 rounded-full flex items-center justify-center ${
//             i === currentPage
//               ? "bg-blue-500 text-white"
//               : "bg-gray-200 text-gray-800"
//           }`}
//           onClick={() => handlePageClick(i)}
//         >
//           {i}
//         </button>
//       );
//     }
//     return pages;
//   };

//   return (
//     <>
//       <div className="flex justify-between my-12 ">
//         <div>
//           <select
//             className="border rounded px-3 py-2"
//             value={filter}
//             onChange={handleFilterChange}
//           >
//             <option key="All" value="All">
//               All
//             </option>
//             {eventData && eventData.map((x) => (
//               <option key={x.eventName} value={x.eventName}>
//                 {x.eventName}
//               </option>
//             ))}
//           </select>
//         </div>
//         <div className="relative">
//           <input
//             type="text"
//             placeholder="Search..."
//             className="border rounded px-3 py-2 w-full"
//           />
//         </div>
//       </div>
//       <div className="overflow-x-auto">
//         <table className="min-w-full table-auto border ">
//           <thead>
//             <tr className="bg-gray-100 ">
//               <th className="px-4 py-4 text-left">Profile Pic.</th>
//               <th className="px-4 py-4 text-left">Insta User Name</th>
//               <th className="px-4 py-4 text-left">Followers</th>
//               <th className="px-4 py-4 text-left">Name</th>
//               <th className="px-4 py-4 text-left">Events Sponsored</th>
//             </tr>
//           </thead>
//           <tbody>
//             {participants && participants.slice((currentPage - 1) * pageSize, currentPage * pageSize).map((part, index) => (
//               <tr
//                 key={index}
//                 className="hover:bg-gray-100 cursor-pointer border border-gray-300"
//                 onClick={() => handleRowClick(part)}
//               >
//                 <td className="px-4 py-2">
//                   <img
//                     src={part.ig_user.profile_picture_url}
//                     alt="profile-pic"
//                     className="w-12 h-12 rounded-full"
//                   />
//                 </td>
//                 <td className="px-4 py-2 ">{part.ig_user.username}</td>
//                 <td className="px-4 py-2">{part.ig_user.followers_count}</td>
//                 <td className="px-4 py-2">{part.ig_user.name}</td>
//                 <td className="px-4 py-2">{part.eventsSponsored}</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       </div>

//       {/* Pagination */}
//       <div className="flex justify-center items-center mt-4 space-x-2">
//         <button
//           onClick={() => handlePageClick(currentPage - 1)}
//           className={`p-2 rounded-full ${
//             currentPage === 1
//               ? "bg-gray-300 text-gray-500"
//               : "bg-blue-500 text-white hover:bg-blue-600"
//           }`}
//           disabled={currentPage === 1}
//         >
//           &lt;
//         </button>

//         {renderPageNumbers()}

//         <button
//           onClick={() => handlePageClick(currentPage + 1)}
//           className={`p-2 rounded-full ${
//             currentPage === totalPages
//               ? "bg-gray-300 text-gray-500"
//               : "bg-blue-500 text-white hover:bg-blue-600"
//           }`}
//           disabled={currentPage === totalPages}
//         >
//           &gt;
//         </button>
//       </div>

//       {/* Modal */}
//       {isModalOpen && (
//         <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
//           <div className="bg-white rounded p-6 w-full max-w-sm">
//             <h3 className="text-lg mb-4">Enter Passphrase</h3>
//             <input
//               type="text"
//               value={passphrase}
//               onChange={(e) => setPassphrase(e.target.value)}
//               placeholder="Passphrase"
//               className="border w-full px-3 py-2 rounded mb-4"
//             />
//             <div className="flex justify-between">
//             <button
//                 onClick={() => setIsModalOpen(false)}
//                 className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
//               >
//                 Cancel
//               </button>
//               <button
//                 onClick={handlePassphraseSubmit}
//                 className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
//               >
//                 OK
//               </button>
            
//             </div>
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// export default SponsorDetail;
