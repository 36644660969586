import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { FaArrowLeft } from "react-icons/fa";
import collapseIcon from "./assets/reduce.png";
import expandIcon from "./assets/expand-arrows.png";
import { DataContext } from "../context/DataContext";
import Select from "react-select";
import { uploadFile } from "../../firebase/fileUpload";
import IndependentMediaUpload from "./IndependentMediaUpload";
import {
  FaTrashAlt,
  FaCheckCircle,
  FaTimesCircle,
  FaPlusCircle,
} from "react-icons/fa";
import LoadingScreen from "../utilities/LoadingScreen";
import AdditionalDataForm from "./AdditionalDataForm";
import SponsorDetailForm from "../sponsor/SponsorDetailForm";
import AddCategoryModal from "../category/AddCategory/AddCategoryModal";

const EventType = Object.freeze({
  Short: "short",
  Long: "long",
});

const formatDateForInput = (date) => {
  if (!date) return "";
  const d = new Date(date);
  const month = ("0" + (d.getMonth() + 1)).slice(-2);
  const day = ("0" + d.getDate()).slice(-2);
  const year = d.getFullYear();
  return `${year}-${month}-${day}`;
};

const EventForm = () => {
  const {
    categories,
    ageCategories,
    genderCategories,
    followerCountCategories,
    venues,
    loading,
  } = useContext(DataContext);

  if (loading) <LoadingScreen />;

  const apiDomain = process.env.REACT_APP_API_DOMAIN;
  const location = useLocation();
  const navigate = useNavigate();

  const [isEditMode, setIsEditMode] = useState(false);
  const [formData, setFormData] = useState({
    eventName: "",
    categories: [],
    description: "",
    startDate: "",
    endDate: "",
    venue: 0,
    votingDuration: 0,
    eventType: "",
    termsConditions: "",
    instructions: "",
    sponsorData: [],
    templateData: null,
  });
  const [modalOpen, setModalOpen] = useState(false); // For modal control
  const [selectedCategories, setSelectedCategories] = useState({
    generic: [],
    age: [],
    gender: [],
    followercount: [],
  });
  const [templateData, setTemplateData] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [selectedEventType, setSelectedEventType] = useState("");
  const [isEventDetailsOpen, setIsEventDetailsOpen] = useState(true);
  const [isCategoriesDetailsOpen, setIsCategoriesDetailsOpen] = useState(true);
  const [isTemplateOpen, setIsTemplateOpen] = useState(true);
  const [eventId, setEventId] = useState(null);
  const [savedData, setSavedData] = useState([]);
  const [eventCreated, setEventCreated] = useState(false);

  useEffect(() => {
    if (location.state && location.state.event) {
      setIsEditMode(true);
      const event = location.state.event;
      setFormData(event);
      setTemplateData(event.templateData);
      setImagePreview(event.templateData);
      setSavedData(event.sponsorData || []);

      // Populate selected categories based on event data in edit mode
      if (event.categories) {
        setSelectedCategories({
          generic: categories
            .filter((cat) => event.categories.includes(cat.id))
            .map((cat) => ({ value: cat.id, label: cat.name })),
          age: ageCategories
            .filter((cat) => event.ageCategories.includes(cat.id))
            .map((cat) => ({ value: cat.id, label: cat.name })),
          gender: genderCategories
            .filter((cat) => event.genderCategories.includes(cat.id))
            .map((cat) => ({ value: cat.id, label: cat.gender })),
          followercount: followerCountCategories
            .filter((cat) => event.followerCountCategories.includes(cat.id))
            .map((cat) => ({
              value: cat.id,
              label: `${cat.startCount}-${cat.endCount}`,
            })),
        });
      }
    }
  }, [
    location.state,
    categories,
    ageCategories,
    genderCategories,
    followerCountCategories,
  ]);

  const getEnumValues = (enumObject) => Object.values(enumObject);

  const handleEventTypeChange = (event) => {
    const selectedValue = event.target.value;
    setFormData((prevData) => ({
      ...prevData,
      eventType: selectedValue,
    }));
    setSelectedEventType(selectedValue);
  };

  const handleCategoryChange = (selectedOptions, categoryType) => {
    if (
      selectedOptions &&
      selectedOptions.some((option) => option.value === "all")
    ) {
      let allOptions = [];
      switch (categoryType) {
        case "generic":
          allOptions = categories.map((cat) => ({
            value: cat.id,
            label: cat.name,
          }));
          break;
        case "age":
          allOptions = ageCategories.map((cat) => ({
            value: cat.id,
            label: cat.name,
          }));
          break;
        case "gender":
          allOptions = genderCategories.map((cat) => ({
            value: cat.id,
            label: cat.gender,
          }));
          break;
        case "followercount":
          allOptions = followerCountCategories.map((cat) => ({
            value: cat.id,
            label: `${cat.startCount}-${cat.endCount}`,
          }));
          break;
        default:
          break;
      }
      setSelectedCategories((prevState) => ({
        ...prevState,
        [categoryType]: allOptions,
      }));
    } else {
      setSelectedCategories((prevState) => ({
        ...prevState,
        [categoryType]: selectedOptions,
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newValue =
      name === "venue" || name === "votingDuration"
        ? parseInt(value, 10) || 0
        : value;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: newValue }));
  };

  const handleCreateSubmit = async () => {
    setModalOpen(true); // Open modal for confirmation before creating the event
  };

  const confirmCreateEvent = async () => {
    const accessToken = Cookies.get("fbauthToken");
    const url = await uploadFile(templateData);
    const jsonData = {
      ...formData,
      templateData: url,
      categories: selectedCategories.generic.map((option) => option.value),
      ageCategories: selectedCategories.age.map((option) => option.value),
      genderCategories: selectedCategories.gender.map((option) => option.value),
      followerCountCategories: selectedCategories.followercount.map(
        (option) => option.value
      ),
    };
    try {
      let response = {};
      if (isEditMode) {
        response = await axios.put(
          `${apiDomain}/api/v2/updateevent?id=${formData.eventID}&access_token=${accessToken}`,
          jsonData,
          { headers: { "Content-Type": "application/json" } }
        );
      } else {
        response = await axios.post(
          `${apiDomain}/api/v2/createevent?access_token=${accessToken}`,
          jsonData,
          { headers: { "Content-Type": "application/json" } }
        );
      }

      if (response.status === 200) {
        if (isEditMode) {
          alert("Event updated successfully!");
        }
        else{
          alert("Event create successfully!");
        }
        setEventCreated(true);
        setModalOpen(false); // Close the modal after success
        if (selectedEventType === "long") {
          alert("Set long Event Media Data Now!");
        }
        setEventId(response.data.event_id);
      } else {
        alert(`Error: ${response}`);
      }
    } catch (error) {
      console.error("Error creating the event!", error);
      alert("There was an error creating the event. Please try again.");
    }
  };
  // Delete sponsor data
  const handleDeleteSponsor = (index) => {
    const updatedSponsorData = formData.sponsorData.filter(
      (_, i) => i !== index
    );
    setFormData((prevData) => ({
      ...prevData,
      sponsorData: updatedSponsorData,
    }));
  };
  // Rename this function to match the prop expected in SponsorDetailForm
  const onSponsorDataChange = (newSponsorData) => {
    setFormData((prevData) => ({
      ...prevData,
      sponsorData: [...prevData.sponsorData, newSponsorData],
    }));
  };

  const handleDelete = (index) => {
    const newData = savedData.filter((_, i) => i !== index);
    setSavedData(newData);
    setFormData((prevFormData) => ({
      ...prevFormData,
      sponsorData: newData,
    }));
  };

  const handleVenueChange = (event) => {
    const selectedId = event.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      venue: selectedId,
    }));
  };
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setTemplateData(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  useEffect(() => {
    return () => {
      if (imagePreview) {
        URL.revokeObjectURL(imagePreview);
      }
    };
  }, [imagePreview]);

  // Inline Modal Component
  const ConfirmationModal = ({
    open,
    onClose,
    onConfirm,
    formData,
    // venues = [],
  }) => {
    if (!open) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white rounded-lg shadow-lg w-5/6 max-w-2xl max-h-[80vh] p-6 overflow-y-auto">
          <h3 className="text-2xl font-semibold mb-4 text-blue-600">
            Confirm Event Details
          </h3>
          <div className="space-y-2 text-gray-800">
            <p>
              <strong>Event Name:</strong> {formData.eventName}
            </p>
            <p>
              <strong>Description:</strong> {formData.description}
            </p>
            <p>
              <strong>Start Date:</strong>{" "}
              {formatDateForInput(formData.startDate)}
            </p>
            <p>
              <strong>End Date:</strong> {formatDateForInput(formData.endDate)}
            </p>
            <p>
              <strong>Venue:</strong>
            </p>
            <p>
              <strong>Event Type:</strong> {formData.eventType}
            </p>
            <p>
              <strong>Terms & Conditions:</strong> {formData.termsConditions}
            </p>
            <p>
              <strong>Instructions:</strong> {formData.instructions}
            </p>

            {/* Category Details */}
            <div>
              <strong>Category Details:</strong>
              <ul className="list-disc ml-6">
                {Object.entries(selectedCategories).map(([key, value]) => (
                  <li key={key}>
                    <strong>{key}:</strong>{" "}
                    {value.map((category) => category.label).join(", ") ||
                      "N/A"}
                  </li>
                ))}
              </ul>
            </div>

            {/* Sponsor Details */}
            <div>
              <strong>Sponsor Details:</strong>
              <ul className="list-disc ml-6">
                {savedData.length > 0 ? (
                  savedData.map((sponsor, index) => (
                    <li key={index}>
                      <strong>Category:</strong> {sponsor.sponsorCategory},{" "}
                      <strong>Coupons:</strong> {sponsor.noOfCoupons},{" "}
                      <strong>Gift Hampers:</strong> {sponsor.noOfGiftHampers},{" "}
                      <strong>Description:</strong> {sponsor.description},{" "}
                      <strong>Price:</strong> {sponsor.price}
                    </li>
                  ))
                ) : (
                  <p>No Sponsor Details Available</p>
                )}
              </ul>
            </div>

            {/* Template Data */}
            <div>
              <strong>Template:</strong>
              {imagePreview ? (
                <img
                  src={imagePreview}
                  alt="Template Preview"
                  className="mt-2 w-60 h-60 rounded"
                />
              ) : (
                <p>No Template Available</p>
              )}
            </div>
          </div>
          <div className="flex justify-end mt-6 space-x-4">
            <button
              onClick={onClose}
              className="flex items-center bg-gray-300 text-gray-700 px-4 py-2 rounded-md shadow hover:bg-gray-400 transition"
            >
              <FaTimesCircle className="mr-2" /> Cancel
            </button>
            <button
              onClick={onConfirm}
              className="flex items-center bg-blue-500 text-white px-4 py-2 rounded-md shadow hover:bg-blue-600 transition"
            >
              <FaCheckCircle className="mr-2" /> Confirm
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="flex flex-col items-start mt-8 lg:ml-[20vw] lg:mr-8">
        {/* Back Button */}
        <button
          onClick={() => navigate(-1)}
          className="flex items-center text-blue-500 hover:text-blue-700 transition mb-4"
        >
          <FaArrowLeft className="mr-2" /> Back
        </button>
        <form
          className="w-full bg-white p-6 rounded-lg shadow-md"
          onSubmit={(e) => e.preventDefault()} // Prevent default submit action
        >
          {/* Event Details */}
          <div className="w-full p-4 border rounded-md mb-4">
            <div className="w-full flex items-center justify-between">
              <h3 className="text-xl font-semibold text-blue-600 bg-gray-100 px-3 py-2 rounded-md mb-2">
                Event Details
              </h3>
              <button
                type="button"
                onClick={() => setIsEventDetailsOpen(!isEventDetailsOpen)}
                className="flex items-center"
              >
                <img
                  src={isEventDetailsOpen ? collapseIcon : expandIcon}
                  alt={isEventDetailsOpen ? "Collapse" : "Expand"}
                  className="w-6 h-6"
                />
              </button>
            </div>
            {isEventDetailsOpen && (
              <div className="mt-4">
                {/* Event Name */}
                <div className="flex flex-wrap gap-8 mb-4">
                  <div className="relative flex-1 min-w-[200px]">
                    <input
                      type="text"
                      id="eventName"
                      name="eventName"
                      value={formData.eventName}
                      onChange={handleChange}
                      required
                      placeholder=" "
                      className="w-full h-14 bg-gray-200 border border-gray-300 rounded-md px-4 py-2 peer"
                    />
                    <label
                      htmlFor="eventName"
                      className="absolute text-sm text-blue-500 bg-white px-1 left-3 -top-2 transition-all peer-placeholder-shown:top-4 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500"
                    >
                      Event Name
                    </label>
                  </div>
                </div>

                {/* Description */}
                <div className="flex flex-wrap gap-8 mb-4">
                  <div className="relative flex-1 min-w-[200px]">
                    <textarea
                      id="description"
                      name="description"
                      value={formData.description}
                      onChange={handleChange}
                      required
                      placeholder=" "
                      className="w-full min-h-20 bg-gray-200 border border-gray-300 rounded-md px-4 py-2 peer "
                    ></textarea>
                    <label
                      htmlFor="description"
                      className="absolute text-sm text-blue-500 bg-white px-1 left-3 -top-2 transition-all peer-placeholder-shown:top-4 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500"
                    >
                      Description
                    </label>
                  </div>
                </div>

                {/* Terms & Conditions */}
                <div className="flex flex-wrap gap-8 mb-4">
                  <div className="relative flex-1 min-w-[200px]">
                    <textarea
                      id="termsConditions"
                      name="termsConditions"
                      value={formData.termsConditions}
                      onChange={handleChange}
                      required
                      placeholder=" "
                      className="w-full min-h-20 bg-gray-200 border border-gray-300 rounded-md px-4 py-2 peer"
                    ></textarea>
                    <label
                      htmlFor="termsConditions"
                      className="absolute text-sm text-blue-500 bg-white px-1 left-3 -top-2 transition-all peer-placeholder-shown:top-4 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500"
                    >
                      Terms & Conditions
                    </label>
                  </div>
                </div>

                {/* Instructions */}
                <div className="flex flex-wrap gap-8 mb-4">
                  <div className="relative flex-1 min-w-[200px]">
                    <textarea
                      id="instructions"
                      name="instructions"
                      value={formData.instructions}
                      onChange={handleChange}
                      required
                      placeholder=" "
                      className="w-full min-h-20 bg-gray-200 border border-gray-300 rounded-md px-4 py-2 peer"
                    ></textarea>
                    <label
                      htmlFor="instructions"
                      className="absolute text-sm text-blue-500 bg-white px-1 left-3 -top-2 transition-all peer-placeholder-shown:top-4 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500"
                    >
                      Instructions
                    </label>
                  </div>
                </div>

                {/* Start Date and End Date */}
                <div className="flex flex-wrap gap-8 mb-4">
                  <div className="relative flex-1 min-w-[200px]">
                    <input
                      type="date"
                      id="startDate"
                      name="startDate"
                      value={formatDateForInput(formData.startDate)}
                      onChange={handleChange}
                      required
                      placeholder=" "
                      className="w-full h-14 bg-gray-200 border border-gray-300 rounded-md px-4 py-2 peer"
                    />
                    <label
                      htmlFor="startDate"
                      className="absolute text-sm text-blue-500 bg-white px-1 left-3 -top-2 transition-all peer-placeholder-shown:top-4 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500"
                    >
                      Start Date
                    </label>
                  </div>
                  <div className="relative flex-1 min-w-[200px]">
                    <input
                      type="date"
                      id="endDate"
                      name="endDate"
                      value={formatDateForInput(formData.endDate)}
                      onChange={handleChange}
                      required
                      placeholder=" "
                      className="w-full h-14 bg-gray-200 border border-gray-300 rounded-md px-4 py-2 peer"
                    />
                    <label
                      htmlFor="endDate"
                      className="absolute text-sm text-blue-500 bg-white px-1 left-3 -top-2 transition-all peer-placeholder-shown:top-4 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500"
                    >
                      End Date
                    </label>
                  </div>
                </div>

                {/* Voting Duration and Event Type */}
                <div className="flex flex-wrap gap-8 mb-4">
                  <div className="relative flex-1 min-w-[200px]">
                    <input
                      type="number"
                      id="votingDuration"
                      name="votingDuration"
                      value={formData.votingDuration}
                      onChange={handleChange}
                      required
                      placeholder=" "
                      className="w-full h-14 bg-gray-200 border border-gray-300 rounded-md px-4 py-2 peer"
                    />
                    <label
                      htmlFor="votingDuration"
                      className="absolute text-sm text-blue-500 bg-white px-1 left-3 -top-2 transition-all peer-placeholder-shown:top-4 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500"
                    >
                      Voting Duration (In Days)
                    </label>
                  </div>
                  <div className="relative flex-1 min-w-[200px]">
                    <select
                      id="eventType"
                      name="eventType"
                      value={formData.eventType}
                      onChange={handleEventTypeChange}
                      required
                      className="w-full h-14 bg-gray-200 border border-gray-300 rounded-md px-4 py-2 peer"
                    >
                      <option value="">Select Event Type</option>
                      {getEnumValues(EventType).map((value) => (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      ))}
                    </select>
                    <label
                      htmlFor="eventType"
                      className="absolute text-sm lg: text-blue-500 bg-white px-1 left-3 -top-2 transition-all peer-placeholder-shown:top-4 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500"
                    >
                      Event Type
                    </label>
                  </div>
                </div>

                {/* Venue */}
                <div className="relative flex-1 min-w-[200px]">
                  <select
                    id="venue"
                    name="venue"
                    value={formData.venue}
                    onChange={handleVenueChange}
                    required
                    className="w-full h-14 bg-gray-200 border border-gray-300 rounded-md px-4 py-2 peer"
                  >
                    <option value="">Select Venue</option>
                    {venues &&
                      venues.map((venue) => (
                        <option key={venue.id} value={venue.id}>
                          {Object.values(venue)
                            .filter((value) => value)
                            .join(", ")}
                        </option>
                      ))}
                  </select>
                  <label
                    htmlFor="venue"
                    className="absolute text-sm text-blue-500 bg-white px-1 left-3 -top-2 transition-all peer-placeholder-shown:top-4 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500"
                  >
                    Venue
                  </label>
                </div>
              </div>
            )}
          </div>

          {/* Category Details */}
          <div className="w-full p-4 border rounded-md mt-8">
            <div className="w-full flex items-center justify-between">
              <h3 className="text-xl font-semibold text-blue-600 bg-gray-100 px-3 py-2 rounded-md mb-2">
                Category Details
              </h3>
              <button
                type="button"
                onClick={() =>
                  setIsCategoriesDetailsOpen(!isCategoriesDetailsOpen)
                }
                className="flex items-center"
              >
                <img
                  src={isCategoriesDetailsOpen ? collapseIcon : expandIcon}
                  alt={isCategoriesDetailsOpen ? "Collapse" : "Expand"}
                  className="w-6 h-6"
                />
              </button>
            </div>
            {isCategoriesDetailsOpen && (
              <section className="grid grid-cols-1 lg:grid-cols-2 gap-4">
               
                <div className="flex items-center mb-4">
                  <label className="text-sm text-blue-600 absolute px-2 bg-white -mt-3 ml-2">
                    Select Generic Category
                  </label>
                  <Select
                    options={[
                      { value: "all", label: "Select All" },
                      ...categories.map((cat) => ({
                        value: cat.id,
                        label: cat.name,
                      })),
                    ]}
                    isMulti
                    onChange={(selectedOptions) =>
                      handleCategoryChange(selectedOptions, "generic")
                    }
                    value={selectedCategories.generic}
                    placeholder="Select Generic Category" // Placeholder text
                    className="w-full"
                    styles={{
                      control: (base) => ({
                        ...base,
                        backgroundColor: "#E5E7EB", // bg-gray-200
                        border: "1px solid #D1D5DB", // border-gray-300
                        borderRadius: "0.375rem", // rounded-md
                        padding: "0.5rem", // px-4 py-2
                      }),
                      menu: (base) => ({
                        ...base,
                        zIndex: 9999,
                      }),
                    }}
                    menuPortalTarget={document.body}
                    menuPlacement="auto"
                  />
                  {/* <AddCategoryModal /> */}
                </div>
                
               
                <div className="flex items-center mb-4">
                  <label className="text-sm text-blue-600 absolute px-2 bg-white -mt-3 ml-2">
                    Select Age Category
                  </label>
                  <Select
                    options={[
                      { value: "all", label: "Select All" },
                      ...ageCategories.map((cat) => ({
                        value: cat.id,
                        label: cat.name,
                      })),
                    ]}
                    isMulti
                    onChange={(selectedOptions) =>
                      handleCategoryChange(selectedOptions, "age")
                    }
                    value={selectedCategories.age}
                    placeholder="Select Age Category" // Placeholder text
                    className="w-full"
                    styles={{
                      control: (base) => ({
                        ...base,
                        backgroundColor: "#E5E7EB", // bg-gray-200
                        border: "1px solid #D1D5DB", // border-gray-300
                        borderRadius: "0.375rem", // rounded-md
                        padding: "0.5rem", // px-4 py-2
                      }),
                      menu: (base) => ({
                        ...base,
                        zIndex: 9999,
                      }),
                    }}
                    menuPortalTarget={document.body}
                    menuPlacement="auto"
                  />
                </div>

                
                <div className="flex items-center mb-4">
                  <label className="text-sm text-blue-600 absolute px-2 bg-white -mt-3 ml-2">
                    Select Gender Category
                  </label>
                  <Select
                    options={[
                      { value: "all", label: "Select All" },
                      ...genderCategories.map((cat) => ({
                        value: cat.id,
                        label: cat.gender,
                      })),
                    ]}
                    isMulti
                    onChange={(selectedOptions) =>
                      handleCategoryChange(selectedOptions, "gender")
                    }
                    value={selectedCategories.gender}
                    placeholder="Select Gender Category" // Placeholder text
                    className="w-full"
                    styles={{
                      control: (base) => ({
                        ...base,
                        backgroundColor: "#E5E7EB", // bg-gray-200
                        border: "1px solid #D1D5DB", // border-gray-300
                        borderRadius: "0.375rem", // rounded-md
                        padding: "0.5rem", // px-4 py-2
                      }),
                      menu: (base) => ({
                        ...base,
                        zIndex: 9999,
                      }),
                    }}
                    menuPortalTarget={document.body}
                    menuPlacement="auto"
                  />
                </div>

               
                <div className="flex items-center mb-4">
                  <label className="text-sm text-blue-600 absolute px-2 bg-white -mt-3 ml-2">
                    Select Followers Count Category
                  </label>
                  <Select
                    options={[
                      { value: "all", label: "Select All" },
                      ...followerCountCategories.map((cat) => ({
                        value: cat.id,
                        label: `${cat.startCount}-${cat.endCount}`,
                      })),
                    ]}
                    isMulti
                    onChange={(selectedOptions) =>
                      handleCategoryChange(selectedOptions, "followercount")
                    }
                    value={selectedCategories.followercount}
                    placeholder="Select Followers Count Category" // Placeholder text
                    className="w-full"
                    styles={{
                      control: (base) => ({
                        ...base,
                        backgroundColor: "#E5E7EB", // bg-gray-200
                        border: "1px solid #D1D5DB", // border-gray-300
                        borderRadius: "0.375rem", // rounded-md
                        padding: "0.5rem", // px-4 py-2
                      }),
                      menu: (base) => ({
                        ...base,
                        zIndex: 9999,
                      }),
                    }}
                    menuPortalTarget={document.body}
                    menuPlacement="auto"
                  />
                </div>
              </section>
            )}
          </div>
         

          {/* Sponsor Details */}
          <SponsorDetailForm onSponsorDataChange={onSponsorDataChange} />
          {/* Display Saved Sponsors with Additional Data and Delete Option */}
          <div className="mt-6">
            <h2 className="text-xl font-semibold">Saved Sponsor Details</h2>
            <div className="grid gap-4 mt-2">
              {formData.sponsorData.map((sponsor, index) => (
                <div
                  key={index}
                  className="p-4 border rounded-md flex justify-between items-center"
                >
                  <div>
                    {/* Basic Sponsor Info */}
                    <p>
                      <strong>Category:</strong> {sponsor.sponsorCategory}
                    </p>
                    <p>
                      <strong>Coupons:</strong> {sponsor.noOfCoupons}
                    </p>
                    <p>
                      <strong>Gift Hampers:</strong> {sponsor.noOfGiftHampers}
                    </p>
                    <p>
                      <strong>Description:</strong> {sponsor.description}
                    </p>
                    <p>
                      <strong>Price:</strong> {sponsor.price}
                    </p>

                    {/* Additional Data */}
                    {sponsor.additional_data && (
                      <div className="mt-4">
                        <h3 className="text-lg font-semibold">
                          Additional Data
                        </h3>
                        <p>
                          <strong>No of Seats:</strong>{" "}
                          {sponsor.additional_data.no_of_seats || "N/A"}
                        </p>
                        <p>
                          <strong>Proposal URL:</strong>{" "}
                          {sponsor.additional_data.proposal_url ? (
                            <a
                              href={sponsor.additional_data.proposal_url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-500 underline"
                            >
                              View Proposal
                            </a>
                          ) : (
                            "N/A"
                          )}
                        </p>

                        {/* Location Information */}
                        <h3 className="text-md font-semibold mt-2">
                          Location Level
                        </h3>
                        <p>
                          <strong>Country:</strong>{" "}
                          {sponsor.additional_data.country}
                        </p>

                        {sponsor.additional_data.is_state && (
                          <>
                            <p>
                              <strong>State:</strong>{" "}
                              {sponsor.additional_data.state || "N/A"}
                            </p>
                          </>
                        )}

                        {sponsor.additional_data.is_city && (
                          <>
                            <p>
                              <strong>State:</strong>{" "}
                              {sponsor.additional_data.state || "N/A"}
                            </p>
                            <p>
                              <strong>City:</strong>{" "}
                              {sponsor.additional_data.city || "N/A"}
                            </p>
                          </>
                        )}

                        {sponsor.additional_data.is_pincode && (
                          <>
                            <p>
                              <strong>State:</strong>{" "}
                              {sponsor.additional_data.state || "N/A"}
                            </p>
                            <p>
                              <strong>City:</strong>{" "}
                              {sponsor.additional_data.city || "N/A"}
                            </p>
                            <p>
                              <strong>Pincode:</strong>{" "}
                              {sponsor.additional_data.pincode || "N/A"}
                            </p>
                          </>
                        )}
                      </div>
                    )}
                  </div>

                  {/* Delete Button */}
                  <button
                    onClick={() => handleDeleteSponsor(index)}
                    className="text-red-500 hover:text-red-700"
                  >
                    Delete
                  </button>
                </div>
              ))}
            </div>
          </div>

          {/* Template Details */}
          <div className="w-full p-4 border rounded-md mt-8">
            <div className="w-full flex items-center justify-between">
              <h3 className="text-xl font-semibold text-blue-600 bg-gray-100 px-3 py-2 rounded-md mb-2">
                Template Details
              </h3>
              <button
                type="button"
                onClick={() => setIsTemplateOpen(!isTemplateOpen)}
                className="flex items-center"
              >
                <img
                  src={isTemplateOpen ? collapseIcon : expandIcon}
                  alt={isTemplateOpen ? "Collapse" : "Expand"}
                  className="w-6 h-6"
                />
              </button>
            </div>
            {isTemplateOpen && (
              <div className="mt-4 flex flex-wrap justify-between">
                <div className="w-[46%] flex flex-col items-start space-y-2">
                  <label>Template</label>
                  <input
                    id="imageinput"
                    type="file"
                    accept="image/*"
                    className="w-full border rounded-sm px-4 py-2"
                    onChange={handleFileChange}
                  />
                  {imagePreview && (
                    <div className="mt-2">
                      <img
                        src={imagePreview}
                        alt="Uploaded Template Preview"
                        className="w-full max-h-40 object-cover border rounded-sm"
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <button
            type="button"
            onClick={handleCreateSubmit}
            className="mt-12 flex items-center justify-center gap-2 px-4 py-2 bg-black text-white rounded shadow-md hover:bg-gray-800"
          >
            <FaPlusCircle className="mr-2" />
            {isEditMode ? "Update" : "Create"}
          </button>
        </form>
      </div>
      // Render the Confirmation Modal
      <ConfirmationModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={confirmCreateEvent}
        formData={formData}
      />
      {!isEditMode && selectedEventType == "long" && eventId && (
        <IndependentMediaUpload
          eventId={eventId || "defaultEventID"}
          existingMedia={null}
        />
      )}
      {isEditMode &&
        formData &&
        formData.eventType == "long" &&
        formData.eventID && (
          <IndependentMediaUpload
            eventId={formData.eventID || "defaultEventID"}
          />
        )}
      {((!isEditMode && eventCreated && eventId) ||
        (isEditMode && formData && formData.eventID)) && (
        <AdditionalDataForm
          eventId={eventId || formData.eventID}
          onDelete={handleDelete}
        />
      )}
    </>
  );
};

export default EventForm;

// import React, { useContext, useState, useEffect } from "react";
// import { useNavigate, useLocation } from "react-router-dom";
// import axios from "axios";
// import Cookies from "js-cookie";
// import { FaArrowLeft } from "react-icons/fa";
// import collapseIcon from "./assets/reduce.png";
// import expandIcon from "./assets/expand-arrows.png";
// import { DataContext } from "../context/DataContext";
// import Select from "react-select";
// import { uploadFile } from "../../firebase/fileUpload";
// import IndependentMediaUpload from "./IndependentMediaUpload";
// import {
//   FaTrashAlt,
//   FaCheckCircle,
//   FaTimesCircle,
//   FaPlusCircle,
// } from "react-icons/fa";
// import LoadingScreen from "../utilities/LoadingScreen";
// import AdditionalDataForm from "./AdditionalDataForm";

// const EventType = Object.freeze({
//   Short: "short",
//   Long: "long",
// });

// const formatDateForInput = (date) => {
//   if (!date) return "";
//   const d = new Date(date);
//   const month = ("0" + (d.getMonth() + 1)).slice(-2);
//   const day = ("0" + d.getDate()).slice(-2);
//   const year = d.getFullYear();
//   return `${year}-${month}-${day}`;
// };

// const EventForm = () => {
//   const {
//     categories,
//     ageCategories,
//     genderCategories,
//     followerCountCategories,
//     venues,
//     loading,
//   } = useContext(DataContext);

//   if (loading) <LoadingScreen />;

//   const apiDomain = process.env.REACT_APP_API_DOMAIN;
//   const location = useLocation();
//   const navigate = useNavigate();

//   const [isEditMode, setIsEditMode] = useState(false);
//   const [formData, setFormData] = useState({
//     eventName: "",
//     categories: [],
//     description: "",
//     startDate: "",
//     endDate: "",
//     venue: 0,
//     votingDuration: 0,
//     eventType: "",
//     termsConditions: "",
//     instructions: "",
//     sponsorData: [],
//     templateData: null,
//   });
//   const [modalOpen, setModalOpen] = useState(false); // For modal control
//   const [selectedCategories, setSelectedCategories] = useState({
//     generic: [],
//     age: [],
//     gender: [],
//     followercount: [],
//   });
//   const [templateData, setTemplateData] = useState(null);
//   const [imagePreview, setImagePreview] = useState(null);
//   const [selectedEventType, setSelectedEventType] = useState("");
//   const [isEventDetailsOpen, setIsEventDetailsOpen] = useState(true);
//   const [isCategoriesDetailsOpen, setIsCategoriesDetailsOpen] = useState(true);
//   const [isSponsorOpen, setIsSponsorOpen] = useState(true);
//   const [isTemplateOpen, setIsTemplateOpen] = useState(true);
//   const [eventId, setEventId] = useState(null);
//   const [sponsorData, setSponsorData] = useState({
//     sponsorCategory: "",
//     noOfCoupons: "",
//     noOfGiftHampers: "",
//     description: "",
//     price: "",
//   });
//   const [savedData, setSavedData] = useState([]);
//   const [eventCreated, setEventCreated] = useState(false);

//   useEffect(() => {
//     if (location.state && location.state.event) {
//       setIsEditMode(true);
//       const event = location.state.event;
//       setFormData(event);
//       setTemplateData(event.templateData);
//       setImagePreview(event.templateData);
//       setSavedData(event.sponsorData || []);

//       // Populate selected categories based on event data in edit mode
//       if (event.categories) {
//         setSelectedCategories({
//           generic: categories
//             .filter((cat) => event.categories.includes(cat.id))
//             .map((cat) => ({ value: cat.id, label: cat.name })),
//           age: ageCategories
//             .filter((cat) => event.ageCategories.includes(cat.id))
//             .map((cat) => ({ value: cat.id, label: cat.name })),
//           gender: genderCategories
//             .filter((cat) => event.genderCategories.includes(cat.id))
//             .map((cat) => ({ value: cat.id, label: cat.gender })),
//           followercount: followerCountCategories
//             .filter((cat) => event.followerCountCategories.includes(cat.id))
//             .map((cat) => ({
//               value: cat.id,
//               label: `${cat.startCount}-${cat.endCount}`,
//             })),
//         });
//       }
//     }
//   }, [
//     location.state,
//     categories,
//     ageCategories,
//     genderCategories,
//     followerCountCategories,
//   ]);

//   const getEnumValues = (enumObject) => Object.values(enumObject);

//   const handleEventTypeChange = (event) => {
//     const selectedValue = event.target.value;
//     setFormData((prevData) => ({
//       ...prevData,
//       eventType: selectedValue,
//     }));
//     setSelectedEventType(selectedValue);
//   };

//   const handleCategoryChange = (selectedOptions, categoryType) => {
//     if (
//       selectedOptions &&
//       selectedOptions.some((option) => option.value === "all")
//     ) {
//       let allOptions = [];
//       switch (categoryType) {
//         case "generic":
//           allOptions = categories.map((cat) => ({
//             value: cat.id,
//             label: cat.name,
//           }));
//           break;
//         case "age":
//           allOptions = ageCategories.map((cat) => ({
//             value: cat.id,
//             label: cat.name,
//           }));
//           break;
//         case "gender":
//           allOptions = genderCategories.map((cat) => ({
//             value: cat.id,
//             label: cat.gender,
//           }));
//           break;
//         case "followercount":
//           allOptions = followerCountCategories.map((cat) => ({
//             value: cat.id,
//             label: `${cat.startCount}-${cat.endCount}`,
//           }));
//           break;
//         default:
//           break;
//       }
//       setSelectedCategories((prevState) => ({
//         ...prevState,
//         [categoryType]: allOptions,
//       }));
//     } else {
//       setSelectedCategories((prevState) => ({
//         ...prevState,
//         [categoryType]: selectedOptions,
//       }));
//     }
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     const newValue =
//       name === "venue" || name === "votingDuration"
//         ? parseInt(value, 10) || 0
//         : value;
//     setFormData((prevFormData) => ({ ...prevFormData, [name]: newValue }));
//   };

//   const handleCreateSubmit = async () => {
//     setModalOpen(true); // Open modal for confirmation before creating the event
//   };

//   const confirmCreateEvent = async () => {
//     const accessToken = Cookies.get("fbauthToken");
//     const url = await uploadFile(templateData);
//     const jsonData = {
//       ...formData,
//       templateData: url,
//       categories: selectedCategories.generic.map((option) => option.value),
//       ageCategories: selectedCategories.age.map((option) => option.value),
//       genderCategories: selectedCategories.gender.map((option) => option.value),
//       followerCountCategories: selectedCategories.followercount.map(
//         (option) => option.value
//       ),
//     };
//     try {
//       const response = await axios.post(
//         `${apiDomain}/api/v2/createevent?access_token=${accessToken}`,
//         jsonData,
//         { headers: { "Content-Type": "application/json" } }
//       );
//       if (response.status === 200) {
//         alert("Event created successfully!");
//         setEventCreated(true);
//         setModalOpen(false); // Close the modal after success
//         if (selectedEventType === "long") {
//           alert("Set long Event Media Data Now!");
//         }
//         setEventId(response.data.event_id);
//       } else {
//         alert(`Error: ${response}`);
//       }
//     } catch (error) {
//       console.error("Error creating the event!", error);
//       alert("There was an error creating the event. Please try again.");
//     }
//   };

//   const handleVenueChange = (event) => {
//     const selectedId = event.target.value;
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       venue: selectedId,
//     }));
//   };

//   const handleFileChange = async (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       setTemplateData(file);
//       setImagePreview(URL.createObjectURL(file));
//     }
//   };

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setSponsorData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleSaveData = () => {
//     setSavedData((prevData) => [...prevData, sponsorData]);
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       sponsorData: [...(prevFormData.sponsorData || []), sponsorData],
//     }));
//     setSponsorData({
//       sponsorCategory: "",
//       noOfCoupons: "",
//       noOfGiftHampers: "",
//       description: "",
//       price: "",
//     });
//   };

//   const handleDelete = (index) => {
//     const newData = savedData.filter((_, i) => i !== index);
//     setSavedData(newData);
//     setFormData((prevFormData) => ({
//       ...prevFormData,
//       sponsorData: newData,
//     }));
//   };

//   useEffect(() => {
//     return () => {
//       if (imagePreview) {
//         URL.revokeObjectURL(imagePreview);
//       }
//     };
//   }, [imagePreview]);

//   // Inline Modal Component
//   const ConfirmationModal = ({
//     open,
//     onClose,
//     onConfirm,
//     formData,
//     // venues = [],
//   }) => {
//     if (!open) return null;

//     // // Match the logic from EventCard to retrieve and format the venue details
//     // const selectedVenue = venues.find((venue) => venue.id == formData.venue);
//     // const venueDetails = selectedVenue
//     //   ? Object.entries(selectedVenue)
//     //       .filter(([key]) => key !== "id") // Filter out the 'id' key
//     //       .map(([, value]) => value) // Extract the values
//     //       .join(", ") // Join values with a comma
//     //   : "N/A";

//     return (
//       <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
//         <div className="bg-white rounded-lg shadow-lg w-5/6 max-w-2xl max-h-[80vh] p-6 overflow-y-auto">
//           <h3 className="text-2xl font-semibold mb-4 text-blue-600">
//             Confirm Event Details
//           </h3>
//           <div className="space-y-2 text-gray-800">
//             <p>
//               <strong>Event Name:</strong> {formData.eventName}
//             </p>
//             <p>
//               <strong>Description:</strong> {formData.description}
//             </p>
//             <p>
//               <strong>Start Date:</strong>{" "}
//               {formatDateForInput(formData.startDate)}
//             </p>
//             <p>
//               <strong>End Date:</strong> {formatDateForInput(formData.endDate)}
//             </p>
//             <p>
//               <strong>Venue:</strong>
//             </p>
//             <p>
//               <strong>Event Type:</strong> {formData.eventType}
//             </p>
//             <p>
//               <strong>Terms & Conditions:</strong> {formData.termsConditions}
//             </p>
//             <p>
//               <strong>Instructions:</strong> {formData.instructions}
//             </p>

//             {/* Category Details */}
//             <div>
//               <strong>Category Details:</strong>
//               <ul className="list-disc ml-6">
//                 {Object.entries(selectedCategories).map(([key, value]) => (
//                   <li key={key}>
//                     <strong>{key}:</strong>{" "}
//                     {value.map((category) => category.label).join(", ") ||
//                       "N/A"}
//                   </li>
//                 ))}
//               </ul>
//             </div>

//             {/* Sponsor Details */}
//             <div>
//               <strong>Sponsor Details:</strong>
//               <ul className="list-disc ml-6">
//                 {savedData.length > 0 ? (
//                   savedData.map((sponsor, index) => (
//                     <li key={index}>
//                       <strong>Category:</strong> {sponsor.sponsorCategory},{" "}
//                       <strong>Coupons:</strong> {sponsor.noOfCoupons},{" "}
//                       <strong>Gift Hampers:</strong> {sponsor.noOfGiftHampers},{" "}
//                       <strong>Description:</strong> {sponsor.description},{" "}
//                       <strong>Price:</strong> {sponsor.price}
//                     </li>
//                   ))
//                 ) : (
//                   <p>No Sponsor Details Available</p>
//                 )}
//               </ul>
//             </div>

//             {/* Template Data */}
//             <div>
//               <strong>Template:</strong>
//               {imagePreview ? (
//                 <img
//                   src={imagePreview}
//                   alt="Template Preview"
//                   className="mt-2 w-60 h-60 rounded"
//                 />
//               ) : (
//                 <p>No Template Available</p>
//               )}
//             </div>
//           </div>
//           <div className="flex justify-end mt-6 space-x-4">
//             <button
//               onClick={onClose}
//               className="flex items-center bg-gray-300 text-gray-700 px-4 py-2 rounded-md shadow hover:bg-gray-400 transition"
//             >
//               <FaTimesCircle className="mr-2" /> Cancel
//             </button>
//             <button
//               onClick={onConfirm}
//               className="flex items-center bg-blue-500 text-white px-4 py-2 rounded-md shadow hover:bg-blue-600 transition"
//             >
//               <FaCheckCircle className="mr-2" /> Confirm
//             </button>
//           </div>
//         </div>
//       </div>
//     );
//   };

//   return (
//     <>

//       <div className="flex flex-col items-start mt-8 lg:ml-[20vw] lg:mr-8">
//          {/* Back Button */}
//       <button
//         onClick={() => navigate(-1)}
//         className="flex items-center text-blue-500 hover:text-blue-700 transition mb-4"
//       >
//         <FaArrowLeft className="mr-2" /> Back
//       </button>
//         <form
//           className="w-full bg-white p-6 rounded-lg shadow-md"
//           onSubmit={(e) => e.preventDefault()} // Prevent default submit action
//         >
//           {/* Event Details */}
//           <div className="w-full p-4 border rounded-md mb-4">
//             <div className="w-full flex items-center justify-between">
//               <h3 className="text-xl font-semibold text-blue-600 bg-gray-100 px-3 py-2 rounded-md mb-2">
//                 Event Details
//               </h3>
//               <button
//                 type="button"
//                 onClick={() => setIsEventDetailsOpen(!isEventDetailsOpen)}
//                 className="flex items-center"
//               >
//                 <img
//                   src={isEventDetailsOpen ? collapseIcon : expandIcon}
//                   alt={isEventDetailsOpen ? "Collapse" : "Expand"}
//                   className="w-6 h-6"
//                 />
//               </button>
//             </div>
//             {isEventDetailsOpen && (
//               <div className="mt-4">
//                 {/* Event Name */}
//                 <div className="flex flex-wrap gap-8 mb-4">
//                   <div className="relative flex-1 min-w-[200px]">
//                     <input
//                       type="text"
//                       id="eventName"
//                       name="eventName"
//                       value={formData.eventName}
//                       onChange={handleChange}
//                       required
//                       placeholder=" "
//                       className="w-full h-14 bg-gray-200 border border-gray-300 rounded-md px-4 py-2 peer"
//                     />
//                     <label
//                       htmlFor="eventName"
//                       className="absolute text-sm text-blue-500 bg-white px-1 left-3 -top-2 transition-all peer-placeholder-shown:top-4 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500"
//                     >
//                       Event Name
//                     </label>
//                   </div>
//                 </div>

//                 {/* Description */}
//                 <div className="flex flex-wrap gap-8 mb-4">
//                   <div className="relative flex-1 min-w-[200px]">
//                     <textarea
//                       id="description"
//                       name="description"
//                       value={formData.description}
//                       onChange={handleChange}
//                       required
//                       placeholder=" "
//                       className="w-full min-h-20 bg-gray-200 border border-gray-300 rounded-md px-4 py-2 peer "
//                     ></textarea>
//                     <label
//                       htmlFor="description"
//                       className="absolute text-sm text-blue-500 bg-white px-1 left-3 -top-2 transition-all peer-placeholder-shown:top-4 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500"
//                     >
//                       Description
//                     </label>
//                   </div>
//                 </div>

//                 {/* Terms & Conditions */}
//                 <div className="flex flex-wrap gap-8 mb-4">
//                   <div className="relative flex-1 min-w-[200px]">
//                     <textarea
//                       id="termsConditions"
//                       name="termsConditions"
//                       value={formData.termsConditions}
//                       onChange={handleChange}
//                       required
//                       placeholder=" "
//                       className="w-full min-h-20 bg-gray-200 border border-gray-300 rounded-md px-4 py-2 peer"
//                     ></textarea>
//                     <label
//                       htmlFor="termsConditions"
//                       className="absolute text-sm text-blue-500 bg-white px-1 left-3 -top-2 transition-all peer-placeholder-shown:top-4 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500"
//                     >
//                       Terms & Conditions
//                     </label>
//                   </div>
//                 </div>

//                 {/* Instructions */}
//                 <div className="flex flex-wrap gap-8 mb-4">
//                   <div className="relative flex-1 min-w-[200px]">
//                     <textarea
//                       id="instructions"
//                       name="instructions"
//                       value={formData.instructions}
//                       onChange={handleChange}
//                       required
//                       placeholder=" "
//                       className="w-full min-h-20 bg-gray-200 border border-gray-300 rounded-md px-4 py-2 peer"
//                     ></textarea>
//                     <label
//                       htmlFor="instructions"
//                       className="absolute text-sm text-blue-500 bg-white px-1 left-3 -top-2 transition-all peer-placeholder-shown:top-4 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500"
//                     >
//                       Instructions
//                     </label>
//                   </div>
//                 </div>

//                 {/* Start Date and End Date */}
//                 <div className="flex flex-wrap gap-8 mb-4">
//                   <div className="relative flex-1 min-w-[200px]">
//                     <input
//                       type="date"
//                       id="startDate"
//                       name="startDate"
//                       value={formatDateForInput(formData.startDate)}
//                       onChange={handleChange}
//                       required
//                       placeholder=" "
//                       className="w-full h-14 bg-gray-200 border border-gray-300 rounded-md px-4 py-2 peer"
//                     />
//                     <label
//                       htmlFor="startDate"
//                       className="absolute text-sm text-blue-500 bg-white px-1 left-3 -top-2 transition-all peer-placeholder-shown:top-4 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500"
//                     >
//                       Start Date
//                     </label>
//                   </div>
//                   <div className="relative flex-1 min-w-[200px]">
//                     <input
//                       type="date"
//                       id="endDate"
//                       name="endDate"
//                       value={formatDateForInput(formData.endDate)}
//                       onChange={handleChange}
//                       required
//                       placeholder=" "
//                       className="w-full h-14 bg-gray-200 border border-gray-300 rounded-md px-4 py-2 peer"
//                     />
//                     <label
//                       htmlFor="endDate"
//                       className="absolute text-sm text-blue-500 bg-white px-1 left-3 -top-2 transition-all peer-placeholder-shown:top-4 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500"
//                     >
//                       End Date
//                     </label>
//                   </div>
//                 </div>

//                 {/* Voting Duration and Event Type */}
//                 <div className="flex flex-wrap gap-8 mb-4">
//                   <div className="relative flex-1 min-w-[200px]">
//                     <input
//                       type="number"
//                       id="votingDuration"
//                       name="votingDuration"
//                       value={formData.votingDuration}
//                       onChange={handleChange}
//                       required
//                       placeholder=" "
//                       className="w-full h-14 bg-gray-200 border border-gray-300 rounded-md px-4 py-2 peer"
//                     />
//                     <label
//                       htmlFor="votingDuration"
//                       className="absolute text-sm text-blue-500 bg-white px-1 left-3 -top-2 transition-all peer-placeholder-shown:top-4 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500"
//                     >
//                       Voting Duration (In Days)
//                     </label>
//                   </div>
//                   <div className="relative flex-1 min-w-[200px]">
//                     <select
//                       id="eventType"
//                       name="eventType"
//                       value={formData.eventType}
//                       onChange={handleEventTypeChange}
//                       required
//                       className="w-full h-14 bg-gray-200 border border-gray-300 rounded-md px-4 py-2 peer"
//                     >
//                       <option value="">Select Event Type</option>
//                       {getEnumValues(EventType).map((value) => (
//                         <option key={value} value={value}>
//                           {value}
//                         </option>
//                       ))}
//                     </select>
//                     <label
//                       htmlFor="eventType"
//                       className="absolute text-sm lg: text-blue-500 bg-white px-1 left-3 -top-2 transition-all peer-placeholder-shown:top-4 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500"
//                     >
//                       Event Type
//                     </label>
//                   </div>
//                 </div>

//                 {/* Venue */}
//                 <div className="relative flex-1 min-w-[200px]">
//                   <select
//                     id="venue"
//                     name="venue"
//                     value={formData.venue}
//                     onChange={handleVenueChange}
//                     required
//                     className="w-full h-14 bg-gray-200 border border-gray-300 rounded-md px-4 py-2 peer"
//                   >
//                     <option value="">Select Venue</option>
//                     {venues &&
//                       venues.map((venue) => (
//                         <option key={venue.id} value={venue.id}>
//                           {Object.values(venue)
//                             .filter((value) => value)
//                             .join(", ")}
//                         </option>
//                       ))}
//                   </select>
//                   <label
//                     htmlFor="venue"
//                     className="absolute text-sm text-blue-500 bg-white px-1 left-3 -top-2 transition-all peer-placeholder-shown:top-4 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500"
//                   >
//                     Venue
//                   </label>
//                 </div>
//               </div>
//             )}
//           </div>

//           {/* Category Details */}
//           <div className="w-full p-4 border rounded-md mt-8">
//             <div className="w-full flex items-center justify-between">
//               <h3 className="text-xl font-semibold text-blue-600 bg-gray-100 px-3 py-2 rounded-md mb-2">
//                 Category Details
//               </h3>
//               <button
//                 type="button"
//                 onClick={() =>
//                   setIsCategoriesDetailsOpen(!isCategoriesDetailsOpen)
//                 }
//                 className="flex items-center"
//               >
//                 <img
//                   src={isCategoriesDetailsOpen ? collapseIcon : expandIcon}
//                   alt={isCategoriesDetailsOpen ? "Collapse" : "Expand"}
//                   className="w-6 h-6"
//                 />
//               </button>
//             </div>
//             {isCategoriesDetailsOpen && (
//               <section className="grid grid-cols-1 lg:grid-cols-2 gap-4">
//                 {/* Generic Categories */}
//                 <div className="flex items-center mb-4">
//                   <label className="text-sm text-blue-600 absolute px-2 bg-white -mt-3 ml-2">
//                     Select Generic Category
//                   </label>
//                   <Select
//                     options={[
//                       { value: "all", label: "Select All" },
//                       ...categories.map((cat) => ({
//                         value: cat.id,
//                         label: cat.name,
//                       })),
//                     ]}
//                     isMulti
//                     onChange={(selectedOptions) =>
//                       handleCategoryChange(selectedOptions, "generic")
//                     }
//                     value={selectedCategories.generic}
//                     placeholder="Select Generic Category" // Placeholder text
//                     className="w-full"
//                     styles={{
//                       control: (base) => ({
//                         ...base,
//                         backgroundColor: "#E5E7EB", // bg-gray-200
//                         border: "1px solid #D1D5DB", // border-gray-300
//                         borderRadius: "0.375rem", // rounded-md
//                         padding: "0.5rem", // px-4 py-2
//                       }),
//                       menu: (base) => ({
//                         ...base,
//                         zIndex: 9999,
//                       }),
//                     }}
//                     menuPortalTarget={document.body}
//                     menuPlacement="auto"
//                   />
//                 </div>

//                 {/* Age Categories */}
//                 <div className="flex items-center mb-4">
//                   <label className="text-sm text-blue-600 absolute px-2 bg-white -mt-3 ml-2">
//                     Select Age Category
//                   </label>
//                   <Select
//                     options={[
//                       { value: "all", label: "Select All" },
//                       ...ageCategories.map((cat) => ({
//                         value: cat.id,
//                         label: cat.name,
//                       })),
//                     ]}
//                     isMulti
//                     onChange={(selectedOptions) =>
//                       handleCategoryChange(selectedOptions, "age")
//                     }
//                     value={selectedCategories.age}
//                     placeholder="Select Age Category" // Placeholder text
//                     className="w-full"
//                     styles={{
//                       control: (base) => ({
//                         ...base,
//                         backgroundColor: "#E5E7EB", // bg-gray-200
//                         border: "1px solid #D1D5DB", // border-gray-300
//                         borderRadius: "0.375rem", // rounded-md
//                         padding: "0.5rem", // px-4 py-2
//                       }),
//                       menu: (base) => ({
//                         ...base,
//                         zIndex: 9999,
//                       }),
//                     }}
//                     menuPortalTarget={document.body}
//                     menuPlacement="auto"
//                   />
//                 </div>

//                 {/* Gender Categories */}
//                 <div className="flex items-center mb-4">
//                   <label className="text-sm text-blue-600 absolute px-2 bg-white -mt-3 ml-2">
//                     Select Gender Category
//                   </label>
//                   <Select
//                     options={[
//                       { value: "all", label: "Select All" },
//                       ...genderCategories.map((cat) => ({
//                         value: cat.id,
//                         label: cat.gender,
//                       })),
//                     ]}
//                     isMulti
//                     onChange={(selectedOptions) =>
//                       handleCategoryChange(selectedOptions, "gender")
//                     }
//                     value={selectedCategories.gender}
//                     placeholder="Select Gender Category" // Placeholder text
//                     className="w-full"
//                     styles={{
//                       control: (base) => ({
//                         ...base,
//                         backgroundColor: "#E5E7EB", // bg-gray-200
//                         border: "1px solid #D1D5DB", // border-gray-300
//                         borderRadius: "0.375rem", // rounded-md
//                         padding: "0.5rem", // px-4 py-2
//                       }),
//                       menu: (base) => ({
//                         ...base,
//                         zIndex: 9999,
//                       }),
//                     }}
//                     menuPortalTarget={document.body}
//                     menuPlacement="auto"
//                   />
//                 </div>

//                 {/* Followers Count Categories */}
//                 <div className="flex items-center mb-4">
//                   <label className="text-sm text-blue-600 absolute px-2 bg-white -mt-3 ml-2">
//                     Select Followers Count Category
//                   </label>
//                   <Select
//                     options={[
//                       { value: "all", label: "Select All" },
//                       ...followerCountCategories.map((cat) => ({
//                         value: cat.id,
//                         label: `${cat.startCount}-${cat.endCount}`,
//                       })),
//                     ]}
//                     isMulti
//                     onChange={(selectedOptions) =>
//                       handleCategoryChange(selectedOptions, "followercount")
//                     }
//                     value={selectedCategories.followercount}
//                     placeholder="Select Followers Count Category" // Placeholder text
//                     className="w-full"
//                     styles={{
//                       control: (base) => ({
//                         ...base,
//                         backgroundColor: "#E5E7EB", // bg-gray-200
//                         border: "1px solid #D1D5DB", // border-gray-300
//                         borderRadius: "0.375rem", // rounded-md
//                         padding: "0.5rem", // px-4 py-2
//                       }),
//                       menu: (base) => ({
//                         ...base,
//                         zIndex: 9999,
//                       }),
//                     }}
//                     menuPortalTarget={document.body}
//                     menuPlacement="auto"
//                   />
//                 </div>
//               </section>
//             )}
//           </div>

//           {/* Sponsor Details */}
//           <div className="w-full p-4 border rounded-md mt-8">
//             <div className="w-full flex items-center justify-between">
//               <h2 className="w-fit text-xl font-semibold text-blue-600 bg-gray-100 px-3 py-2 rounded-md mb-2">
//                 Sponsor Details
//               </h2>
//               <button
//                 type="button"
//                 onClick={() => setIsSponsorOpen(!isSponsorOpen)}
//                 className="flex items-center"
//               >
//                 <img
//                   src={isSponsorOpen ? collapseIcon : expandIcon}
//                   alt={isSponsorOpen ? "Collapse" : "Expand"}
//                   className="w-6 h-6"
//                 />
//               </button>
//             </div>
//             {isSponsorOpen && (
//               <div className="mt-4">
//                 <section className="grid grid-cols-1 lg:grid-cols-2 gap-4">
//                   {/* Sponsor Category Name Input */}
//                   <div className="relative">
//                     <input
//                       type="text"
//                       name="sponsorCategory"
//                       value={sponsorData.sponsorCategory}
//                       onChange={handleInputChange}
//                       className="w-full border rounded-md px-4 py-2 focus:ring-2 focus:ring-blue-500 peer"
//                       placeholder=" " // This empty placeholder is necessary for the floating effect
//                     />
//                     <label className="absolute left-3 -top-2 bg-white px-1 text-sm text-gray-600 peer-placeholder-shown:top-2 peer-placeholder-shown:text-gray-400 peer-placeholder-shown:text-base peer-focus:-top-2 peer-focus:text-sm peer-focus:text-blue-500 transition-all">
//                       Sponsor Category
//                     </label>
//                   </div>

//                   {/* No Of Coupons Input */}
//                   <div className="relative">
//                     <input
//                       type="number"
//                       name="noOfCoupons"
//                       value={sponsorData.noOfCoupons}
//                       onChange={handleInputChange}
//                       className="w-full border rounded-md px-4 py-2 focus:ring-2 focus:ring-blue-500 peer"
//                       placeholder=" "
//                     />
//                     <label className="absolute left-3 -top-2 bg-white px-1 text-sm text-gray-600 peer-placeholder-shown:top-2 peer-placeholder-shown:text-gray-400 peer-placeholder-shown:text-base peer-focus:-top-2 peer-focus:text-sm peer-focus:text-blue-500 transition-all">
//                       No Of Coupons
//                     </label>
//                   </div>

//                   {/* No Of Gift Hampers Input */}
//                   <div className=" relative">
//                     <input
//                       type="number"
//                       name="noOfGiftHampers"
//                       value={sponsorData.noOfGiftHampers}
//                       onChange={handleInputChange}
//                       className="w-full border rounded-md px-4 py-2 focus:ring-2 focus:ring-blue-500 peer"
//                       placeholder=" "
//                     />
//                     <label className="absolute left-3 -top-2 bg-white px-1 text-sm text-gray-600 peer-placeholder-shown:top-2 peer-placeholder-shown:text-gray-400 peer-placeholder-shown:text-base peer-focus:-top-2 peer-focus:text-sm peer-focus:text-blue-500 transition-all">
//                       No Of Gift Hampers
//                     </label>
//                   </div>

//                   {/* Description Input */}
//                   <div className="relative ">
//                     <textarea
//                       name="description"
//                       value={sponsorData.description}
//                       onChange={handleInputChange}
//                       className="w-full border rounded-md px-4 py-2 focus:ring-2 focus:ring-blue-500 peer"
//                       placeholder=" "
//                     />
//                     <label className="absolute left-3 -top-2 bg-white px-1 text-sm text-gray-600 peer-placeholder-shown:top-2 peer-placeholder-shown:text-gray-400 peer-placeholder-shown:text-base peer-focus:-top-2 peer-focus:text-sm peer-focus:text-blue-500 transition-all">
//                       Description
//                     </label>
//                   </div>

//                   {/* Price Input */}
//                   <div className="relative ">
//                     <input
//                       type="text"
//                       name="price"
//                       value={sponsorData.price}
//                       onChange={handleInputChange}
//                       className="w-full border rounded-md px-4 py-2 focus:ring-2 focus:ring-blue-500 peer"
//                       placeholder=" "
//                     />
//                     <label className="absolute left-3 -top-2 bg-white px-1 text-sm text-gray-600 peer-placeholder-shown:top-2 peer-placeholder-shown:text-gray-400 peer-placeholder-shown:text-base peer-focus:-top-2 peer-focus:text-sm peer-focus:text-blue-500 transition-all">
//                       Price
//                     </label>
//                   </div>
//                 </section>

//                 {/* Save Data Button */}
//                 <div className="w-full flex justify-end mt-6">
//                   <button
//                     type="button"
//                     onClick={handleSaveData}
//                     className="bg-blue-500 text-white px-4 py-2 rounded-md shadow hover:bg-blue-600 transition"
//                   >
//                     Save Data
//                   </button>
//                 </div>

//                 {/* Saved Data (Sponsor Cards) */}
//                 <div className="w-full mt-6 grid gap-4 lg:grid-cols-3 sm:grid-cols-1">
//                   {savedData.map((item, index) => (
//                     <div
//                       key={index}
//                       className="p-4 border border-gray-300 rounded-md shadow-lg bg-white relative"
//                     >
//                       <div className="absolute top-2 right-2">
//                         <FaTrashAlt
//                           onClick={() => handleDelete(index)}
//                           className="text-red-500 cursor-pointer hover:text-red-700"
//                           size={16}
//                         />
//                       </div>
//                       <h3 className="text-lg font-bold text-gray-700 mb-2">
//                         {item.sponsorCategory}
//                       </h3>
//                       <p className="text-sm text-gray-600">
//                         <strong>Coupons: </strong>
//                         {item.noOfCoupons}
//                       </p>
//                       <p className="text-sm text-gray-600">
//                         <strong>Gift Hampers: </strong>
//                         {item.noOfGiftHampers}
//                       </p>
//                       <p className="text-sm text-gray-600">
//                         <strong>Description: </strong>
//                         {item.description}
//                       </p>
//                       <p className="text-sm text-gray-600">
//                         <strong>Price: </strong>
//                         {item.price}
//                       </p>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             )}
//           </div>

//           {/* Template Details */}

//           <div className="w-full p-4 border rounded-md mt-8">
//             <div className="w-full flex items-center justify-between">
//             <h3 className="text-xl font-semibold text-blue-600 bg-gray-100 px-3 py-2 rounded-md mb-2">
//                 Template Details
//               </h3>
//               <button
//                 type="button"
//                 onClick={() => setIsTemplateOpen(!isTemplateOpen)}
//                 className="flex items-center"
//               >
//                 <img
//                   src={isTemplateOpen ? collapseIcon : expandIcon}
//                   alt={isTemplateOpen ? "Collapse" : "Expand"}
//                   className="w-6 h-6"
//                 />
//               </button>
//             </div>

//             {isTemplateOpen && (
//               <div className="mt-4 flex flex-wrap justify-between">
//                 <div className="w-[46%] flex flex-col items-start space-y-2">
//                   <label>Template</label>
//                   <input
//                     id="imageinput"
//                     type="file"
//                     accept="image/*"
//                     className="w-full border rounded-sm px-4 py-2"
//                     onChange={handleFileChange}
//                   />
//                   {imagePreview && (
//                     <div className="mt-2">
//                       <img
//                         src={imagePreview}
//                         alt="Uploaded Template Preview"
//                         className="w-full max-h-40 object-cover border rounded-sm"
//                       />
//                     </div>
//                   )}
//                 </div>
//               </div>
//             )}
//           </div>

//           <button
//             type="button"
//             onClick={handleCreateSubmit}
//             className="mt-12 flex items-center justify-center gap-2 px-4 py-2 bg-black text-white rounded shadow-md hover:bg-gray-800"
//           >
//             <FaPlusCircle className="mr-2" />
//             {isEditMode ? "Update" : "Create"}
//           </button>
//         </form>
//       </div>
//       // Render the Confirmation Modal
//       <ConfirmationModal
//         open={modalOpen}
//         onClose={() => setModalOpen(false)}
//         onConfirm={confirmCreateEvent}
//         formData={formData}
//       />
//       {!isEditMode && selectedEventType == "long" && eventId && (
//         <IndependentMediaUpload
//           eventId={eventId || "defaultEventID"}
//           existingMedia={null}
//         />
//       )}
//       {isEditMode &&
//         formData &&
//         formData.eventType == "long" &&
//         formData.eventID && (
//           <IndependentMediaUpload
//             eventId={formData.eventID || "defaultEventID"}
//           />
//         )}
//       {((!isEditMode && eventCreated && eventId) ||
//         (isEditMode && formData && formData.eventID)) && (
//         <AdditionalDataForm
//           eventId={eventId || formData.eventID}
//           onDelete={handleDelete}
//         />
//       )}
//     </>
//   );
// };

// export default EventForm;
