import React from "react";
import TotalDonationStat from "./totalDonationStat/TotalDonationStat";
import DonationTable from "./donationTable/DonationTable";

const Donation = () => {
  return (
    <div className="lg:ml-[21vw] lg:mr-[2vw] mt-12">
      <TotalDonationStat />
      <DonationTable />
    </div>
  );
};

export default Donation;
