import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandHoldingHeart,
  faUserFriends,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";

const data = [
  {
    icon: faHandHoldingHeart,
    label: "Total Donations Received",
    number: "85",
    percentage: 60,
  },
  { icon: faUserFriends, label: "Number Of Donors", number: "85", percentage: 60 },
  { icon: faCalendarAlt, label: "Number Of Events", number: "120", percentage: 90 },
];

const TotalDonationStat = () => {
  return (
    <div className="flex flex-wrap justify-between">
      {data.map((item, index) => (
        <div
          key={index}
          className="flex items-center bg-white shadow-md rounded-lg p-4 w-full sm:w-[48%] lg:w-[30%] mb-6"
        >
          <div className="flex-shrink-0 bg-blue-100 p-4 rounded-full">
            <FontAwesomeIcon
              icon={item.icon}
              className="text-blue-500 text-3xl"
            />
          </div>
          <div className="ml-4">
            <div className="text-gray-500 text-sm font-semibold">{item.label}</div>
            <div className="text-gray-900 text-xl font-bold">{item.number}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TotalDonationStat;
