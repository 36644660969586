// src/utils/PincodeLoader.js

export class Pincode {
  constructor({ officeName, pincode, taluk, districtName, stateName }) {
    this.officeName = officeName;
    this.pincode = pincode;
    this.taluk = taluk;
    this.districtName = districtName;
    this.stateName = stateName;
  }

  static fromJson(json) {
    return new Pincode({
      officeName: json.officeName,
      pincode: json.pincode,
      taluk: json.taluk,
      districtName: json.districtName,
      stateName: json.stateName,
    });
  }
}

// Function to parse the pincode JSON
const parsePincodes = (responseBody) => {
  const parsed = JSON.parse(responseBody);
  return parsed.map((json) => Pincode.fromJson(json));
};

// Function to load and return parsed pincode data
export const loadPincodes = async () => {
  try {
    const response = await fetch("/pincodes.json"); // Adjust the path to your JSON file
    const data = await response.text();
    return parsePincodes(data);
  } catch (error) {
    console.error("Error loading pincodes:", error);
    return [];
  }
};
