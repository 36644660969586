import React, { useState, useEffect } from "react";
import Select from "react-select";
import { uploadFile } from "../../firebase/fileUpload";
import { loadPincodes } from "../utilities/PincodeLoader";
import collapseIcon from "../event/assets/reduce.png";
import expandIcon from "../event/assets/expand-arrows.png";

const SponsorDetailForm = ({ onSponsorDataChange }) => {
  const [sponsorData, setSponsorData] = useState({
    sponsorCategory: "",
    noOfCoupons: "",
    noOfGiftHampers: "",
    description: "",
    price: "",
    additional_data: {
      country: "India",
      state: null,
      city: null,
      pincode: null,
      is_national: true,
      is_state: false,
      is_city: false,
      is_pincode: false,
      no_of_seats: "",
      proposal_url: "",
    },
  });

  const [stateOptions, setStateOptions] = useState([]);
  const [districtOptions, setDistrictOptions] = useState([]);
  const [pincodeOptions, setPincodeOptions] = useState([]);
  const [isSponsorOpen, setIsSponsorOpen] = useState(true);

  useEffect(() => {
    const fetchPincodes = async () => {
      const pincodeData = await loadPincodes();
      const uniqueStates = [
        ...new Set(pincodeData.map((item) => item.stateName)),
      ];
      setStateOptions(
        uniqueStates.map((state) => ({ value: state, label: state }))
      );
    };

    fetchPincodes();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSponsorData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAdditionalDataChange = (field, value) => {
    setSponsorData((prevData) => ({
      ...prevData,
      additional_data: {
        ...prevData.additional_data,
        [field]: value,
      },
    }));
  };

  const handleCheckboxChange = (level) => {
    setSponsorData((prevData) => {
      const newState = {
        ...prevData,
        additional_data: {
          ...prevData.additional_data,
          is_national: false,
          is_state: false,
          is_city: false,
          is_pincode: false,
          [level]: true,
        },
      };

      if (level === "is_city" && newState.additional_data.state) {
        loadDistrictOptions(newState.additional_data.state);
      }

      if (
        level === "is_pincode" &&
        newState.additional_data.state &&
        newState.additional_data.city
      ) {
        loadPincodeOptions(newState.additional_data.city);
      }

      return newState;
    });
  };

  const loadDistrictOptions = async (state) => {
    const pincodeData = await loadPincodes();
    const uniqueDistricts = [
      ...new Set(
        pincodeData
          .filter((item) => item.stateName === state)
          .map((item) => item.districtName)
      ),
    ];
    setDistrictOptions(
      uniqueDistricts.map((district) => ({ value: district, label: district }))
    );
  };

  const loadPincodeOptions = async (district) => {
    const pincodeData = await loadPincodes();
    const pincodes = pincodeData
      .filter((item) => item.districtName === district)
      .map((item) => item.pincode);
    setPincodeOptions(
      pincodes.map((pincode) => ({ value: pincode, label: pincode }))
    );
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const url = await uploadFile(file);
        handleAdditionalDataChange("proposal_url", url);
      } catch (error) {
        console.error("File upload failed:", error);
      }
    }
  };

  const handleSaveData = () => {
    const updatedSponsorData = {
      ...sponsorData,
      additional_data: {
        ...sponsorData.additional_data,
        no_of_seats: sponsorData.additional_data.no_of_seats || "N/A",
        proposal_url: sponsorData.additional_data.proposal_url || "N/A",
      },
    };

    onSponsorDataChange(updatedSponsorData);

    setSponsorData({
      sponsorCategory: "",
      noOfCoupons: "",
      noOfGiftHampers: "",
      description: "",
      price: "",
      additional_data: {
        country: "India",
        state: null,
        city: null,
        pincode: null,
        no_of_seats: "",
        proposal_url: "",
        is_national: true,
        is_state: false,
        is_city: false,
        is_pincode: false,
      },
    });
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-sm border my-4">
      <div className="w-full flex items-center justify-between">
        <h3 className="text-xl font-semibold text-blue-600 bg-gray-100 px-3 py-2 rounded-md mb-2">
          Sponsor Details
        </h3>
        <button
          type="button"
          onClick={() => setIsSponsorOpen(!isSponsorOpen)}
          className="flex items-center"
        >
          <img
            src={isSponsorOpen ? collapseIcon : expandIcon}
            alt={isSponsorOpen ? "Collapse" : "Expand"}
            className="w-6 h-6"
          />
        </button>
      </div>
      {isSponsorOpen && (
        <>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <div className="relative">
              <input
                type="text"
                name="sponsorCategory"
                value={sponsorData.sponsorCategory}
                onChange={handleInputChange}
                className="w-full border border-gray-300 rounded-md p-3 peer"
                placeholder=" "
              />
              <label className="absolute text-base text-blue-500 bg-white px-1 left-3 -top-2 transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500">
                Sponsor Category
              </label>
            </div>
            <div className="relative">
              <input
                type="number"
                name="noOfCoupons"
                value={sponsorData.noOfCoupons}
                onChange={handleInputChange}
                className="w-full border border-gray-300 rounded-md p-3 peer"
                placeholder=" "
              />
              <label className="absolute text-base text-blue-500 bg-white px-1 left-3 -top-2 transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500">
                No of Coupons
              </label>
            </div>
            <div className="relative">
              <input
                type="number"
                name="noOfGiftHampers"
                value={sponsorData.noOfGiftHampers}
                onChange={handleInputChange}
                className="w-full border border-gray-300 rounded-md p-3 peer"
                placeholder=" "
              />
              <label className="absolute text-base text-blue-500 bg-white px-1 left-3 -top-2 transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500">
                No of Gift Hampers
              </label>
            </div>
            <div className="relative">
              <input
                type="number"
                name="price"
                value={sponsorData.price}
                onChange={handleInputChange}
                className="w-full border border-gray-300 rounded-md p-3 peer"
                placeholder=" "
              />
              <label className="absolute text-base text-blue-500 bg-white px-1 left-3 -top-2 transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500">
                Price
              </label>
            </div>
          </div>
          <div className="my-8 lg:w-1/2 relative">
            <textarea
              name="description"
              value={sponsorData.description}
              onChange={handleInputChange}
              className="w-full border border-gray-300 rounded-md p-3 peer"
              placeholder=" "
            />
            <label className="absolute text-base text-blue-500 bg-white px-1 left-3 -top-2 transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500">
              Description
            </label>
          </div>
          <h3 className="text-lg font-medium text-gray-700 mb-3">
            Additional Data
          </h3>
          <div className="mb-4">
            <label className="mr-4">
              <input
                type="checkbox"
                checked={sponsorData.additional_data.is_national}
                onChange={() => handleCheckboxChange("is_national")}
              />
              <span className="ml-2">National</span>
            </label>
            <label className="mr-4">
              <input
                type="checkbox"
                checked={sponsorData.additional_data.is_state}
                onChange={() => handleCheckboxChange("is_state")}
              />
              <span className="ml-2">State</span>
            </label>
            <label className="mr-4">
              <input
                type="checkbox"
                checked={sponsorData.additional_data.is_city}
                onChange={() => handleCheckboxChange("is_city")}
              />
              <span className="ml-2">City</span>
            </label>
            <label className="mr-4">
              <input
                type="checkbox"
                checked={sponsorData.additional_data.is_pincode}
                onChange={() => handleCheckboxChange("is_pincode")}
              />
              <span className="ml-2">Pincode</span>
            </label>
          </div>
          {sponsorData.additional_data.is_state && (
            <Select
              options={stateOptions}
              value={stateOptions.find(
                (option) => option.value === sponsorData.additional_data.state
              )}
              onChange={(selectedOption) =>
                handleAdditionalDataChange("state", selectedOption.value)
              }
              placeholder="Select State"
            />
          )}
          {sponsorData.additional_data.is_city && (
            <Select
              options={districtOptions}
              value={districtOptions.find(
                (option) => option.value === sponsorData.additional_data.city
              )}
              onChange={(selectedOption) =>
                handleAdditionalDataChange("city", selectedOption.value)
              }
              placeholder="Select City"
            />
          )}
          {sponsorData.additional_data.is_pincode && (
            <Select
              options={pincodeOptions}
              value={pincodeOptions.find(
                (option) => option.value === sponsorData.additional_data.pincode
              )}
              onChange={(selectedOption) =>
                handleAdditionalDataChange("pincode", selectedOption.value)
              }
              placeholder="Select Pincode"
            />
          )}
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <div className="my-4 relative">
              <input
                type="number"
                value={sponsorData.additional_data.no_of_seats}
                onChange={(e) => handleAdditionalDataChange("no_of_seats", e.target.value)}
                className="w-full border border-gray-300 rounded-md p-3 peer"
                placeholder=" "
              />
              <label className="absolute text-base text-blue-500 bg-white px-1 left-3 -top-3 transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500">
                 Sponsor Entry(No of Seats)
              </label>
            </div>
            <div className="my-4 relative">
              <input
                type="file"
                onChange={handleFileUpload}
                className="w-full border border-gray-300 rounded-md p-3 peer"
                placeholder=" "
              />
              <label className="absolute text-base text-blue-500 bg-white px-1 left-3 -top-4 transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500">
              Proposal document
              </label>
            </div>
          </div>
          <button
            onClick={handleSaveData}
            className="bg-blue-500 text-white px-4 py-2 rounded-md"
          >
            Save Sponsor Data
          </button>
        </>
      )}
    </div>
  );
};

export default SponsorDetailForm;

// import React, { useState, useEffect } from "react";
// import Select from "react-select";
// import { uploadFile } from "../../firebase/fileUpload";
// import { loadPincodes } from "../utilities/PincodeLoader";

// const SponsorDetailForm = ({ onSponsorDataChange }) => {
//   const [sponsorData, setSponsorData] = useState({
//     sponsorCategory: "",
//     noOfCoupons: "",
//     noOfGiftHampers: "",
//     description: "",
//     price: "",
//     additional_data: {
//       no_of_seats: "",
//       proposal_url: "",
//       is_national: false,
//       is_state: false,
//       is_city: false,
//       is_pincode: false,
//       state: "",
//       district: "",
//       pincode: "",
//     },
//   });

//   const [stateOptions, setStateOptions] = useState([]);
//   const [districtOptions, setDistrictOptions] = useState([]);
//   const [pincodeOptions, setPincodeOptions] = useState([]);

//   useEffect(() => {
//     const fetchPincodes = async () => {
//       const pincodeData = await loadPincodes();
//       console.log("pincodeData", pincodeData)
//       const states = [...new Set(pincodeData.map((item) => item.stateName))];
//       setStateOptions(states.map((state) => ({ value: state, label: state })));
//     };

//     fetchPincodes();
//   }, []);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setSponsorData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleAdditionalDataChange = (field, value) => {
//     setSponsorData((prevData) => ({
//       ...prevData,
//       additional_data: {
//         ...prevData.additional_data,
//         [field]: value,
//       },
//     }));
//   };

//   const handleLocationChange = (type, value) => {
//     handleAdditionalDataChange(type, value);

//     if (type === "state") {
//       loadDistrictOptions(value);
//       handleAdditionalDataChange("district", "");
//       handleAdditionalDataChange("pincode", "");
//     } else if (type === "district") {
//       loadPincodeOptions(value);
//       handleAdditionalDataChange("pincode", "");
//     }
//   };

//   const loadDistrictOptions = async (state) => {
//     const pincodeData = await loadPincodes();
//     const districts = [
//       ...new Set(
//         pincodeData
//           .filter((item) => item.stateName === state)
//           .map((item) => item.districtName)
//       ),
//     ];
//     setDistrictOptions(districts.map((district) => ({ value: district, label: district })));
//   };

//   const loadPincodeOptions = async (district) => {
//     const pincodeData = await loadPincodes();
//     const pincodes = pincodeData
//       .filter((item) => item.districtName === district)
//       .map((item) => item.pincode);
//     setPincodeOptions(pincodes.map((pincode) => ({ value: pincode, label: pincode })));
//   };

//   const handleCheckboxChange = (checkbox) => {
//     const newValues = {
//       is_national: checkbox === "is_national",
//       is_state: checkbox === "is_state" || checkbox === "is_national",
//       is_city: checkbox === "is_city" || checkbox === "is_state" || checkbox === "is_national",
//       is_pincode: checkbox === "is_pincode" || checkbox === "is_city" || checkbox === "is_state" || checkbox === "is_national",
//     };
//     setSponsorData((prevData) => ({
//       ...prevData,
//       additional_data: { ...prevData.additional_data, ...newValues },
//     }));
//   };

//   const handleFileUpload = async (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       const url = await uploadFile(file);
//       handleAdditionalDataChange("proposal_url", url);
//     }
//   };

//   const handleSaveData = () => {
//     onSponsorDataChange(sponsorData);
//     setSponsorData({
//       sponsorCategory: "",
//       noOfCoupons: "",
//       noOfGiftHampers: "",
//       description: "",
//       price: "",
//       additional_data: {
//         no_of_seats: "",
//         proposal_url: "",
//         is_national: false,
//         is_state: false,
//         is_city: false,
//         is_pincode: false,
//         state: "",
//         district: "",
//         pincode: "",
//       },
//     });
//   };

//   return (
//     <div className="bg-white p-6 rounded-lg shadow-lg">
//       <h2 className="text-xl font-semibold text-gray-700 mb-4">Sponsor Details</h2>

//       {/* Sponsor Fields */}
//       <input
//         type="text"
//         name="sponsorCategory"
//         value={sponsorData.sponsorCategory}
//         onChange={handleInputChange}
//         placeholder="Sponsor Category"
//         className="w-full mb-4 px-4 py-2 border border-gray-300 rounded-md"
//       />

//       <input
//         type="number"
//         name="noOfCoupons"
//         value={sponsorData.noOfCoupons}
//         onChange={handleInputChange}
//         placeholder="No of Coupons"
//         className="w-full mb-4 px-4 py-2 border border-gray-300 rounded-md"
//       />

//       <input
//         type="number"
//         name="noOfGiftHampers"
//         value={sponsorData.noOfGiftHampers}
//         onChange={handleInputChange}
//         placeholder="No of Gift Hampers"
//         className="w-full mb-4 px-4 py-2 border border-gray-300 rounded-md"
//       />

//       <textarea
//         name="description"
//         value={sponsorData.description}
//         onChange={handleInputChange}
//         placeholder="Description"
//         className="w-full mb-4 px-4 py-2 border border-gray-300 rounded-md"
//       />

//       <input
//         type="number"
//         name="price"
//         value={sponsorData.price}
//         onChange={handleInputChange}
//         placeholder="Price"
//         className="w-full mb-4 px-4 py-2 border border-gray-300 rounded-md"
//       />

//       <h3 className="text-lg font-medium text-gray-700 mb-3">Additional Data</h3>

//       {/* Additional Data Fields */}
//       <input
//         type="number"
//         value={sponsorData.additional_data.no_of_seats}
//         onChange={(e) => handleAdditionalDataChange("no_of_seats", e.target.value)}
//         placeholder="No of Seats"
//         className="w-full mb-4 px-4 py-2 border border-gray-300 rounded-md"
//       />

//       <input type="file" onChange={handleFileUpload} className="w-full mb-4" />

//       {/* Location Checkboxes */}
//       <div className="mb-4">
//         <label className="mr-4">
//           <input
//             type="checkbox"
//             checked={sponsorData.additional_data.is_national}
//             onChange={() => handleCheckboxChange("is_national")}
//             className="mr-2"
//           />
//           National
//         </label>
//         <label className="mr-4">
//           <input
//             type="checkbox"
//             checked={sponsorData.additional_data.is_state}
//             onChange={() => handleCheckboxChange("is_state")}
//             className="mr-2"
//           />
//           State
//         </label>
//         <label className="mr-4">
//           <input
//             type="checkbox"
//             checked={sponsorData.additional_data.is_city}
//             onChange={() => handleCheckboxChange("is_city")}
//             className="mr-2"
//           />
//           City
//         </label>
//         <label className="mr-4">
//           <input
//             type="checkbox"
//             checked={sponsorData.additional_data.is_pincode}
//             onChange={() => handleCheckboxChange("is_pincode")}
//             className="mr-2"
//           />
//           Pincode
//         </label>
//       </div>

//       {/* Location Dropdowns */}
//       <div className="flex space-x-4 mb-4">
//         {sponsorData.additional_data.is_national && (
//           <Select
//             options={stateOptions}
//             onChange={(option) => handleLocationChange("state", option.value)}
//             placeholder="Select State"
//             className="flex-1"
//             isClearable={!sponsorData.additional_data.is_state}
//           />
//         )}

//         {sponsorData.additional_data.is_city && sponsorData.additional_data.state && (
//           <Select
//             options={districtOptions}
//             onChange={(option) => handleLocationChange("district", option.value)}
//             placeholder="Select District"
//             className="flex-1"
//             isClearable={!sponsorData.additional_data.is_city}
//           />
//         )}

//         {sponsorData.additional_data.is_pincode && sponsorData.additional_data.district && (
//           <Select
//             options={pincodeOptions}
//             onChange={(option) => handleAdditionalDataChange("pincode", option.value)}
//             placeholder="Select Pincode"
//             className="flex-1"
//             isClearable={!sponsorData.additional_data.is_pincode}
//           />
//         )}
//       </div>

//       <button
//         onClick={handleSaveData}
//         className="bg-blue-600 text-white px-4 py-2 rounded-lg w-full"
//       >
//         Save Sponsor Data
//       </button>
//     </div>
//   );
// };

// export default SponsorDetailForm;
