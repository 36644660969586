import axios from "axios";
import React, { useEffect, useState } from "react";

export const CollabRequest = ({ id, instagramBusinessAccountId, token }) => {
  const apiDomain = process.env.REACT_APP_API_DOMAIN;
  const [collabData, setCollabData] = useState(null);
  const[statusUpdated, setStatusUpdated]= useState(false);
  useEffect(() => {
    const fetchCollabRequest = async (id, pageSize = 100) => {
      try {
        const response = await axios.get(
          `${apiDomain}/api/v1/requestsbysender`,
          {
            params: {
              sender: id,
              pageSize: pageSize,
              access_token: token,
            },
          }
        );
        console.log("collab Data", response.data);
        setCollabData(response.data);
        return response.data;
      } catch (error) {
        console.error("Error fetching participants:", error);
        throw error;
      }
    };
    fetchCollabRequest(id);
  }, [id, instagramBusinessAccountId,statusUpdated]);
  var userName = "John Doe";
  var profileImg = "https://example.com/johndoe.jpg";
  var initialStatus = "pending"; // Can be 'pending', 'accepted', or 'rejected'

  // State to track the current request status
  const [requestStatus, setRequestStatus] = useState(initialStatus);

  // Handler functions for changing request status
  const handleAccept = async (id) => {
    const acceptCollabRequest = async () => {
        try {
          const response = await axios.post(
            `${apiDomain}/api/v1/adminapproverequest?requestid=${parseInt(id, 10)}`,
            {
              params: {
                requestId: id,
                access_token: token,
              },
            }
          );
          console.log("collab Data", response.data);
          return response.data;
        } catch (error) {
          console.error("Error fetching participants:", error);
          throw error;
        }
      };
      try{
        await acceptCollabRequest();
        alert("Request Accepted Successfully")
      }catch(ex){
        alert("Error accepting request",ex)
      }
      setStatusUpdated(!statusUpdated)
};
  const handleReject = async (id) => {
    const rejectCollabRequest = async () => {
        try {
          const response = await axios.post(
            `${apiDomain}/api/v1/adminrejectrequest?requestid=${parseInt(id, 10)}`,
            {
                params: {
                    requestId: id,
                    access_token: token,
                  },
            }
          );
          console.log("collab Data", response.data);
          return response.data;
        } catch (error) {
          console.error("Error fetching participants:", error);
          throw error;
        }
      };
      try{
        await rejectCollabRequest();
        alert("Request Rejected Successfully")
      }catch(ex){
        alert("Error rejecting request",ex)
      }
      setStatusUpdated(!statusUpdated)
};

  return (
    <div className="grid lg:grid-cols-3 grid-cols-1 gap-8  my-8">
      {collabData &&
        collabData.map((x) => {
          return (
            <div className="collab-request border p-4 rounded-md shadow-lg">
              <div className="flex items-center mb-4">
                <img
                  src={x.recieverIg.profile_picture_url}
                  alt={`${userName}'s profile`}
                  className="w-12 h-12 rounded-full object-cover mr-4"
                />
                <h2 className="text-xl font-semibold">
                  {x.recieverIg.name}
                </h2>
              </div>

              <div className="status-section mb-4">
                <p className="text-gray-700">
                  User Request Status:{" "}
                  <span
                    className={`font-bold ${
                      x.accepted === 1
                        ? "text-green-500"
                        : x.accepted === 0
                        ? "text-red-500"
                        : "text-yellow-500"
                    }`}
                  >
                    { x.accepted === 1
                        ? "Accepted"
                        : x.accepted === 0
                        ? "Rejected"
                        : "Pending"}
                  </span>
                </p>
              </div>

              {x.adminStatus === -1 ? (
                <div className="action-buttons flex space-x-4">
                  <button
                    onClick={() => handleAccept(x.requestId)}
                    className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
                  >
                    Accept
                  </button>
                  <button
                    onClick={() => handleReject(x.requestId)}
                    className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
                  >
                    Reject
                  </button>
                </div>
              ) : x.adminStatus === 0 ? (
                <div className="reset-button">
                 Admin Status : Rejected
                </div>
              ): (<div className="reset-button">
                Admin Status : Accepted
              </div>)}
            </div>
          );
        })}
    </div>
  );
};