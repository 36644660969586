import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import "normalize.css";
import "./App.css";
import Dashboard from "./newComponents/event/Dashboard";
import EventForm from "./newComponents/event/EventForm";
import Participants from "./newComponents/participant/instagramUI/Participants";
// import ParticipantDetails from "../unUsedComponents/Participants/participantDetails/ParticipantDetails";
// import ParticipantPreference from "../unUsedComponents/Participants/participantPreference/ParticipantPreference";
import Sponsors from "./components/content/sponsors/Sponsors";
import SponsorDetails from "./components/content/sponsors/sponsorDetails/SponsorDetails";
import Events from "./newComponents/event/Events";
import Donation from "./components/content/donations/Donation";
// import DonationSettings from "./components/content/donations/donationSettings/DonationSettings";
// import RoleManagement from "./components/content/rolemanagement/RoleManagement";
// import EditRole from "./components/content/rolemanagement/editRole/EditRole";
import CollaborationRequest from "./components/content/sponsors/collaborationRequest/CollaborationRequest";
// import EditEventForm from "./components/content/Dashboard/editevent/EditEventForm";
import Category from "./newComponents/category/Category";
import { Login } from "./components/loginPage/Login";
import Cookies from "js-cookie";
import { FbCallbackHandler } from "./components/loginPage/loginHandelSystem/fbCallbackHandler";
import { Venue } from "./newComponents/venue/Venue";
import EventHashtag from "./newComponents/eventHashtag/EventHashtag";
import { Instagram } from "./newComponents/participant/instagramUI/Instagram";
import { SponsorInsta } from "./components/content/sponsors/sponsorListTable/SponsorInsta";
import { PrivacyPolicy } from "./components/content/policyPage/PrivacyPolicy";
import { Terms } from "./components/content/policyPage/Terms";
import { FairPolicy } from "./components/content/policyPage/FairPolicy";
import FixedComponents from "./newComponents/FixedComponents";
import Wallet from "./newComponents/wallet/Wallet";
import Notifications from "./newComponents/notification/Notification";
import HelpSupport from "./newComponents/helpSupport/HelpSupport";
import Referal from "./newComponents/referal/Referal";
import WinnersList from "./newComponents/winnings/WinnersList";
import FAQManager from "./newComponents/faqs/FAQManager";
import VideoPackage from "./newComponents/videoPackage/VideoPackage";
import { CollabComponent } from "./newComponents/collabRequest/Collab";
import { SponsorshipDetail } from "./components/content/sponsors/sponsorListTable/SponsorshipDetail";
import SponsorDetail from "./components/content/sponsors/sponsorListTable/SponsorDetail";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const id = "some_id"; // Replace this with the actual id or obtain from context/hooks
  const instagramBusinessAccountId = "instagram_business_account_id";
  const userFbToken = "user_facebook_token";

  useEffect(() => {
    const checkAuth = async () => {
      const authToken = Cookies.get("authToken");
      const fbAuthToken = Cookies.get("fbauthToken");
      const loginTime = Cookies.get("loginTime");
      const userId = Cookies.get("userId");
      if (
        authToken &&
        fbAuthToken &&
        loginTime &&
        Date.now() - loginTime < 24 * 60 * 60 * 1000
      ) {
        console.log("logged In Successfully");
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    };
    checkAuth();
  }, [location, navigate]);

  return (
    <div className="App">
      <FixedComponents />
      <div className="mainContent">
        {/* <Navbar /> */}
        <div className="content">
          <Routes>
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/fairpolicy" element={<FairPolicy />} />
            <Route path="/termcondition" element={<Terms />} />
            {!isLoggedIn ? (
              <Route path="/admin/login" element={<Login />} />
            ) : (
              <Route path="/admin/login" element={<Dashboard />} />
            )}
            <Route
              path="/auth/facebook/callback"
              element={<FbCallbackHandler />}
            />

            {isLoggedIn ? (
              <>
                <Route path="/" element={<Dashboard />} />
                <Route
                  path="/participants/:id/:instagramBusinessAccountId/:isiguser"
                  element={<Instagram />}
                />
                <Route path="/participants" element={<Participants />} />
                <Route path="/category" element={<Category />} />
                {/* <Route
                  path="/collab-request" element={<SponsorDetail />} />
                */}
                <Route path="/venue" element={<Venue />} />
                <Route path="/eventhashtag" element={<EventHashtag />} />
                <Route path="/sponsors" element={<Sponsors />} />            
                <Route path="/events" element={<Events />} />
                <Route path="/donations" element={<Donation />} />
                <Route path="/notification" element={<Notifications />} />
                <Route path="/wallet" element={<Wallet />} />
                <Route path="/winnings" element={<WinnersList />} />
                <Route path="/helpsupport" element={<HelpSupport />} />
                <Route path="/referal" element={<Referal />} />
                <Route path="/event-form" element={<EventForm />} />
                <Route path="/faqs" element={<FAQManager />} />
                <Route path="/video-package" element={<VideoPackage />} />
                {/* <Route
                  path="/participants/:participantId"
                  element={<ParticipantDetails />}
                /> */}
                <Route
                  path="/sponsor/:participantId"
                  element={<SponsorDetails />}
                />
                <Route
                  path="/sponsors/:id/:instagramBusinessAccountId"
                  element={<SponsorInsta />}
                />
                {/* <Route
                  path="/participant/:participantId/preferences"
                  element={<ParticipantPreference />}
                /> */}
              </>
            ) : (
              <Route path="*" element={<Navigate to="/admin/login" />} />
            )}
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default App;
