import React, { useContext, useState } from "react";
import { DataContext } from "../context/DataContext";
import axios from "axios";
import LoadingScreen from "../utilities/LoadingScreen";
import { FiUser, FiCalendar, FiGift, FiSave, FiChevronLeft, FiChevronRight } from "react-icons/fi";

const Referal = () => {
  const { referrals, rewards, loading } = useContext(DataContext);
  if (loading) <LoadingScreen />;

  const apiDomain = process.env.REACT_APP_API_DOMAIN;
  const [referralReward, setReferralReward] = useState(0);
  const [referredReward, setReferredReward] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const referralsPerPage = 9;

  if (referralReward === 0) setReferralReward(rewards.referred_user_reward);
  if (referredReward === 0) setReferredReward(rewards.referrer_reward);

  const setReferralData = async (referrerRewardStr, referredUserRewardStr) => {
    if (isNaN(referrerRewardStr) || isNaN(referredUserRewardStr)) {
      alert("Invalid reward values. Please enter valid numbers.");
      return;
    }
    const referralData = {
      referrer_reward: parseFloat(referrerRewardStr),
      referred_user_reward: parseFloat(referredUserRewardStr),
    };

    try {
      const response = await axios.post(
        `${apiDomain}/api/v2/referral-data`,
        referralData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      alert(response.data);
    } catch (error) {
      alert("Failed to set referral data. Please try again.");
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    return `${day}/${month}/${year}`;
  };

  // Pagination logic
  const lastReferralIndex = currentPage * referralsPerPage;
  const firstReferralIndex = lastReferralIndex - referralsPerPage;
  const currentReferrals = referrals.referrals.slice(firstReferralIndex, lastReferralIndex);
  const totalPages = Math.ceil(referrals.referrals.length / referralsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  return (
    <div className="lg:p-1 min-h-screen lg:ml-[20vw] lg:mr-[2vw] bg-gray-50">
      <h2 className="text-3xl font-bold mb-6 text-indigo-800">Referral Management</h2>

      {/* Input Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
        <div className="p-4 bg-white shadow-md rounded-lg">
          <label className="block text-gray-700 font-semibold mb-2" htmlFor="referralReward">
            Referral Reward
          </label>
          <input
            type="number"
            id="referralReward"
            className="w-full p-3 border border-gray-300 rounded-lg"
            value={referralReward}
            onChange={(e) => setReferralReward(e.target.value)}
          />
        </div>
        <div className="p-4 bg-white shadow-md rounded-lg">
          <label className="block text-gray-700 font-semibold mb-2" htmlFor="referredReward">
            Referred Reward
          </label>
          <input
            type="number"
            id="referredReward"
            className="w-full p-3 border border-gray-300 rounded-lg"
            value={referredReward}
            onChange={(e) => setReferredReward(e.target.value)}
          />
        </div>
        <button
          className="w-full bg-blue-500 text-white py-3 rounded-md flex items-center justify-center"
          onClick={() => setReferralData(referralReward, referredReward)}
        >
          <FiSave className="mr-2" /> Save
        </button>
      </div>

      {/* Referral Cards */}
      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        {currentReferrals.map((entry, index) => (
          <div
            key={index}
            className="p-6 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow"
          >
            <div className="flex items-center space-x-4 mb-4">
              <FiUser className="text-indigo-600 text-2xl" />
              <div>
                <h3 className="text-lg font-bold text-gray-800">
                  Referrer ID: <span className="text-indigo-600">{entry.referrer_id}</span>
                </h3>
                <h3 className="text-lg font-bold text-gray-800">
                  Referred ID: <span className="text-indigo-600">{entry.referred_user_id}</span>
                </h3>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <FiGift className="text-green-500" />
              <p className="text-gray-700 font-semibold">Status: {entry.status}</p>
            </div>
            <div className="flex items-center space-x-4 mt-2">
              <FiCalendar className="text-yellow-500" />
              <p className="text-gray-700 font-semibold">
                Created At: {formatDate(entry.created_at)}
              </p>
            </div>
          </div>
        ))}
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-center items-center mt-6">
        <button
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
          className="p-2 bg-indigo-600 text-white rounded-l-md disabled:opacity-50"
        >
          <FiChevronLeft />
        </button>
        <span className="px-4 py-2 bg-white text-indigo-600 font-semibold border">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="p-2 bg-indigo-600 text-white rounded-r-md disabled:opacity-50"
        >
          <FiChevronRight />
        </button>
      </div>
    </div>
  );
};

export default Referal;







// import React, { useContext, useState } from "react";
// import { DataContext } from "../context/DataContext";
// import axios from "axios";
// import LoadingScreen from "../utilities/LoadingScreen";

// const Referal = () => {
//   const { referrals, rewards, loading } = useContext(DataContext);
//   if (loading) <LoadingScreen />

//   const apiDomain = process.env.REACT_APP_API_DOMAIN;
//   console.log("Referrals data:", referrals, rewards);
//   const [referralReward, setReferralReward] = useState(0); // Static referral reward
//   const [referredReward, setReferredReward] = useState(0); // Static referred reward
//   if (referralReward == 0) {
//     setReferralReward(rewards.referred_user_reward);
//   }
//   if (referredReward == 0) {
//     setReferredReward(rewards.referrer_reward);
//   }
//   const setReferralData = async (referrerRewardStr, referredUserRewardStr) => {
//     // Check if the conversion resulted in valid numbers
//     if (isNaN(referrerRewardStr) || isNaN(referredUserRewardStr)) {
//       alert("Invalid reward values. Please enter valid numbers.");
//       return;
//     }
//     console.log(referredUserRewardStr, referrerRewardStr);
//     // Define the referral data to send
//     const referralData = {
//       referrer_reward: parseFloat(referrerRewardStr), // Converted to float
//       referred_user_reward: parseFloat(referredUserRewardStr), // Converted to float
//     };

//     try {
//       // Make the POST request to the referral-data endpoint
//       const response = await axios.post(
//         `${apiDomain}/api/v2/referral-data`,
//         referralData,
//         {
//           headers: {
//             "Content-Type": "application/json",
//           },
//         }
//       );

//       // Handle the response (success message)
//       console.log(response.data); // Expected: "Referral data updated successfully" or "Referral data created successfully"
//       alert(response.data); // Optional: Display success message
//     } catch (error) {
//       // Handle any errors during the request
//       console.error(
//         "Error setting referral data:",
//         error.response ? error.response.data : error.message
//       );
//       alert("Failed to set referral data. Please try again.");
//     }
//   };

//   const formatDate = (dateString) => {
//     const date = new Date(dateString);
//     const day = String(date.getDate()).padStart(2, '0');
//     const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so we add 1
//     const year = String(date.getFullYear()).slice(-2); // Get the last two digits of the year
//     return `${day}/${month}/${year}`;
//   };

//   return (
//     <div className="lg:p-10 min-h-screen lg:ml-[20vw] lg:mr-[2vw]">
//       <h2 className="text-3xl font-bold mb-6">Referral Management</h2>

//       {/* Input Section */}
//       <div className="grid grid-cols-2 gap-4 mb-6">
//         {/* Referral Reward */}
//         <div>
//           <label
//             className="block text-gray-700 font-semibold mb-2"
//             htmlFor="referralReward"
//           >
//             Referral Reward
//           </label>
//           <input
//             type="number"
//             id="referralReward"
//             className="w-full p-3 border border-gray-300 rounded-lg"
//             value={referralReward}
//             onChange={(e) => setReferralReward(e.target.value)}
//           />
//         </div>
//         {/* Referred Reward */}
//         <div>
//           <label
//             className="block text-gray-700 font-semibold mb-2"
//             htmlFor="referredReward"
//           >
//             Referred Reward
//           </label>
//           <input
//             type="number"
//             id="referredReward"
//             className="w-full p-3 border border-gray-300 rounded-lg"
//             value={referredReward}
//             onChange={(e) => setReferredReward(e.target.value)}
//           />
//         </div>
//       </div>

//       <div className="relative">
//         <button
//           className="absolute right-8 bg-blue-500 text-slate-50 px-4 py-2 rounded-md"
//           onClick={() => setReferralData(referralReward, referredReward)}
//         >
//           Save
//         </button>
//       </div>

//       {/* Total Referral Rewards */}
//       {/* <div className="bg-white p-6 rounded-lg shadow-lg mb-6">
//         <h3 className="text-2xl font-semibold">
//           Total Referral Rewards ={" "}
//           <span className="text-blue-600">${totalReferralRewards}</span>
//         </h3>
//       </div> */}

//       {/* Referral Table */}
//       <div className="bg-white p-6 rounded-lg shadow-lg">
//         <h3 className="text-xl font-semibold mb-4">Referral Data</h3>
//         <table className="w-full table-auto border-collapse">
//           <thead>
//             <tr className="bg-gray-200">
//               <th className="border px-4 py-2 text-left">Referral User (ID)</th>
//               <th className="border px-4 py-2 text-left">Referred User (ID)</th>
//               <th className="border px-4 py-2 text-left">Status</th>
//               <th className="border px-4 py-2 text-left">Created At</th>
//             </tr>
//           </thead>
//           <tbody>
//             {referrals &&
//               referrals.referrals.map((entry, index) => (
//                 <tr key={index} className="bg-white hover:bg-gray-100">
//                   {/* Referral User ID */}
//                   <td className="border px-4 py-2">
//                     <span className="font-semibold">{entry.referrer_id}</span>
//                   </td>
//                   {/* Referred User ID */}
//                   <td className="border px-4 py-2">
//                     <span className="font-semibold">
//                       {entry.referred_user_id}
//                     </span>
//                   </td>
//                   {/* Status */}
//                   <td className="border px-4 py-2">{entry.status}</td>
//                   {/* Created At */}
//                   <td className="border px-4 py-2">{formatDate(entry.created_at)}</td>
//                   {/* Reward */}
//                 </tr>
//               ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default Referal;
