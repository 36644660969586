import React from "react";
import AddCategory from "./AddCategory/AddCategory";
import CategoryTable from "./CategoryTable/CategoryTable";

const Category = () => {
  return (
    <div className="lg:ml-[20vw] lg:mr-8 mt-12">
      <CategoryTable />
      <br />
      <AddCategory />
      
    </div>
  );
};

export default Category;
