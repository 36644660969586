import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { uploadFile } from "../../firebase/fileUpload";

const AdditionalDataForm = ({ eventId }) => {
  const [isHashtagsEditable, setIsHashtagsEditable] = useState(false);
  const [isCaptionEditable, setIsCaptionEditable] = useState(false);
  const [isSponsorTagsEditable, setIsSponsorTagsEditable] = useState(false);
  const [advideo, setAdVideo] = useState(null);
  const [likeWeight, setLikeWeight] = useState(0);
  const [commentWeight, setCommentWeight] = useState(0);
  const [shareWeight, setShareWeight] = useState(0);
  const [playCount, setPlayCount] = useState(0);
  const [demoVideo, setDemoVideo] = useState(null);
  const [advideoUrl, setAdVideoUrl] = useState(null);
  const [demoVideoUrl, setDemoVideoUrl] = useState(null);
  const [coinValue, setCoinValue] = useState(0);
  const [entities, setEntities] = useState([
    { entityName: "", isEntity: false },
  ]);

  // Fields related to referred and referrer coins
  const [referredCoins, setReferredCoins] = useState({
    sponsorTosponsor: 0,
    sponsorTouser: 0,
    userTouser: 0,
    userTosponsor: 0,
  });

  const [referrerCoins, setReferrerCoins] = useState({
    sponsorTosponsor: 0,
    sponsorTouser: 0,
    userTouser: 0,
    userTosponsor: 0,
  });

  const [isEditMode, setIsEditMode] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const apiDomain = process.env.REACT_APP_API_DOMAIN;

  // Fetch existing additional data if it's an edit mode
  useEffect(() => {
    const fetchAdditionalData = async () => {
      try {
        const response = await axios.get(
          `${apiDomain}/api/v2/event/additional/get?event_id=${eventId}`
        );

        if (response.data) {
          const data = response.data;
          setIsHashtagsEditable(data.isHashtagsEditable);
          setIsCaptionEditable(data.isCaptionEditable);
          setIsSponsorTagsEditable(data.isSponsorTagsEditable);
          setAdVideoUrl(data.advideo);
          setLikeWeight(data.lekeWeight || 0);
          setCommentWeight(data.commentWeight || 0);
          setShareWeight(data.shareWeight || 0);
          setPlayCount(data.playCount || 0);
          setDemoVideoUrl(data.demoVideo);
          setCoinValue(data.coinValue || 0);
          setEntities(data.entities || [{ entityName: "", isEntity: false }]);
          setReferredCoins({
            sponsorTosponsor: data.referred_coins_sponsorTosponsor || 0,
            sponsorTouser: data.referred_coins_sponsorTouser || 0,
            userTouser: data.referred_coins_userTouser || 0,
            userTosponsor: data.referred_coins_userTosponsor || 0,
          });
          setReferrerCoins({
            sponsorTosponsor: data.reffferer_coins_sponsorTosponsor || 0,
            sponsorTouser: data.reffferer_coins_sponsorTouser || 0,
            userTouser: data.reffferer_coins_userTouser || 0,
            userTosponsor: data.reffferer_coins_userTosponsor || 0,
          });
          setIsEditMode(true);
        }
      } catch (error) {
        console.error("Error fetching additional data:", error);
      }
    };

    if (eventId) {
      fetchAdditionalData();
    }
  }, [eventId]);

  const handleFileChange = (e, setFile) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
    }
  };

  const handleSaveMedia = async () => {
    setIsUploading(true);
    try {
      // Upload advideo if present and retrieve Firebase URL
      let advideoUrlTemp = advideoUrl;
      if (advideo) {
        advideoUrlTemp = await uploadFile(advideo); // Upload to Firebase
        setAdVideoUrl(advideoUrlTemp); // Set local state for advideo URL
      }

      // Upload demoVideo if present and retrieve Firebase URL
      let demoVideoUrlTemp = demoVideoUrl;
      if (demoVideo) {
        demoVideoUrlTemp = await uploadFile(demoVideo); // Upload to Firebase
        setDemoVideoUrl(demoVideoUrlTemp); // Set local state for demoVideo URL
      }

      // Prepare additional data payload with Firebase URLs
      const additionalData = {
        isHashtagsEditable,
        isCaptionEditable,
        isSponsorTagsEditable,
        advideo: advideoUrlTemp, // Use Firebase URL
        demoVideo: demoVideoUrlTemp, // Use Firebase URL
        likeWeight,
        commentWeight,
        shareWeight,
        playCount,
        coinValue,
        entities,
        referred_coins_sponsorTosponsor: referredCoins.sponsorTosponsor,
        referred_coins_sponsorTouser: referredCoins.sponsorTouser,
        referred_coins_userTouser: referredCoins.userTouser,
        referred_coins_userTosponsor: referredCoins.userTosponsor,
        reffferer_coins_sponsorTosponsor: referrerCoins.sponsorTosponsor,
        reffferer_coins_sponsorTouser: referrerCoins.sponsorTouser,
        reffferer_coins_userTouser: referrerCoins.userTouser,
        reffferer_coins_userTosponsor: referrerCoins.userTosponsor,
      };

      // Retrieve access token and post data to API
      const accessToken = Cookies.get("fbauthToken");

      const response = await axios.post(
        `${apiDomain}/api/v2/event/additional/post?event_id=${eventId}&access_token=${accessToken}`,
        additionalData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        alert(
          isEditMode
            ? "Additional data updated successfully!"
            : "Additional data added successfully!"
        );
      } else {
        alert("Error saving additional data.");
      }
    } catch (error) {
      console.error("Error uploading files or saving additional data:", error);
      alert("Failed to submit additional data.");
    } finally {
      setIsUploading(false);
    }
  };

  // Dynamic handling of adding/removing entities
  const handleAddEntity = () => {
    setEntities([...entities, { entityName: "", isEntity: false }]);
  };

  const handleRemoveEntity = (index) => {
    setEntities(entities.filter((_, i) => i !== index));
  };

  const handleEntityChange = (index, key, value) => {
    const updatedEntities = entities.map((entity, i) => {
      if (i === index) {
        return { ...entity, [key]: value };
      }
      return entity;
    });
    setEntities(updatedEntities);
  };

  return (
    <div className="lg:ml-[20vw] lg:mr-[2vw] p-4 border rounded-md mt-4 mb-10 bg-white shadow-xl">
      <h2 className="w-fit text-xl font-semibold text-blue-600 bg-gray-100 px-3 py-2 rounded-md mb-8">
        {isEditMode
          ? "Edit Additional Data For Event"
          : "Add Additional Data For Event"}
      </h2>

      {/* Editable Fields */}
      <section className="grid grid-cols-1 lg:grid-cols-3 gap-16">
        <div className="mb-4 flex items-center justify-between p-2 pr-8 rounded-xl shadow-xl">
          <label className="block mb-2 text-base font-medium text-gray-900">
            Are Hashtags Editable?
          </label>
          <input
            type="checkbox"
            checked={isHashtagsEditable}
            onChange={() => setIsHashtagsEditable(!isHashtagsEditable)}
            className="h-5 w-5 text-blue-600"
          />
        </div>

        <div className="mb-4 flex items-center justify-between p-2 pr-8 rounded-xl shadow-xl">
          <label className="block mb-2 text-base font-medium text-gray-900">
            Is Caption Editable?
          </label>
          <input
            type="checkbox"
            checked={isCaptionEditable}
            onChange={() => setIsCaptionEditable(!isCaptionEditable)}
            className="h-5 w-5 text-blue-600"
          />
        </div>

        <div className="mb-4 flex items-center justify-between p-2 pr-8 rounded-xl shadow-xl">
          <label className="block mb-2 text-base font-medium text-gray-900">
            Are Sponsor Tags Editable?
          </label>
          <input
            type="checkbox"
            checked={isSponsorTagsEditable}
            onChange={() => setIsSponsorTagsEditable(!isSponsorTagsEditable)}
            className="h-5 w-5 text-blue-600"
          />
        </div>
      </section>

      {/* Video Uploads */}
      <section className="grid grid-cols-1 lg:grid-cols-2 gap-8 mt-8">
        <div className="mb-4">
          <label className="block mb-2 text-lg font-medium text-gray-900">
            Advertisement Video (advideo)
          </label>
          <input
            type="file"
            onChange={(e) => handleFileChange(e, setAdVideo)}
            className="border border-gray-300 rounded-md"
          />
          {advideoUrl && (
            <video
              src={advideoUrl}
              controls
              className="my-4 object-cover rounded-md"
            />
          )}
        </div>

        <div className="mb-4">
          <label className="block mb-2 text-lg font-medium text-gray-900">
            Demo Video
          </label>
          <input
            type="file"
            onChange={(e) => handleFileChange(e, setDemoVideo)}
            className=" border border-gray-300 rounded-md"
          />
          {demoVideoUrl && (
            <video
              src={demoVideoUrl}
              controls
              className="object-cover rounded-md my-4"
              volume={1.0} // This sets the volume to 100%
              muted={false} // This ensures the video is not muted
            />
          )}
        </div>
      </section>

      {/* Entities */}
      <div className="mb-4 border-b">
        <h3 className="block mb-2 text-lg font-medium text-gray-900">
          Entities
        </h3>
        <div className="grid grid-col-1 lg:grid-cols-2 gap-5">
          {entities.map((entity, index) => (
            <div key={index} className="w-full flex items-center gap-4 mb-2">
              <input
                type="text"
                value={entity.entityName}
                onChange={(e) =>
                  handleEntityChange(index, "entityName", e.target.value)
                }
                className="w-full lg:w-2/3 border border-gray-300 rounded-md px-4 py-2 mr-2"
                placeholder="Entity Name"
              />
              <input
                type="checkbox"
                checked={entity.isEntity}
                onChange={(e) =>
                  handleEntityChange(index, "isEntity", e.target.checked)
                }
                className="h-5 w-5 text-blue-600"
              />
              <button
                type="button"
                onClick={() => handleRemoveEntity(index)}
                className="ml-2 text-red-600 text-lg bg-gray-300 px-2 rounded-2xl hover:bg-slate-200 hover:cursor-pointer"
              >
                Remove
              </button>
            </div>
          ))}
        </div>
        <button
          type="button"
          onClick={handleAddEntity}
          className="px-4 py-2 bg-blue-600 text-white rounded-md my-5"
        >
          Add Entity
        </button>
      </div>

            {/* Coin Value */}
            <div className="mb-4">
        <label className="block mb-2 text-lg font-medium text-gray-900">
          Coin Value
        </label>
        <input
          type="number"
          value={coinValue}
          onChange={(e) => setCoinValue(e.target.value)}
          className="w-full lg:w-1/2 border border-gray-300 rounded-md px-4 py-2"
        />
      </div>
      {/* Referred Coins */}
      <div className="mb-4">
        <h3 className="text-lg font-medium text-gray-900 my-5">
          Referred Coins
        </h3>
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-8">
          <div className="relative">
            <input
              type="number"
              value={referredCoins.sponsorTosponsor}
              onChange={(e) =>
                setReferredCoins({
                  ...referredCoins,
                  sponsorTosponsor: e.target.value,
                })
              }
              className="w-full border border-gray-300 rounded-md px-4 py-2 peer"
              placeholder=" "
            />
            <label className="absolute text-sm text-blue-500 bg-white px-1 left-3 -top-2 transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500">
              Sponsor to Sponsor
            </label>
          </div>
          <div className="relative">
            <input
              type="number"
              value={referredCoins.sponsorTouser}
              onChange={(e) =>
                setReferredCoins({
                  ...referredCoins,
                  sponsorTouser: e.target.value,
                })
              }
              className="w-full border border-gray-300 rounded-md px-4 py-2 peer"
              placeholder=" "
            />
            <label className="absolute text-sm text-blue-500 bg-white px-1 left-3 -top-2 transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500">
              Sponsor to User
            </label>
          </div>
          <div className="relative">
            <input
              type="number"
              value={referredCoins.userTouser}
              onChange={(e) =>
                setReferredCoins({
                  ...referredCoins,
                  userTouser: e.target.value,
                })
              }
              className="w-full border border-gray-300 rounded-md px-4 py-2 peer"
              placeholder=" "
            />
            <label className="absolute text-sm text-blue-500 bg-white px-1 left-3 -top-2 transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500">
              User to User
            </label>
          </div>
          <div className="relative">
            <input
              type="number"
              value={referredCoins.userTosponsor}
              onChange={(e) =>
                setReferredCoins({
                  ...referredCoins,
                  userTosponsor: e.target.value,
                })
              }
              className="w-full border border-gray-300 rounded-md px-4 py-2 peer"
              placeholder=" "
            />
            <label className="absolute text-sm text-blue-500 bg-white px-1 left-3 -top-2 transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500">
              User to Sponsor
            </label>
          </div>
        </div>
      </div>

      {/* Referrer Coins */}
      <div className="mb-4">
        <h3 className="text-lg font-medium text-gray-900 my-5">
          Referrer Coins
        </h3>
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-8">
          <div className="relative">
            <input
              type="number"
              value={referrerCoins.sponsorTosponsor}
              onChange={(e) =>
                setReferrerCoins({
                  ...referrerCoins,
                  sponsorTosponsor: e.target.value,
                })
              }
              className="w-full border border-gray-300 rounded-md px-4 py-2 peer"
              placeholder=" "
            />
            <label className="absolute text-sm text-blue-500 bg-white px-1 left-3 -top-2 transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500">
              Sponsor to Sponsor
            </label>
          </div>
          <div className="relative">
            <input
              type="number"
              value={referrerCoins.sponsorTouser}
              onChange={(e) =>
                setReferrerCoins({
                  ...referrerCoins,
                  sponsorTouser: e.target.value,
                })
              }
              className="w-full border border-gray-300 rounded-md px-4 py-2 peer"
              placeholder=" "
            />
            <label className="absolute text-sm text-blue-500 bg-white px-1 left-3 -top-2 transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500">
              Sponsor to User
            </label>
          </div>
          <div className="relative">
            <input
              type="number"
              value={referrerCoins.userTouser}
              onChange={(e) =>
                setReferrerCoins({
                  ...referrerCoins,
                  userTouser: e.target.value,
                })
              }
              className="w-full border border-gray-300 rounded-md px-4 py-2 peer"
              placeholder=" "
            />
            <label className="absolute text-sm text-blue-500 bg-white px-1 left-3 -top-2 transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500">
              User to User
            </label>
          </div>
          <div className="relative">
            <input
              type="number"
              value={referrerCoins.userTosponsor}
              onChange={(e) =>
                setReferrerCoins({
                  ...referrerCoins,
                  userTosponsor: e.target.value,
                })
              }
              className="w-full border border-gray-300 rounded-md px-4 py-2 peer"
              placeholder=" "
            />
            <label className="absolute text-sm text-blue-500 bg-white px-1 left-3 -top-2 transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500">
              User to Sponsor
            </label>
          </div>
        </div>
      </div>

      {/* Voting weight */}
      <div className="my-8">
        <h3 className="text-lg font-medium text-gray-900 my-5">
          Voting Weight
        </h3>
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-8">
          <div className="relative">
            <input
              type="number"
              value={likeWeight}
              onChange={(e) => setLikeWeight(e.target.value)}
              className="w-full  border border-gray-300 rounded-md px-4 py-2"
            />
            <label className="absolute text-sm text-blue-500 bg-white px-1 left-3 -top-2 transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500">
              Like Weight
            </label>
          </div>
          <div className="relative">
            <input
              type="number"
              value={commentWeight}
              onChange={(e) => setCommentWeight(e.target.value)}
              className="w-full  border border-gray-300 rounded-md px-4 py-2"
            />
            <label className="absolute text-sm text-blue-500 bg-white px-1 left-3 -top-2 transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500">
              Comment Weight
            </label>
          </div>
          <div className="relative">
            <input
              type="number"
              value={shareWeight}
              onChange={(e) => setShareWeight(e.target.value)}
              className="w-full border border-gray-300 rounded-md px-4 py-2"
            />
            <label className="absolute text-sm text-blue-500 bg-white px-1 left-3 -top-2 transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500">
              Share Weight
            </label>
          </div>
          <div className="relative">
            <input
              type="number"
              value={playCount}
              onChange={(e) => setPlayCount(e.target.value)}
              className="w-full border border-gray-300 rounded-md px-4 py-2"
            />
            <label className="absolute text-sm text-blue-500 bg-white px-1 left-3 -top-2 transition-all peer-placeholder-shown:top-3 peer-placeholder-shown:text-gray-500 peer-focus:-top-2 peer-focus:text-blue-500">
              Play Count
            </label>
          </div>
        </div>
      </div>

      <button
        onClick={handleSaveMedia}
        disabled={isUploading}
        className="px-4 py-2 bg-blue-600 text-white rounded-md"
      >
        {isUploading
          ? "Saving..."
          : isEditMode
          ? "Update Additional Data"
          : "Save Additional Data"}
      </button>
    </div>
  );
};

export default AdditionalDataForm;
