import React, { useEffect, useState } from "react";
import axios from "axios";

export const ParticipationDetail = ({ details, eventData, categories, ageCategories, genderCategories, followerCountCategories,token }) => {
  const [error, setError] = useState(null)
  const [insights, setInsights] = useState(null)
  const [loading, setLoading] = useState(false)
  const apiDomain = process.env.REACT_APP_API_DOMAIN;
  const getResponse = async (url) => {
    try {
      const response = await axios.get(url, {
        params: {
          access_token: token,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching media details:', error);
      throw error;
    }
  };
  useEffect(() => {
    if(!details){
        return
    }
    const fetchInsights = async (userId) => {
        setLoading(true);
        try {
          const initialUrl = `${apiDomain}/api/v2/user/monthly?id=${userId}&access_token=${token}&period=day`;
          const data = await getResponse(initialUrl);
          console.log("filtereddata",data)
          console.log("insights", data)
          setInsights(data);
          
             // Update prevUrl from response
        } catch (err) {
          setError(err);
        } finally {
          setLoading(false);
        }
      };
      fetchInsights(details.instagramBusinessAccountId)
  },[details])

  return (
    <main className="space-y-8 px-4">
    {details?.participationDetails?.map((x, index) => (
      <div key={index} className="relative p-6 bg-white rounded-md overflow-hidden">
        {/* Gradient Border */}
        <div className="absolute inset-0 rounded-md border-[3px] 
          border-transparent bg-gradient-to-r from-pink-500 via-purple-500 to-blue-500 
          animate-gradient-border"></div>

        {/* Inner Content */}
        <div className="relative z-10 flex flex-col space-y-6 bg-white rounded-md p-6">
          <div className="w-full border-b pb-5 flex items-center justify-between">
            <strong>
              Event Name:{" "}
              <span className="text-gray-600 text-base ml-2">
                {eventData?.find((y) => y.eventID === x.eventId)?.eventName || "N/A"}
              </span>
            </strong>
            <strong>
              Start Date:{" "}
              <span className="text-gray-600 font-medium text-base ml-2">
                {eventData?.find((y) => y.eventID === x.eventId) &&
                  new Date(
                    eventData.find((y) => y.eventID === x.eventId).startDate
                  ).toLocaleDateString("en-GB")}
              </span>
            </strong>
            <strong>
              Ending Date:{" "}
              {eventData?.find((y) => y.eventID === x.eventId) &&
                new Date(
                  eventData.find((y) => y.eventID === x.eventId).endDate
                ).toLocaleDateString("en-GB")}
            </strong>
          </div>

          <div className="w-full border-b pb-5 flex items-center justify-between">
            <strong>Participant Name: {details.name}</strong>
            <strong>
              Joining Date:{" "}
              {new Date(details.joiningTime).toLocaleDateString("en-GB")}
            </strong>
          </div>

          <div className="w-full border-b pb-5 flex flex-wrap justify-between">
            <strong>
              Category:{" "}
              {categories.find((z) => z.id === x.genericCategory)?.name || "Invalid"}
            </strong>
            <strong>
              Gender Category:{" "}
              {genderCategories.find((z) => z.id === x.genderCategory)?.gender || "Invalid"}
            </strong>
            <strong>
              Age Category:{" "}
              {ageCategories.find((z) => z.id === x.ageCategory)?.name || "Invalid"}
            </strong>
            <strong>
              Followers Category:{" "}
              {followerCountCategories.find((z) => z.id === x.followersCountCategory)
                ? `${followerCountCategories.find((z) => z.id === x.followersCountCategory).startCount} - 
                  ${followerCountCategories.find((z) => z.id === x.followersCountCategory).endCount}`
                : "Invalid"}
            </strong>
          </div>

          <div className="w-full border-b pb-5 flex flex-wrap justify-between">
            <strong>City: {x.city}</strong>
            <strong>State: {x.state}</strong>
            <strong>Pincode: {x.pincode}</strong>
            <strong>International: {x.international.toString()}</strong>
          </div>
        </div>
      </div>
    ))}
  </main>
  );
};
