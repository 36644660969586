import React, { useContext, useState, useEffect } from "react";
import { DataContext } from "../context/DataContext";
import axios from "axios";
import Winnings from "./Winnings";
import LoadingScreen from "../utilities/LoadingScreen";

const WinnersList = () => {
  const {
    eventData,
    categories,
    ageCategories,
    genderCategories,
    followerCountCategories,
    location,
    loading,
  } = useContext(DataContext);

  const cities = location.city;
  const states = location.state;
  const pincodes = location.pin_code;

  if(loading) <LoadingScreen />

  const [selectedEvent, setSelectedEvent] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedAgeCategory, setSelectedAgeCategory] = useState("");
  const [selectedGenderCategory, setSelectedGenderCategory] = useState("");
  const [selectedFollowerCountCategory, setSelectedFollowerCountCategory] =
    useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedPincode, setSelectedPincode] = useState("");
  const [winners, setWinners] = useState(null);

  const apiDomain = process.env.REACT_APP_API_DOMAIN;

  const handleSubmit = async () => {
    const selectedEventInt = parseInt(selectedEvent.trim(), 10) || 0;
    const selectedCategoryInt = parseInt(selectedCategory.trim(), 10) || 0;
    const selectedAgeCategoryInt = parseInt(selectedAgeCategory.trim(), 10) || 0;
    const selectedGenderCategoryInt = parseInt(selectedGenderCategory.trim(), 10) || 0;
    const selectedFollowerCountCategoryInt = parseInt(selectedFollowerCountCategory.trim(), 10) || 0;

    let url = `${apiDomain}/api/v2/getfilteredwinners`;
    let queryParams = [];

    if (selectedEventInt > 0) queryParams.push(`event_id=${selectedEventInt}`);
    if (selectedCategoryInt > 0) queryParams.push(`categories=${selectedCategoryInt}`);
    if (selectedAgeCategoryInt > 0) queryParams.push(`agecategories=${selectedAgeCategoryInt}`);
    if (selectedGenderCategoryInt > 0) queryParams.push(`gendercategories=${selectedGenderCategoryInt}`);
    if (selectedFollowerCountCategoryInt > 0) queryParams.push(`followercountcategories=${selectedFollowerCountCategoryInt}`);
    if (selectedState.trim()) queryParams.push(`state=${selectedState.trim()}`);
    if (selectedPincode.trim()) queryParams.push(`pincode=${selectedPincode.trim()}`);
    if (selectedCity.trim()) queryParams.push(`city=${selectedCity.trim()}`);

    if (queryParams.length > 0) {
      url += `?${queryParams.join("&")}`;
    }

    try {
      const resp = await axios.get(url);
      setWinners(resp.data);
    } catch (error) {
      console.error("Error fetching filtered winners:", error);
    }
  };

  return (
    <div className="lg:ml-[20vw] lg:mr-[2vw] grid grid-cols-1 lg:grid-cols-2 gap-6 p-4">
      <div className="p-6 bg-white rounded-lg shadow-lg border border-gray-200">
        <h2 className="text-3xl font-bold text-center mb-6">Filter Winners</h2>
        <div className="grid grid-cols-1 gap-6">
          <div>
            <label className="block text-gray-700 font-semibold">Event</label>
            <select
              className="block w-full p-2 mt-1 border border-gray-300 rounded"
              value={selectedEvent}
              onChange={(e) => setSelectedEvent(e.target.value)}
            >
              <option value="">Select Event</option>
              {eventData && eventData.map((event) => (
                <option key={event.eventID} value={event.eventID}>
                  {event.eventName}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-gray-700 font-semibold">
              Follower Count Category
            </label>
            <select
              className="block w-full p-2 mt-1 border border-gray-300 rounded"
              value={selectedFollowerCountCategory}
              onChange={(e) => setSelectedFollowerCountCategory(e.target.value)}
            >
              <option value="">Select Followers Count</option>
              {followerCountCategories &&
                followerCountCategories.map((event) => (
                  <option key={event.id} value={event.id}>
                    {event.startCount} - {event.endCount}
                  </option>
                ))}
            </select>
          </div>
          <div>
            <label className="block text-gray-700 font-semibold">Category</label>
            <select
              className="block w-full p-2 mt-1 border border-gray-300 rounded"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="">Select Category</option>
              {categories && categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-gray-700 font-semibold">Age Category</label>
            <select
              className="block w-full p-2 mt-1 border border-gray-300 rounded"
              value={selectedAgeCategory}
              onChange={(e) => setSelectedAgeCategory(e.target.value)}
            >
              <option value="">Select Age Category</option>
              {ageCategories && ageCategories.map((age) => (
                <option key={age.id} value={age.id}>
                  {age.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-gray-700 font-semibold">
              Gender Category
            </label>
            <select
              className="block w-full p-2 mt-1 border border-gray-300 rounded"
              value={selectedGenderCategory}
              onChange={(e) => setSelectedGenderCategory(e.target.value)}
            >
              <option value="">Select Gender Category</option>
              {genderCategories && genderCategories.map((gender) => (
                <option key={gender.id} value={gender.id}>
                  {gender.gender}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-gray-700 font-semibold">City</label>
            <select
              className="block w-full p-2 mt-1 border border-gray-300 rounded"
              value={selectedCity}
              onChange={(e) => setSelectedCity(e.target.value)}
            >
              <option value="">Select City</option>
              {cities && cities.map((city, index) => (
                <option key={index} value={city}>
                  {city}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-gray-700 font-semibold">State</label>
            <select
              className="block w-full p-2 mt-1 border border-gray-300 rounded"
              value={selectedState}
              onChange={(e) => setSelectedState(e.target.value)}
            >
              <option value="">Select State</option>
              {states && states.map((state, index) => (
                <option key={index} value={state}>
                  {state}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-gray-700 font-semibold">Pincode</label>
            <select
              className="block w-full p-2 mt-1 border border-gray-300 rounded"
              value={selectedPincode}
              onChange={(e) => setSelectedPincode(e.target.value)}
            >
              <option value="">Select Pincode</option>
              {pincodes && pincodes.map((pincode, index) => (
                <option key={index} value={pincode}>
                  {pincode}
                </option>
              ))}
            </select>
          </div>
          <div className="mt-6">
            <button
              onClick={handleSubmit}
              className="w-full py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              Go
            </button>
          </div>
        </div>
      </div>
      {winners && <Winnings data={winners} eventData={eventData} />}
    </div>
  );
};

export default WinnersList;









// import React, { useContext, useState } from "react";
// import { DataContext } from "../context/DataContext";
// import axios from "axios";
// import Winnings from "./Winnings";

// const WinnersList = () => {
//   const {
//     eventData,
//     categories,
//     ageCategories,
//     genderCategories,
//     followerCountCategories,
//     location,
//     loading,
//   } = useContext(DataContext);

//   if (loading) <></>;

//   const cities = location.city;
//   const states = location.state;
//   const pincodes = location.pin_code;
//   const [selectedEvent, setSelectedEvent] = useState("");
//   const [selectedCategory, setSelectedCategory] = useState("");
//   const [selectedAgeCategory, setSelectedAgeCategory] = useState("");
//   const [selectedGenderCategory, setSelectedGenderCategory] = useState("");
//   const [selectedFollowerCountCategory, setSelectedFollowerCountCategory] =
//     useState("");
//   const [selectedCity, setSelectedCity] = useState("");
//   const [selectedState, setSelectedState] = useState("");
//   const [selectedPincode, setSelectedPincode] = useState("");
//   const [winners, setWinners] = useState(null);

//   const apiDomain = process.env.REACT_APP_API_DOMAIN;

//   const handleSubmit = async () => {
//     console.log("Selected Event:", selectedEvent);
//     console.log("Selected Category:", selectedCategory);
//     console.log("Selected Age Category:", selectedAgeCategory);
//     console.log("Selected Gender Category:", selectedGenderCategory);
//     console.log("Selected City:", selectedCity);
//     console.log("Selected State:", selectedState);
//     console.log("Selected Pincode:", selectedPincode);
//     console.log(
//       "Selected Follower Count Category",
//       selectedFollowerCountCategory
//     );

//     // Ensure the values are parsed as integers and there are no extra spaces
//     var selectedEventInt = parseInt(selectedEvent.trim(), 10) || 0;
//     var selectedCategoryInt = parseInt(selectedCategory.trim(), 10) || 0;
//     var selectedAgeCategoryInt = parseInt(selectedAgeCategory.trim(), 10) || 0;
//     var selectedGenderCategoryInt =
//       parseInt(selectedGenderCategory.trim(), 10) || 0;
//     var selectedFollowerCountCategoryInt =
//       parseInt(selectedFollowerCountCategory.trim(), 10) || 0;

//     // Initialize the base URL
//     // var url = "http://localhost:8080/api/v2/getfilteredwinners";
//     var url = `${apiDomain}/api/v2/getfilteredwinners`;

//     // Initialize an empty array to hold query parameters
//     var queryParams = [];

//     // Conditionally append integer parameters only if their value is greater than 0
//     if (selectedEventInt > 0) {
//       queryParams.push(`event_id=${selectedEventInt}`);
//     }
//     if (selectedCategoryInt > 0) {
//       queryParams.push(`categories=${selectedCategoryInt}`);
//     }
//     if (selectedAgeCategoryInt > 0) {
//       queryParams.push(`agecategories=${selectedAgeCategoryInt}`);
//     }
//     if (selectedGenderCategoryInt > 0) {
//       queryParams.push(`gendercategories=${selectedGenderCategoryInt}`);
//     }
//     if (selectedFollowerCountCategoryInt > 0) {
//       queryParams.push(
//         `followercountcategories=${selectedFollowerCountCategoryInt}`
//       );
//     }

//     // Conditionally append string parameters only if they are not empty
//     if (selectedState.trim()) {
//       queryParams.push(`state=${selectedState.trim()}`);
//     }
//     if (selectedPincode.trim()) {
//       queryParams.push(`pincode=${selectedPincode.trim()}`);
//     }
//     if (selectedCity.trim()) {
//       queryParams.push(`city=${selectedCity.trim()}`);
//     }

//     // Join the query parameters with '&' and append to the base URL
//     if (queryParams.length > 0) {
//       url += `?${queryParams.join("&")}`;
//     }

//     console.log("url:", url);

//     // Make the API request
//     try {
//       var resp = await axios.get(url);
//       setWinners(resp.data);
//       console.log("response:", resp);
//     } catch (error) {
//       console.error("Error fetching filtered winners:", error);
//     }
//   };

//   return (
//     <>
//       <div className="lg:ml-[20vw] lg:mr-[2vw] my-10 p-6 bg-white rounded-lg shadow-lg border border-gray-200">
//         <h2 className="text-3xl font-bold text-center mb-6">Filter Winners</h2>

//         <div className="grid grid-cols-1 gap-6">
//           <div>
//             <label className="block text-gray-700 font-semibold">Event</label>
//             <select
//               className="block w-full p-2 mt-1 border border-gray-300 rounded"
//               value={selectedEvent}
//               onChange={(e) => setSelectedEvent(e.target.value)}
//             >
//               <option value="">Select Event</option>
//               {eventData && eventData.map((event) => (
//                 <option key={event.eventID} value={event.eventID}>
//                   {event.eventName}
//                 </option>
//               ))}
//             </select>
//           </div>
//           <div className="grid grid-cols-1 gap-6">
//             <div>
//               <label className="block text-gray-700 font-semibold">
//                 followercount Category
//               </label>
//               <select
//                 className="block w-full p-2 mt-1 border border-gray-300 rounded"
//                 value={selectedFollowerCountCategory}
//                 onChange={(e) =>
//                   setSelectedFollowerCountCategory(e.target.value)
//                 }
//               >
//                 <option value="">Select Followers Count</option>
//                 {followerCountCategories && followerCountCategories.map((event) => (
//                   <option key={event.id} value={event.id}>
//                     {event.startCount} - {event.endCount}
//                   </option>
//                 ))}
//               </select>
//             </div>
//           </div>

//           <div>
//             <label className="block text-gray-700 font-semibold">
//               Category
//             </label>
//             <select
//               className="block w-full p-2 mt-1 border border-gray-300 rounded"
//               value={selectedCategory}
//               onChange={(e) => setSelectedCategory(e.target.value)}
//             >
//               <option value="">Select Category</option>
//               {categories && categories.map((category) => (
//                 <option key={category.id} value={category.id}>
//                   {category.name}
//                 </option>
//               ))}
//             </select>
//           </div>

//           <div>
//             <label className="block text-gray-700 font-semibold">
//               Age Category
//             </label>
//             <select
//               className="block w-full p-2 mt-1 border border-gray-300 rounded"
//               value={selectedAgeCategory}
//               onChange={(e) => setSelectedAgeCategory(e.target.value)}
//             >
//               <option value="">Select Age Category</option>
//               {ageCategories && ageCategories.map((age) => (
//                 <option key={age.id} value={age.id}>
//                   {age.name}
//                 </option>
//               ))}
//             </select>
//           </div>

//           <div>
//             <label className="block text-gray-700 font-semibold">
//               Gender Category
//             </label>
//             <select
//               className="block w-full p-2 mt-1 border border-gray-300 rounded"
//               value={selectedGenderCategory}
//               onChange={(e) => setSelectedGenderCategory(e.target.value)}
//             >
//               <option value="">Select Gender Category</option>
//               {genderCategories && genderCategories.map((gender) => (
//                 <option key={gender.id} value={gender.id}>
//                   {gender.gender}
//                 </option>
//               ))}
//             </select>
//           </div>

//           <div>
//             <label className="block text-gray-700 font-semibold">City</label>
//             <select
//               className="block w-full p-2 mt-1 border border-gray-300 rounded"
//               value={selectedCity}
//               onChange={(e) => setSelectedCity(e.target.value)}
//             >
//               <option value="">Select City</option>
//               {cities && cities.map((city, index) => (
//                 <option key={index} value={city}>
//                   {city}
//                 </option>
//               ))}
//             </select>
//           </div>

//           <div>
//             <label className="block text-gray-700 font-semibold">State</label>
//             <select
//               className="block w-full p-2 mt-1 border border-gray-300 rounded"
//               value={selectedState}
//               onChange={(e) => setSelectedState(e.target.value)}
//             >
//               <option value="">Select State</option>
//               {states && states.map((state, index) => (
//                 <option key={index} value={state}>
//                   {state}
//                 </option>
//               ))}
//             </select>
//           </div>

//           <div>
//             <label className="block text-gray-700 font-semibold">Pincode</label>
//             <select
//               className="block w-full p-2 mt-1 border border-gray-300 rounded"
//               value={selectedPincode}
//               onChange={(e) => setSelectedPincode(e.target.value)}
//             >
//               <option value="">Select Pincode</option>
//               {pincodes && pincodes.map((pincode, index) => (
//                 <option key={index} value={pincode}>
//                   {pincode}
//                 </option>
//               ))}
//             </select>
//           </div>

//           <div className="mt-6">
//             <button
//               onClick={handleSubmit}
//               className="w-full py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
//             >
//               Go
//             </button>
//           </div>
//         </div>
//       </div>
//       {winners && <Winnings data={winners} />}
//     </>
//   );
// };

// export default WinnersList;
